import SkyInput from "components/Forms/SkyInput";
import InputMask from "react-input-mask";
import CurrencyInput from "react-currency-format";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { Controller } from "react-hook-form";

type Props = {
  control: any;
  setValue: any;
  watch: any;
};

const EnvanterInfo = (props: Props) => {
  return (
    <Row>
      <Col sm={3}>
        <SkyInput name="inventoryNo" control={props.control} label="Envanter No" />
      </Col>
      <Col sm={3}>
        <SkyInput name="invoiceNo" control={props.control} label="Fatura No" />
      </Col>
      <Col sm={3}>
        <FormGroup>
          <Label>Alım Tarihi</Label>
          <Controller
            control={props.control}
            name="purchaseData"
            render={({ value }) => (
              <InputMask
                mask="99.99.9999"
                value={value}
                className="form-control"
                maskPlaceholder="__.__.____"
                onChange={(e) => {
                  props.setValue(
                    "purchaseData",
                    e.target.value
                      .split("")
                      .filter((ch) => ch !== "_")
                      .join("")
                  );
                }}
                name="purchaseData"
              />
            )}
          />
        </FormGroup>
      </Col>
      <Col sm={3}>
        <FormGroup>
          <Label>Alım Fiyatı</Label>
          <Controller
            control={props.control}
            name="price"
            render={({ value }) => (
              <CurrencyInput
                className="form-control"
                value={value ? value.split(".").join(",") : ""}
                thousandSeparator={"."}
                decimalSeparator=","
                prefix={"₺"}
                onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  // formattedValue = $2,223
                  // value ie, 2223
                  props.setValue("price", `${value}`);
                }}
              />
            )}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

export default EnvanterInfo;
