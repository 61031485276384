interface Route {
  name: string;
  title: string;
  path: string | string[];
  component: any;
  exact: boolean;
  roles: any;
}

export const AuthRouter: Route[] = [
  {
    name: "Dashboard",
    title: "Kontrol Paneli",
    path: ["/", "/dashboard", "/home", "/control-center"],
    component: require("pages/Dashboard").default,
    exact: true,
    roles: [],
  },
  {
    name: "CampusList",
    title: "Kampüs Listesi",
    path: ["/campuses", "/campus-list"],
    component: require("pages/CampusList").default,
    exact: true,
    roles: [],
  },
  {
    name: "BuildList",
    title: "Bina Listesi",
    path: "/campus/:campusID/builds",
    component: require("pages/BuildList").default,
    exact: true,
    roles: [],
  },
  {
    name: "FloorList",
    title: "Kat Listesi",
    path: "/campus/:campusID/builds/:buildID/floors",
    component: require("pages/FloorList").default,
    exact: true,
    roles: [],
  },
  {
    name: "RoomList",
    title: "Oda Listesi",
    path: "/campus/:campusID/builds/:buildID/floors/:floorID/rooms",
    component: require("pages/RoomList").default,
    exact: true,
    roles: [],
  },
  {
    name: "Employee",
    title: "CalisanListesi",
    path: "/employee",
    component: require("pages/EmployeeList").default,
    exact: true,
    roles: [],
  },
  {
    name: "User",
    title: "KullanıcıListesi",
    path: "/user",
    component: require("pages/UserList").default,
    exact: true,
    roles: [],
  },
  {
    name: "ReportSearch",
    title: "Kayıt Arama",
    path: "/report-search",
    component: require("pages/report-pages/ReportSearch").default,
    exact: true,
    roles: [],
  },

  {
    name: "ReportEdit",
    title: "Kayıt Düzenleme",
    path: "/report-edit/:barcode",
    component: require("pages/report-pages/Edit").default,
    exact: true,
    roles: [],
  },
  {
    name: "ReportBulkEdit",
    title: "Toplu Kayıt Düzenleme",
    path: "/report-edit-bulk",
    component: require("pages/InventoryBulkUpdate/InventoryBulkUpdate").default,
    exact: true,
    roles: [],
  },
  {
    name: "MatchReport",
    title: "Eşleşen Raporlar",
    path: "/report-match",
    component: require("pages/report-pages/MatchInventory").default,
    exact: true,
    roles: [],
  },
  {
    name: "ReadableReport",
    title: "Sayılabilir Raporlar",
    path: "/report-readable",
    component: require("pages/report-pages/Readable").default,
    exact: true,
    roles: [],
  },
  {
    name: "PassiveReport",
    title: "Pasif Raporlar",
    path: "/report-passive",
    component: require("pages/report-pages/PassiveInventory").default,
    exact: true,
    roles: [],
  },
  {
    name: "EmployeeReports",
    title: "Zimmetli Raporları",
    path: "/employee-reports",
    component: require("pages/report-pages/Employee").default,
    exact: true,
    roles: [],
  },
  {
    name: "SanalBarcode",
    title: "Sanal Barkod",
    path: "/sanal-barcode",
    component: require("pages/report-pages/SanalBarcode").default,
    exact: true,
    roles: [],
  },
  {
    name: "Settings",
    title: "Ayarlar",
    path: "/setting",
    component: require("pages/Settings").default,
    exact: true,
    roles: [],
  },
  {
    name: "CreateReport",
    title: "Sabit Kıymet Tanımla",
    path: "/create-report/:barcode",
    component: require("pages/CreateReport").default,
    exact: true,
    roles: [],
  },
];

export const UnauthRouter: Route[] = [
  {
    name: "Login",
    title: "Giriş Ekranı",
    path: "",
    component: require("pages/Login").default,
    exact: false,
    roles: [],
  },
];
