import * as userConstants from "../constants/user-constant";
import { TUser } from "../types/user-types";

type TSetUserPayload = {
  user: TUser;
  token: string;
};

export interface ISetUser {
  type: userConstants.SET_USER;
  payload: TSetUserPayload;
}

// Actions
export function setUser(user: TUser, token: string): ISetUser {
  return {
    type: userConstants.SET_USER,
    payload: { token, user },
  };
}

export interface ISetProject {
  type: userConstants.SET_PROJECT;
  payload: TUser["project"];
}

export function setProject(project: TUser["project"]) {
  return {
    type: userConstants.SET_PROJECT,
    payload: project,
  };
}

export interface ISetFullUser {
  type: userConstants.SET_FULL_USER;
  payload: TUser;
}

export function setFullUser(user: TUser) {
  return {
    type: userConstants.SET_FULL_USER,
    payload: user,
  };
}

export interface IClearUser {
  type: userConstants.CLEAR_USER;
}

export function clearUser() {
  return {
    type: userConstants.CLEAR_USER,
  };
}

export type TUserAction = ISetUser | ISetProject | ISetFullUser | IClearUser;
