import React, { useState, useMemo, ReactNode, ReactNodeArray, Children } from "react";
import { useRedux, useToggle } from "hooks";
import { removeItem } from "helper/storageManager";
import { clearUser } from "store/actions/user-actions";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { UserEnum } from "models/user-enum";

const Header = () => {
  const [isOpen, toggle] = useToggle();
  const [
    {
      auth: { user },
    },
    dispatch,
  ] = useRedux();

  function logout() {
    removeItem();
    dispatch(clearUser());
  }
  const history = useHistory();
  return (
    <div className="header header-fixed">
      <div className="container-fluid d-flex align-items-center justify-content-between">
        <div className="header-menu-wrapper header-menu-wrapper-left">
          <div className="header-menu header-menu-mobile header-menu-layout-default">
            <ul className="menu-nav">
              <li className="menu-item menu-item-submenu">
                {user?.userRoleID !== UserEnum.basic && (
                  <Link to="/create-report/none" className="menu-link menu-toggle">
                    <span className="menu-text ">
                      <i className="flaticon2-plus mr-3" />
                      Sabit Kıymet Tanımla
                    </span>
                  </Link>
                )}
              </li>
              {/* <li className="menu-item menu-item-open menu-item-here menu-item-submenu menu-item-rel menu-item-open menu-item-here menu-item-active">
                <a href="javascript:;" className="menu-link menu-toggle">
                  <span className="menu-text">Pages</span>
                  <i className="menu-arrow"></i>
                </a>
              </li>
              <li className="menu-item menu-item-submenu">
                <a href="javascript:;" className="menu-link menu-toggle">
                  <span className="menu-text">Features</span>
                  <i className="menu-arrow"></i>
                </a>
              </li>
              <li className="menu-item menu-item-submenu">
                <span
                  className="menu-link menu-toggle"
                  onClick={() => {
                    logout();
                  }}
                >
                  <span className="menu-text">Logout</span>
                  <i className="menu-arrow"></i>
                </span>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="header-menu-wrapper header-menu-wrapper-right">
          <div className="d-flex align-items-center">{user!.project!.name} | </div>
          <Dropdown isOpen={isOpen} toggle={toggle}>
            <DropdownToggle color="secondary" className="border-0" outline caret>{`${user!.name} ${
              user!.surname
            }`}</DropdownToggle>
            <DropdownMenu right style={{ minWidth: "132%" }}>
              <DropdownItem header>{user!.userName}</DropdownItem>
              <DropdownItem
                onClick={() => {
                  history.push("/setting");
                }}
              >
                Ayarlar
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => {
                  logout();
                }}
              >
                Çıkış Yap
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Header;
