import React, { ReactElement, useEffect, useLayoutEffect, useState } from "react";
import { useRedux, useGet, Status } from "hooks";
import { useForm } from "react-hook-form";
import { Campus, campusUrls, Build, buildUrls, floorUrls, RoomListParams, roomUrls } from "services/location";
import { Params, url } from "../Detail/services";
import { Form, Row, Col, FormGroup, Label, Input, Button, Modal, ModalHeader } from "reactstrap";
import { InvertoryOData } from "models";
import _ from "lodash";
import SelectBox from "components/SelectBox";

interface Props {
  isOpen: boolean;
  onFilter: (val: any) => void;
  onReset: () => void;
  toggle: () => void;
  data: InvertoryOData[];
}

interface States {
  campusList: any[];
  builds: any[];
  floors: any[];
  rooms: any[];
  costs: any[];
  departments: any[];
  models: any[];
  brands: any[];
  groups: any[];
  mainGroups: any[];
  employees: any[];
  iStats: any[];
  colors: any[];
}

function InventoryODataFilter2(props: Props): ReactElement {
  const [isOpen, setOpen] = useState<boolean>(true);
  const [
    {
      auth: { user },
    },
  ] = useRedux();
  const { register, watch, getValues, setValue, control, errors, handleSubmit } = useForm<any>();

  const [getEmployees, , employeeList] = useGet<any[], { projectId: number }>("/Employee/GetEmployeeListByProjectID");
  const [getColors, statColors, colors] = useGet<any[], any>("/Color/GetColorListByProjectID");
  const [getStats, statStat, stats] = useGet<any[], {}>("/Inventory/GetInventoryStatusList");

  function onValid(fields: any) {
    
    setOpen(false);
    let tmpData: any = [];
    Object.entries(fields).forEach(([key, value]) => {
      if (!!value && value !== "-1" && value !== undefined) {
        switch (key) {
          case "brandID":
            tmpData = { ...tmpData, brandID: { id: value, value: key } };
            break;
          case "buildID":
            tmpData = { ...tmpData, buildID: { id: value, value: key } };
            break;
          case "campusID":
            tmpData = { ...tmpData, campusID: { id: value, value: key } };
            break;
          case "colorID":
            tmpData = { ...tmpData, colorID: { id: value, value: key } };
            break;
          case "costID":
            tmpData = { ...tmpData, costID: { id: value, value: key } };
            break;
          case "departmentID":
            tmpData = { ...tmpData, departmentID: { id: value, value: key } };
            break;
          case "emplooyeID":
            tmpData = { ...tmpData, emplooyeID: { id: value, value: key } };
            break;
          case "endDate":
            tmpData = { ...tmpData, endDate: { id: "Oluşturma Tarihi", value: value } };
            break;
          case "floorID":
            tmpData = { ...tmpData, floorID: { id: value, value: key } };
            break;
          case "grupID":
            tmpData = { ...tmpData, grupID: { id: value, value: key } };
            break;
          case "inventorystatusID":
            tmpData = {
              ...tmpData,
              inventorystatusID: { id: value, value: key },
            };
            break;
          case "mainGrupID":
            tmpData = { ...tmpData, mainGrupID: { id: value, value: key } };
            break;
          case "modelID":
            tmpData = { ...tmpData, modelID: { id: value, value: key } };
            break;
          case "roomID":
            tmpData = { ...tmpData, roomID: { id: value, value: key } };
            break;
          case "startDate":
            tmpData = { ...tmpData, startDate: { id: value, value: key } };
            break;
        }
      }
    });
    props.onFilter(tmpData);
    props.toggle();
  }

  useLayoutEffect(() => {
    getEmployees({ projectId: user!.project!.id! });
    getColors({ projectId: user!.project!.id! });
    getStats();
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(onValid)}>
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label>Kampüs</Label>
              <Select
                listurl="/Campus/GetCampusListByProjectID"
                noAdd
                params={{ projectId: user!.project!.id! }}
                name="campusID"
                control={control}
                rules={{
                  required: false,
                  validate: () => {
                    return watch("campusID") == 0 ? false : true;
                  },
                }}
                invalid={!!errors["campusID"]}
              />
            </FormGroup>
          </Col>
          {watch("campusID") && watch("campusID") != 0 && (
            <Col md={3}>
              <FormGroup>
                <Label>Bina</Label>
                <Select
                  listurl="/Build/GetBuildListByCampusID"
                  params={{ campusId: watch("campusID") }}
                  parent={watch("campusID")}
                  name="buildID"
                  rules={{
                    required: false,
                    validate: () => {
                      return watch("buildID") !== 0;
                    },
                  }}
                  control={control}
                  invalid={!!errors["buildID"]}
                  noAdd
                />
              </FormGroup>
            </Col>
          )}
          {watch("buildID") && watch("buildID") != 0 && (
            <Col md={3}>
              <FormGroup>
                <Label>
                  <span className="text-danger">* </span>Kat
                </Label>
                <Select
                  listurl="/Floor/GetFloorListByBuildID"
                  params={{ buildId: watch("buildID") }}
                  parent={watch("buildID")}
                  name="floorID"
                  rules={{
                    required: false,
                    validate: () => {
                      return watch("floorID") !== 0;
                    },
                  }}
                  control={control}
                  invalid={!!errors["floorID"]}
                  noAdd
                />
              </FormGroup>
            </Col>
          )}
          {watch("floorID") && watch("floorID") != 0 && (
            <Col md={3}>
              <FormGroup>
                <Label>
                  <span className="text-danger">* </span>Oda
                </Label>
                <Select
                  listurl="/Room/GetRoomListByFloorID"
                  params={{ floorId: watch("floorID") }}
                  parent={watch("floorID")}
                  name="roomID"
                  rules={{
                    required: false,
                    validate: () => {
                      return watch("roomID") !== 0;
                    },
                  }}
                  control={control}
                  invalid={!!errors["roomID"]}
                  noAdd
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        <hr />
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Bölüm</Label>
              <Select
                listurl="/Department/GetDepartmentListByProjectID"
                addurl="/Department/AddDepartment"
                params={{ projectId: user!.project!.id! }}
                addparams={{ projectId: user!.project!.id! }}
                control={control}
                name="departmentID"
              />
            </FormGroup>
          </Col>
          {watch("departmentID") && watch("departmentID") != 0 && (
            <Col md={6}>
              <FormGroup>
                <Label>Masraf Yeri</Label>
                <Select
                  listurl="/Department/GetCostListByDepartmentID"
                  addurl="/Department/AddCost"
                  params={{ departmentId: watch("departmentID") }}
                  addparams={{ departmentId: watch("departmentID") }}
                  parent={watch("departmentID")}
                  control={control}
                  name="costID"
                  selected={watch("costID")}
                />
              </FormGroup>
            </Col>
          )}
        </Row>

        <hr />
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label>Zimmet</Label>
              <Select
                listurl="/Employee/GetEmployeeListByProjectID"
                addurl="/Employee/AddEmployee"
                params={{ projectId: user!.project!.id! }}
                addparams={{ projectId: user!.project!.id!, companyId: user!.companyID }}
                name="employeeID"
                innerRef={register({ required: false })}
                employee
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Durum</Label>
              <SelectBox
                name="inventoryStatusID"
                control={control}
                rules={{ required: false }}
                options={stats ? stats!.map((stat) => ({ value: stat.id, label: stat.name })) : []}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Renk</Label>
              <Input type="select" name="colorID" innerRef={register()}>
                <option value={"-1"} selected>
                  Seçim Yapılmadı
                </option>
                {colors &&
                  colors.map((_color, index) => (
                    <option value={_color.id} key={index}>
                      {_color.colorName}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label>Ana Grup</Label>
              <Select
                listurl={`/Group/GetMainGroupsByProjectId?projectId=${user!.project!.id}`}
                addurl="/Group/AddGroupMain"
                params={{}}
                addparams={{}}
                name="mainGrupID"
                rules={{ required: false }}
                control={control}
                invalid={!!errors["mainGrupID"]}
              />
            </FormGroup>
          </Col>
          {watch("mainGrupID") && watch("mainGrupID") != 0 && (
            <Col md={3}>
              <FormGroup>
                <Label>Grup</Label>
                <Select
                  listurl="/Group/GetGroupListByGroupMainID"
                  addurl="/Group/AddGroup"
                  params={{ groupMainID: watch("mainGrupID") }}
                  parent={watch("mainGrupID")}
                  addparams={{ grupMainID: watch("mainGrupID") }}
                  name="grupID"
                  rules={{ required: false }}
                  control={control}
                  invalid={!!errors["grupID"]}
                />
              </FormGroup>
            </Col>
          )}
          {watch("grupID") && watch("grupID") != 0 && (
            <Col md={3}>
              <FormGroup>
                <Label>Marka</Label>
                <Select
                  listurl="/Group/GetBrandListByGroupID"
                  addurl="/Group/AddBrand"
                  params={{ groupId: watch("grupID") }}
                  parent={watch("grupID")}
                  addparams={{ grupID: watch("grupID") }}
                  name="brandID"
                  control={control}
                  rules={{ required: false }}
                  invalid={!!errors["brandID"]}
                />
              </FormGroup>
            </Col>
          )}
          {watch("brandID") && watch("brandID") != 0 && (
            <Col md={3}>
              <FormGroup>
                <Label>Model</Label>
                <Select
                  listurl="/Group/GetModelListByBrandID"
                  addurl="/Group/AddModel"
                  params={{ brandId: watch("brandID") }}
                  parent={watch("brandID")}
                  addparams={{ brandId: watch("brandID"), grupId: watch("grupID") }}
                  name="modelID"
                  control={control}
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        <hr />
        <Row>
          <Col md={6}>
            <Label>Başlangıç Tarihi</Label>
            <Input type="date" name="startDate" innerRef={register()} />
          </Col>
          <Col md={6}>
            <Label>Bitiş Tarihi</Label>
            <Input type="date" name="endDate" innerRef={register()} />
          </Col>
        </Row>
        <hr />
        <div className="mt-2">
          <Button color="primary">Getir</Button>
          <Button color="danger" className="ml-2" outline type="button" onClick={props.toggle}>
            Vazgeç
          </Button>
        </div>
      </form>
    </div>
  );
}

export default InventoryODataFilter2;

function Select({ title, addurl, addparams, listurl, params, parent, employee, selected, noAdd, ...props }: any) {
  const [getList, liststat, items] = useGet<any[], any>(listurl);

  useLayoutEffect(() => {
    if (params) {
      getList(params);
    }
  }, [parent]);

  return (
    <div className="d-flex justify-content-between">
      {props.control && (
        <SelectBox
          name={props.name}
          control={props.control}
          rules={props.rules}
          options={items ? items!.map((item) => ({ value: item.id, label: item.name })) : []}
        />
      )}
      {props.innerRef && (
        <Input {...props} type="select" disabled={liststat !== Status.success}>
          {liststat === Status.success && !!items && Array.isArray(items) && (
            <>
              <option value={"-1"} selected disabled>
                Seçim Yapınız
              </option>
              {items.map((item: any, key: number) => (
                <option key={key} value={`${item.id}`} selected={selected === `${item.id}`}>
                  {item.id} - {item.name || item.nameSurname}
                </option>
              ))}
            </>
          )}
          {liststat === Status.pending && (
            <option selected value={"0"}>
              Yükleniyor{" "}
            </option>
          )}
        </Input>
      )}
    </div>
  );
}
