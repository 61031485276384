import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import SkyInput from "components/Forms/SkyInput";
import { IUserEdit, editUserForm } from "models/IUserEdit";
import { usePost, useToggle } from "hooks";
import { editEmployeeForm, IEmployeeEdit } from "models/IEmployeeEdit";

type EmpleyooEditFormProps = {
  defaultValues: IEmployeeEdit;
  setEmloyeeEditForm: React.Dispatch<React.SetStateAction<editEmployeeForm>>;
  editEmployeeRequest: any;
};

const EmpleyooEditForm: FC<EmpleyooEditFormProps> = (props) => {
  const { defaultValues } = props;
  const { control, setValue, watch, handleSubmit, register } = useForm({
    defaultValues: defaultValues,
  });

  const [isOpen, toggle] = useToggle();
  const [isActive, setIsActive] = useState(defaultValues.isActive);

  async function onValid(fields: any) {
    //update emplooye servisi
    const body: any = {
      id: defaultValues.id,
      companyID: defaultValues.companyID,
      projectID: defaultValues.projectID,
      nameSurname: fields.nameSurname,
      sicilID: fields.sicilID,
      isActive: isActive,
      createDate: defaultValues.createDate,
    };
    await props.editEmployeeRequest(body);
    props.setEmloyeeEditForm({ modalOpen: false, defaultValues: null });
  }

  return (
    <form onSubmit={handleSubmit(onValid)}>
      <SkyInput name="nameSurname" control={control} label="Ad-Soyad" />
      <SkyInput name="sicilID" control={control} label="Personel Sicili" />
      <div className="header-menu-wrapper header-menu-wrapper-right">
        <div className="d-flex align-items-center mr-4">Durum:</div>
        <Dropdown isOpen={isOpen} toggle={toggle}>
          <DropdownToggle color="secondary" className="border-0" caret onClick={() => toggle}>
            {isActive ? "Aktif" : "Pasif"}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => setIsActive(true)}>Aktif</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={() => setIsActive(false)}>Pasif</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className="d-flex justify-content-end gap-2">
        <Button
          size="md"
          color="danger"
          title="Kapat"
          onClick={() => props.setEmloyeeEditForm({ modalOpen: false, defaultValues: null })}
        >
          Kapat
        </Button>
        <Button size="md" type="submit" color="success" title="Evet">
          Kaydet
        </Button>
      </div>
    </form>
  );
};

export default EmpleyooEditForm;
