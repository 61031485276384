import React, { ReactElement, useEffect, useLayoutEffect, useState } from "react";
import { useRedux, useGet, Status } from "hooks";
import { useForm } from "react-hook-form";
import { Campus, campusUrls, Build, buildUrls, floorUrls, RoomListParams, roomUrls } from "services/location";
import { Params, url } from "../Detail/services";
import { Form, Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import { Inventory } from "models";
import _ from "lodash";

interface Props {
  isOpen: boolean;
  onFilter: (val: any) => void;
  onReset: () => void;
  toggle: () => void;
  data: Inventory[];
}

interface States {
  campusList: any[];
  builds: any[];
  floors: any[];
  rooms: any[];
  costs: any[];
  departments: any[];
  models: any[];
  brands: any[];
  groups: any[];
  mainGroups: any[];
  employees: any[];
  iStats: any[];
  colors: any[];
}

function InventoryFilter2(props: Props): ReactElement {
  const [isOpen, setOpen] = useState<boolean>(true);
  const [
    {
      auth: { user },
    },
  ] = useRedux();
  const { register, watch, getValues, setValue, handleSubmit } = useForm<any>();
  const [states, setStates] = useState<States>({
    campusList: [],
    builds: [],
    floors: [],
    rooms: [],
    costs: [],
    departments: [],
    brands: [],
    groups: [],
    mainGroups: [],
    models: [],
    employees: [],
    colors: [],
    iStats: [],
  });
  const [getFloors, floorsStat, floorList] = useGet<any[], { buildId: number | string }>(floorUrls.floorList);
  const [getRooms, roomsStat, roomList] = useGet<any[], RoomListParams>(roomUrls.roomList);
  const [getBuilds, buildsStat, buildList] = useGet<Build[], { campusId: number | string }>(buildUrls.buildList);
  const [getGroups, , cgroups] = useGet<any[], { groupMainID: number | string; projectId: number }>(
    "/Group/GetGroupListByGroupMainIDAndProjectID"
  );
  const [getBrands, , cbrands] = useGet<any[], { groupId: number | string; projectId: number }>(
    "/Group/GetBrandListByGroupIDAndProjectID"
  );
  const [getModels, , cmodels] = useGet<any[], { brandId: number | string; projectId: number }>(
    "/Group/GetModelListByBrandIDAndProjectID"
  );
  const [getEmployees, , employeeList] = useGet<any[], { projectId: number }>("/Employee/GetEmployeeListByProjectID");
  const [getColors, , colorList] = useGet<any[], { projectId: number }>("/Color/GetColorListByProjectID");
  const [getStats, , iStatList] = useGet<any[], {}>("/Inventory/GetInventoryStatusList");

  function onValid(fields: any) {
    setOpen(false);
    let params: any = {};
    Object.entries(fields).forEach(([key, value]) => {
      if (!!value && value !== "-1") {
        params[key] = value;
      }
    });
    let tmpData: any[] = [];
    Object.keys(params).map((data: any) => {
      switch (data) {
        case "brandID":
          tmpData.push({id:"Marka",value:params[data].split("-")[0]});
          break;
        case "buildID":
          tmpData.push({id:"Bina",value:params[data].split("-")[0]});
          break;
        case "campusID":
          tmpData.push({id:"Kampüs",value:params[data].split("-")[0]});
          break;
        case "colorID":
          tmpData.push({id:"Renk",value:params[data].split("-")[0]});
          break;
        case "costID":
          tmpData.push({id:"Masraf Yeri",value:params[data].split("-")[0]});
          break;
        case "departmentID":
          tmpData.push({id:"Bölüm",value:params[data].split("-")[0]});
          break;
        case "emplooyeID":
          tmpData.push({id:"Zimmet",value:params[data].split("-")[0]});
          break;
        case "endDate":
          tmpData.push({id:"Oluşturma Tarihi",value:params[data]});
          break;
        case "floorID":
          tmpData.push({id:"Kat",value:params[data].split("-")[0]});
          break;
        case "grupID":
          tmpData.push({id:"Grup",value:params[data].split("-")[0]});
          break;
        case "inventorystatusID":
          tmpData.push({id:"Durum",value:params[data].split("-")[0]});
          break;
        case "mainGrupID":
          tmpData.push({id:"Ana Grup",value:params[data].split("-")[0]});
          break;
        case "modelID":
          tmpData.push({id:"Model",value:params[data].split("-")[0]});
          break;
        case "roomID":
          tmpData.push({id:"Oda",value:params[data].split("-")[0]});
          break;
        case "startDate":
          tmpData.push({id:"Oluşturma Tarihi",value:params[data]});
          break;
      }
    });
    props.onFilter(tmpData);
    props.toggle();
  }

  function generateItem(item: string) {
    const [id, name] = item.split("-");
    return { id, name };
  }

  function processData() {
    if (props.data) {
      let _campusList: string[] = [],
        _builds: any[] = [],
        _floors: any[] = [],
        _rooms: any[] = [],
        _departments: any[] = [],
        _costs: any[] = [],
        _mainGroups: any[] = [],
        _groups: any[] = [],
        _brands: any[] = [],
        _models: any[] = [],
        _employees: any[] = [],
        _colors: any[] = [],
        _stats: any[] = [];

      props.data.forEach((item) => {
        if (item.campusID) {
          _campusList.push(`${item.campusID}-${item.campusName}`);
        }
        if (item.buildID) {
          _builds.push(`${item.buildID}-${item.buildName}`);
        }
        if (item.floorID) {
          _floors.push(`${item.floorID}-${item.floorName}`);
        }
        if (item.roomID) {
          _rooms.push(`${item.roomID}-${item.roomName}`);
        }
        if (item.departmentID) {
          _departments.push(`${item.departmentID}-${item.departmentName}`);
        }
        if (item.costID) {
          _costs.push(`${item.costID}-${item.costName}`);
        }
        if (item.mainGrupID) {
          _mainGroups.push(`${item.mainGrupID}-${item.mainGrupName}`);
        }
        if (item.grupID) {
          _groups.push(`${item.grupID}-${item.grupName}`);
        }
        if (item.brandID) {
          _brands.push(`${item.brandID}-${item.brandName}`);
        }
        if (item.modelID) {
          _models.push(`${item.modelID}-${item.modelName}`);
        }
        if (item.empleyooID) {
          _employees.push(`${item.empleyooID}-${item.empleyooName}`);
        }
        if (item.colorID) {
          _colors.push(`${item.colorID}-${item.colorName}`);
        }
        if (item.inventoryStatusID) {
          _stats.push(`${item.inventoryStatusID}-${item.inventoryStatusName}`);
        }
      });

      _campusList = [...new Set<string>(_campusList)];
      _builds = [...new Set<string>(_builds)];
      _floors = [...new Set<string>(_floors)];
      _rooms = [...new Set<string>(_rooms)];
      _departments = [...new Set<string>(_departments)];
      _costs = [...new Set<string>(_costs)];
      _groups = [...new Set<string>(_groups)];
      _models = [...new Set<string>(_models)];
      _mainGroups = [...new Set<string>(_mainGroups)];
      _brands = [...new Set<string>(_brands)];
      _employees = [...new Set<string>(_employees)];
      _colors = [...new Set<string>(_colors)];
      _stats = [...new Set<string>(_stats)];

      setStates({
        campusList: _campusList.map((_item) => generateItem(_item)),
        builds: _builds.map((_item) => generateItem(_item)),
        floors: _floors.map((_item) => generateItem(_item)),
        rooms: _rooms.map((_item) => generateItem(_item)),
        costs: _costs.map((_item) => generateItem(_item)),
        departments: _departments.map((_item) => generateItem(_item)),
        brands: _brands.map((_item) => generateItem(_item)),
        colors: _colors.map((_item) => generateItem(_item)),
        employees: _employees.map((_item) => generateItem(_item)),
        groups: _groups.map((_item) => generateItem(_item)),
        iStats: _stats.map((_item) => generateItem(_item)),
        mainGroups: _mainGroups.map((_item) => generateItem(_item)),
        models: _models.map((_item) => generateItem(_item)),
      });
    }
  }

  useLayoutEffect(() => {
    processData();
    getEmployees({ projectId: user!.project!.id! });
    getColors({ projectId: user!.project!.id! });
    getStats();
  }, []);

  useEffect(() => {
    if (watch("buildID") !== "-1") {
      getFloors({ buildId: `${getValues().buildID.split("-")[1]}` });
    } else {
      setValue("floorID", "-1");
    }
  }, [watch("buildID")]);

  useEffect(() => {
    if (watch("floorID") !== "-1") {
      getRooms({ floorId: `${getValues().floorID.split("-")[1]}` });
    } else {
      setValue("roomID", "-1");
    }
  }, [watch("floorID")]);
  useEffect(() => {
    if (watch("campusID") !== "-1") {
      getBuilds({ campusId: `${getValues().campusID.split("-")[1]}` });
    } else {
      setValue("buildID", "-1");
    }
  }, [watch("campusID")]);

  useEffect(() => {
    if (watch("mainGrupID") !== "-1") {
      getGroups({ groupMainID: `${getValues().mainGrupID.split("-")[1]}`, projectId: user!.project!.id! });
    } else {
      setValue("grupID", "-1");
    }
  }, [watch("mainGrupID")]);

  useEffect(() => {
    if (watch("grupID") !== "-1") {
      getBrands({ groupId: `${getValues().grupID.split("-")[1]}`, projectId: user!.project!.id! });
    } else {
      setValue("brandID", "-1");
    }
  }, [watch("grupID")]);

  useEffect(() => {
    if (watch("brandID") !== "-1") {
      getModels({ brandId: `${getValues().brandID.split("-")[1]}`, projectId: user!.project!.id! });
    } else {
      setValue("modelID", "-1");
    }
  }, [watch("brandID")]);

  function intersection(arr1: any[], arr2: any[]) {
    return arr1.filter((_item) => arr2.findIndex((_item2) => _item2.id == _item.id) !== -1);
  }

  const {
    campusList,
    builds,
    rooms,
    floors,
    costs,
    departments,
    brands,
    groups,
    mainGroups,
    models,
    colors,
    iStats,
    employees,
  } = states;

  return (
    <div>
      <Form onSubmit={handleSubmit(onValid)}>
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label>Kampüs</Label>
              <Input type="select" name="campusID" innerRef={register()}>
                <option value={"-1"} selected>
                  Seçim Yapılmadı
                </option>
                {campusList &&
                  campusList.map((item, index) => (
                    <option key={index} value={item.name + "-" + item.id}>
                      {item.name}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Bina</Label>
              <Input type="select" name="buildID" innerRef={register()} disabled={getValues().campusId === "-1"}>
                <option value={"-1"} selected>
                  Seçim Yapılmadı
                </option>
                {builds &&
                  buildList &&
                  intersection(buildList, builds).map((item, index) => (
                    <option key={index} value={item.name + "-" + item.id}>
                      {item.name}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Kat</Label>
              <Input type="select" name="floorID" innerRef={register()} disabled={getValues().buildId === "-1"}>
                <option value={"-1"} selected>
                  Seçim Yapılmadı
                </option>
                {floorList &&
                  floors &&
                  intersection(floorList, floors).map((item, index) => (
                    <option key={index} value={item.name + "-" + item.id}>
                      {item.name}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Oda</Label>
              <Input type="select" name="roomID" innerRef={register()} disabled={getValues().floorId === "-1"}>
                <option value={"-1"} selected>
                  Seçim Yapılmadı
                </option>
                {rooms &&
                  roomList &&
                  intersection(roomList, rooms).map((item, index) => (
                    <option key={index} value={item.name + "-" + item.id}>
                      {item.name}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Bölüm</Label>
              <Input type="select" name="departmentID" innerRef={register()}>
                <option value={"-1"} selected>
                  Seçim Yapılmadı
                </option>
                {departments &&
                  departments.map((item, index) => (
                    <option key={index} value={item.name + "-" + item.id}>
                      {item.name}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Masraf Yeri</Label>
              <Input type="select" name="costID" innerRef={register()} disabled={getValues().departmentId === "-1"}>
                <option value={"-1"} selected>
                  Seçim Yapılmadı
                </option>
                {costs &&
                  costs.map((item, index) => (
                    <option key={index} value={item.name + "-" + item.id}>
                      {item.name}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>

        <hr />
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label>Zimmet</Label>
              <Input type="select" name="emplooyeID" innerRef={register()}>
                <option value={"-1"} selected>
                  Seçim Yapılmadı
                </option>
                {employees &&
                  employeeList &&
                  intersection(employeeList, employees).map((item, index) => (
                    <option key={index} value={item.name + "-" + item.id}>
                      {item.nameSurname}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Durum</Label>
              <Input type="select" name="inventorystatusID" innerRef={register()}>
                <option value={"-1"} selected>
                  Seçim Yapılmadı
                </option>
                {iStats &&
                  iStatList &&
                  intersection(iStatList, iStats).map((item, index) => (
                    <option key={index} value={item.name + "-" + item.id}>
                      {item.name}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Renk</Label>
              <Input type="select" name="colorID" innerRef={register()}>
                <option value={"-1"} selected>
                  Seçim Yapılmadı
                </option>
                {colors &&
                  colorList &&
                  intersection(colorList, colors).map((item, index) => (
                    <option key={index} value={item.name + "-" + item.id}>
                      {item.colorName}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label>Ana Grup</Label>
              <Input type="select" name="mainGrupID" innerRef={register()}>
                <option value={"-1"} selected>
                  Seçim Yapılmadı
                </option>
                {mainGroups &&
                  mainGroups.map((item, index) => (
                    <option key={index} value={item.name + "-" + item.id}>
                      {item.name}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Grup</Label>
              <Input type="select" name="grupID" innerRef={register()} disabled={getValues().mainGroupId === "-1"}>
                <option value={"-1"} selected>
                  Seçim Yapılmadı
                </option>
                {groups &&
                  cgroups &&
                  intersection(groups, cgroups).map((item, index) => (
                    <option key={index} value={item.name + "-" + item.id}>
                      {item.name}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Marka</Label>
              <Input type="select" name="brandID" innerRef={register()} disabled={getValues().groupId === "-1"}>
                <option value={"-1"} selected>
                  Seçim Yapılmadı
                </option>
                {brands &&
                  cbrands &&
                  intersection(brands, cbrands).map((item, index) => (
                    <option key={index} value={item.name + "-" + item.id}>
                      {item.name}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Model</Label>
              <Input type="select" name="modelID" innerRef={register()} disabled={getValues().brandId === "-1"}>
                <option value={"-1"} selected>
                  Seçim Yapılmadı
                </option>
                {models &&
                  cmodels &&
                  intersection(models, cmodels).map((item, index) => (
                    <option key={index} value={item.name + "-" + item.id}>
                      {item.name}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={6}>
            <Label>Başlangıç Tarihi</Label>
            <Input type="date" name="startDate" innerRef={register()} />
          </Col>
          <Col md={6}>
            <Label>Bitiş Tarihi</Label>
            <Input type="date" name="endDate" innerRef={register()} />
          </Col>
        </Row>
        <hr />
        <div className="mt-2">
          <Button color="primary">Getir</Button>
          <Button color="danger" className="ml-2" outline type="button" onClick={props.toggle}>
            Vazgeç
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default InventoryFilter2;
