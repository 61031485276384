import React, { useLayoutEffect, useMemo, memo, useState } from "react";
import { Card, CardBody, Button, Input } from "reactstrap";
import DataTable, { IDataTableColumn, defaultThemes } from "react-data-table-component";
import _ from "lodash";
import ReactExport from "react-export-excel";

import "react-data-table-component-extensions/dist/index.css";
import ExcelButton from "components/ExcelButton";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
function downloadCSV(array) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  const filename = "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

const customStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: defaultThemes.default.divider.default,
    },
  },
  headCells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
  cells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
};

const Export = ({ onExport }) => (
  <Button onClick={(e) => onExport(e.target.value)} outline color="info">
    <i className="flaticon-download-1 mr-1" />
    <span>CSV İndir</span>
  </Button>
);

function DataTableComp({
  columns,
  data,
  onClickRowEdit,
  excel,
  onClickFilter,
  filterData,
  resetFilterData,
  excelData,
}) {
  const [searchText, setSearchText] = useState("");

  const tableData = {
    columns,
    data,
  };

  const seperators = columns.map((col) => col.selector);
  const filteredData = data.map((item) => {
    const itmObj = {};
    seperators.forEach((sep) => {
      itmObj[sep] = item[sep];
    });
    return itmObj;
  });

  function getSearchedData(data) {
    return data.filter((item) => {
      const d = _.values(item).some((value) => {
        if (`${!!value ? value : ""}`.toLowerCase().includes(searchText.toLowerCase())) {
          return true;
        }
      });
      return !!d;
    });
  }

  useLayoutEffect(() => {
    const el = document.getElementsByClassName("sc-kLgntA gdMBZx gaMRwo");
    if (el) {
      const row = el[0];
      if (row) {
        row.textContent = "Bir sayfada gösterilecek satır";
      }
    }
  }, []);

  const searchedData = useMemo(() => getSearchedData(data), [searchText, data]);

  class Download extends React.Component {
    render() {
      if (!excelData) {
        return null;
      }
      return <ExcelButton data={excelData} />;
    }
  }

  const actionsMemo = React.useMemo(
    () => (
      <div className="d-flex justify-content-end align-items-center w-100">
        <Input
          type="text"
          style={{ maxWidth: 200 }}
          className="mr-1"
          placeholder="Ara..."
          value={searchText}
          onChange={({ currentTarget: { value } }) => {
            setSearchText(value);
          }}
        />
        {!!excelData && <ExcelButton data={excelData} />}
        {!!excel && <Download dataSet={searchedData} />}
        {onClickRowEdit && (
          <Button outline color="primary" className="ml-1" onClick={onClickRowEdit}>
            Kolon Ayarları
          </Button>
        )}
        {onClickFilter &&
          (filterData ? (
            <Button onClick={resetFilterData} outline color="danger" className="ml-1">
              Filtre Sıfırla
            </Button>
          ) : (
            <Button onClick={onClickFilter} outline color="warning" className="ml-1">
              Filtre
            </Button>
          ))}
      </div>
    ),
    [searchText, filteredData]
  );

  return (
    <Card>
      <CardBody>
        <DataTable
          columns={columns}
          data={searchedData}
          subHeaderComponent={actionsMemo}
          subHeader
          fixedHeader={true}
          defaultSortAsc={true}
          pagination
          highlightOnHover
          selectableRows
          selectableRowsHighlight
          selectableRowsNoSelectAll
          noContextMenu
        />
      </CardBody>
    </Card>
  );
}

export default memo(DataTableComp);
