import { ReactElement, useLayoutEffect, useState, useEffect } from "react";
import DataTable from "components/ReactTable/DataTable";
import ODataTable from "components/ReactTable/ODataTable";
import { http, httpOData } from "helper/http";
import { Status, useGet, useRedux } from "hooks";
import { Page, SubHeader } from "layout/components";
import { ResponseOData } from "models/InvertoryOData";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { Button, Card, CardBody, Col, FormGroup, Label, Row, Spinner, Input } from "reactstrap";
import { Res } from "../MatchInventory/service";

interface Props {}

type FilterProperty = {
  id: string;
  value: string;
};

var queryFilter = "isActive eq true and empleyooID ne null";

function EmployeeReports({}: Props): ReactElement {
  const { register, handleSubmit, errors, setValue, getValues, watch } = useForm();
  const [state, setState] = useState({
    employees: null as any[] | null,
    isLoadingEmployees: true,
  });
  const [tableData, setTableData] = useState<any>();
  const [tableExcelData, setTableExcelData] = useState<any>();
  const [filterState, setFilterState] = useState<any>(null);
  const [tableShowCount, setTableShowCount] = useState(10);
  const [skipCount, setSkipCount] = useState(0);
  const [countState, setCountState] = useState<any>();

  const [get, stat, data] = useGet<Array<{ id: number; nameSurname: string }>, { projectID: number }>(
    "/Employee/GetEmployeesHasEntrust"
  );
  const [getList, listStat, list] = useGet<Res, { employeeId: number }>("/Inventory/GetInventoryListByEmployeeId");
  const [
    {
      auth: { user },
    },
  ] = useRedux();

  async function getEmployees() {
    const response = await http().get("/Employee/GetEmployeesHasEntrust", { params: { projectID: user!.project!.id } });
    if (response && response.data) {
      response.data.unshift({ id: 0, nameSurname: "Tümü" });
      setState((prev) => ({ ...prev, employees: response.data, isLoadingEmployees: false }));
    }
  }

  async function getAllListInventory(filter: string, top: number, skip: number) {
    setTableData(null);
    const params: Record<string, any> = {
      projectID: user?.project?.id,
      top: top,
      skip: skip,
      count: "true",
    };

    if (filter.length > 0) {
      params.filter = filter;
    }
    const response = await httpOData().get("/odata/InventoryOdata", {
      params: params,
    });
    const countResponse = (response.data as unknown as ResponseOData)["@odata.count"];
    setCountState(countResponse);
    setTableData(response.data.value);
  }

  async function getAllExcelListInventory(filter: string) {
    setTableExcelData(null);
    const params: Record<string, any> = {
      projectID: user?.project?.id,
      count: "false",
    };

    if (filter.length > 0) {
      params.filter = filter;
    }
    const response = await httpOData().get("/odata/InventoryOdata", {
      params: params,
    });
    setTableExcelData(response.data.value);
  }

  useLayoutEffect(() => {
    getAllListInventory(queryFilter, tableShowCount, skipCount);
    getEmployees();
    register("employee");
  }, []);

  useEffect(() => {
    if (!!filterState) {
      for (const key in filterState) {
        if (Object.prototype.hasOwnProperty.call(filterState, key)) {
          const property = filterState[key as keyof typeof filterState] as FilterProperty;
          const label = property?.value;
          if (key == "startDate" || key == "endDate") {
            key == "startDate"
              ? (queryFilter = queryFilter + ` and createDate gt ${label}`)
              : (queryFilter = queryFilter + ` and createDate lt ${label}`);
          } else {
            queryFilter = queryFilter + ` and ${key} eq ${property.id}`;
          }
        }
      }
      getAllListInventory(queryFilter, tableShowCount, skipCount);
      getAllExcelListInventory(queryFilter);
    }
  }, [filterState]);

  function onValid({ employee }: { employee: string }) {
    if (employee === "0") {
      getAllListInventory(queryFilter, tableShowCount, skipCount);
      getAllExcelListInventory(queryFilter);
    } else {
      queryFilter = `isActive eq true and empleyooID eq ${employee}`;
      getAllListInventory(queryFilter, tableShowCount, skipCount);
      //getList({ employeeId: parseInt(employee) });
    }
  }

  return (
    <Page>
      <SubHeader title="Zimmet Raporları"></SubHeader>

      <Card>
        <CardBody>
          {!state.isLoadingEmployees ? (
            <form onSubmit={handleSubmit(onValid)}>
              <Row className="d-flex align-items-center">
                <Col md={6}>
                  <FormGroup>
                    <Label>Zimmetli</Label>
                    {/* <Input type="select" name="employee" innerRef={register()}>
                      {data!.map((item, index) => (
                        <option key={index} value={item.id.toString()}>
                          {item.nameSurname}
                        </option>
                      ))}
                    </Input> */}
                    <Select
                      name="employee"
                      options={state.employees!.map((item) => ({ value: item.id, label: item.nameSurname }))}
                      placeholder="Zimmet Seçiniz"
                      onChange={(_val) => {
                        if (!!_val) {
                          setValue("employee", _val.value);
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <Button color="primary ml-1">Getir</Button>
                </Col>
              </Row>
            </form>
          ) : (
            <div className="p-5 d-flex justify-content-center">
              <Spinner></Spinner>
            </div>
          )}
        </CardBody>
      </Card>

      {listStat === Status.pending || !!tableData || (
        <Card className="mt-3">
          <CardBody>
            <div className="p-5 d-flex justify-content-center">
              <Spinner></Spinner>
            </div>
          </CardBody>
        </Card>
      )}

      <div className="mt-3">
        {!!tableData && (
          <ODataTable
            onFilterState={(filter) => {
              setFilterState(filter);
            }}
            onSearchBarcodeState={(barcode) => {
              queryFilter = `isActive eq true and contains(Barcode, \'${barcode}\')`;
              setSkipCount(0);
              getAllListInventory(queryFilter, tableShowCount, 0);
            }}
            resetFilterButtonVisible={!!filterState}
            data={tableData}
            pageSize={tableShowCount}
            excelData={tableExcelData}
            removeAllDebit
            bottomTableElement={
              <>
                <div>
                  <span style={{ whiteSpace: "nowrap" }} className="d-flex align-items-center gap-1">
                    Satır Göster:
                    <Input
                      className="w-60px"
                      type="select"
                      value={tableShowCount}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        setTableShowCount(value);
                        getAllListInventory(queryFilter, value, skipCount);
                        getAllExcelListInventory(queryFilter);
                      }}
                    >
                      {[10, 20, 30, 40, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </Input>
                  </span>
                </div>

                <span className="flex items-center gap-1">
                  <div>
                    <strong>{countState} veri bulundu</strong>
                  </div>
                </span>

                <div className="d-flex align-items-center gap-2">
                  <div className="mr-2">
                    {skipCount + 1} / {Math.ceil(countState / tableShowCount)}
                  </div>
                  <Button
                    className="bg-transparent border-none"
                    onClick={() => {
                      setSkipCount(0);
                      getAllListInventory(queryFilter, tableShowCount, 0);
                      getAllExcelListInventory(queryFilter);
                    }}
                    disabled={skipCount === 0}
                  >
                    <i className="flaticon2-fast-back p-0" />
                  </Button>
                  <Button
                    className="bg-transparent"
                    onClick={() => {
                      setSkipCount(skipCount - 1);
                      getAllListInventory(queryFilter, tableShowCount, (skipCount - 1) * tableShowCount);
                      getAllExcelListInventory(queryFilter);
                    }}
                    disabled={skipCount + 1 === 1}
                  >
                    <i className="flaticon2-left-arrow p-0" />
                  </Button>
                  <Button
                    className="bg-transparent"
                    onClick={() => {
                      setSkipCount(skipCount + 1);
                      getAllListInventory(queryFilter, tableShowCount, (skipCount + 1) * tableShowCount);
                      getAllExcelListInventory(queryFilter);
                    }}
                    disabled={skipCount + 1 === Math.ceil(countState / tableShowCount)}
                  >
                    <i className="flaticon2-right-arrow p-0" />
                  </Button>
                  <Button
                    className="bg-transparent border-none"
                    onClick={() => {
                      setSkipCount(Math.ceil(countState / tableShowCount) - 1);
                      getAllListInventory(
                        queryFilter,
                        tableShowCount,
                        (Math.ceil(countState / tableShowCount) - 1) * tableShowCount
                      );
                      getAllExcelListInventory(queryFilter);
                    }}
                    disabled={skipCount === Math.ceil(countState / tableShowCount) - 1}
                  >
                    <i className="flaticon2-fast-next p-0" />
                  </Button>
                </div>
              </>
            }
          />
        )}
      </div>
    </Page>
  );
}

export default EmployeeReports;
