import { User } from "models";
const itemKey = "demirbas";

export function setItem(token: string, user: User): void {
  localStorage.setItem(itemKey, JSON.stringify({ token, user }));
}

export function getItem(): { token: string; user: User } | null {
  const data: string | null = localStorage.getItem(itemKey);
  if (data) {
    return JSON.parse(data);
  } else {
    return null;
  }
}

export function removeItem(): void {
  localStorage.removeItem(itemKey);
}