import { FC, useLayoutEffect } from "react";
import { useGet, useRedux } from "hooks";
import { Inventory } from "models";
import { BarcodeParameters, reportUrls } from "../report-pages/ReportSearch/service";
import { RouteChildrenProps } from "react-router-dom";
import { Page, SubHeader } from "layout/components";
import InventoryAdd from "components/InventoryAdd/InventoryAdd";

const CreateReport: FC<RouteChildrenProps<{ barcode: string }, any>> = (props) => {
  const { barcode } = props.match!.params;

  const [
    {
      auth: { user },
    },
  ] = useRedux();
  const [barcodeGet, barcodeStatus, barcodeData] = useGet<Inventory, BarcodeParameters>(reportUrls.listByBarcode);
  useLayoutEffect(() => {
    if (barcode) {
      barcodeGet({ projectId: user!.project!.id!, barcode });
    }
  }, []);

  return (
    <Page>
      <SubHeader title="Sabit Kıymet Tanımla" />
      <div>
        {barcodeData ? (
          <InventoryAdd
            defaultValues={{
              id: barcodeData.id,
              colorID: barcodeData.colorID,
              colorName: barcodeData.colorName,
              projectID: barcodeData.projectID,
              projectName: barcodeData.projectName,
              campusID: barcodeData.campusID,
              campusName: barcodeData.campusName,
              buildID: barcodeData.buildID,
              buildName: barcodeData.buildName,
              floorID: barcodeData.floorID,
              floorName: barcodeData.floorName,
              roomID: barcodeData.roomID,
              roomName: barcodeData.roomName,
              mainGrupID: barcodeData.mainGrupID,
              mainGrupName: barcodeData.mainGrupName,
              grupID: barcodeData.grupID,
              grupName: barcodeData.grupName,
              brandID: barcodeData.brandID,
              brandName: barcodeData.brandName,
              modelID: barcodeData.modelID,
              modelName: barcodeData.modelName,
              departmentID: barcodeData.departmentID ? barcodeData.departmentID : 0,
              departmentName: barcodeData.departmentName,
              createDateFormatted: barcodeData.createDateFormatted,
              costID: barcodeData.costID,
              costName: barcodeData.costName,
              empleyooID: barcodeData.empleyooID,
              empleyooName: barcodeData.empleyooName,
              empleyooSicil: barcodeData.empleyooSicil,
              inventoryStatusID: barcodeData.inventoryStatusID,
              inventoryStatusName: barcodeData.inventoryStatusName,
              inventoryNo: barcodeData.inventoryNo,
              serialNumber: barcodeData.serialNumber,
              photoPath: barcodeData.photoPath,
              notes: barcodeData.notes,
              text5: barcodeData.text5,
              invoiceNo: barcodeData.invoiceNo,
              purchaseData: barcodeData.purchaseData,
              price: barcodeData.price,
              addUserID: barcodeData.addUserID,
              addUserName: barcodeData.addUserName,
              isActive: barcodeData.isActive,
              createDate: barcodeData.createDate,
              lastReadDate: barcodeData.lastReadDate,
              activePassiveStatusChangedDate: barcodeData.activePassiveStatusChangedDate,
              activePassiveStatusChangedUserName: barcodeData.activePassiveStatusChangedUserName,
              isActiveFormatted: barcodeData.isActiveFormatted,
              lastReadUserName: barcodeData.lastReadUserName,
              e: barcodeData.e,
              b: barcodeData.b,
              y: barcodeData.y,
              text1: barcodeData.text1,
              text2: barcodeData.text2,
              text3: barcodeData.text3,
              text4: barcodeData.text4,
              barcode: "",
              sanal: false,
            }}
          />
        ) : (
          barcode === "none" && <InventoryAdd />
        )}
      </div>
    </Page>
  );
};

export default CreateReport;
