import SelectBox from "components/SelectBox";
import { Status, useGet, useRedux } from "hooks";
import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

type Props = {
  control: any;
  setValue: any;
  defaults: any;
};

const LocationInfos = (props: Props) => {
  const [
    {
      auth: { user },
    },
  ] = useRedux();
  const [getCampusFetch, statCampus, dataCampus, messageCampus, resetCampus] = useGet<any[], any>(
    "/Campus/GetCampusListByProjectID"
  );
  const [getBuildFetch, statBuild, dataBuild, messageBuild, resetBuild] = useGet<any[], any>(
    "/Build/GetBuildListByCampusID"
  );
  const [getFloorFetch, statFloor, dataFloor, messageFloor, resetFloor] = useGet<any[], any>(
    "/Floor/GetFloorListByBuildID"
  );
  const [getRoomFetch, statRoom, dataRoom, messageRoom, resetRoom] = useGet<any[], any>("/Room/GetRoomListByFloorID");

  function getCampus() {
    getCampusFetch({ projectId: user!.project!.id });
  }

  function getBuild(campusId: number) {
    getBuildFetch({ campusID: campusId });
  }

  function getFloor(buildId: number) {
    getFloorFetch({ buildID: buildId });
  }

  function getRoom(floorId: number) {
    getRoomFetch({ floorID: floorId });
  }

  useEffect(() => {
    getCampus();
    if (props.defaults.campusId) {
      getBuild(props.defaults.campusId);
    }
    if (props.defaults.buildId) {
      getFloor(props.defaults.buildId);
    }
    if (props.defaults.floorId) {
      getRoom(props.defaults.floorId);
    }
  }, []);

  return (
    <Card>
      <CardBody>
        <h2 className="h4 mb-3">Lokasyon Bilgisi</h2>
        <Row>
          <Col xs={3}>
            <SelectBox
              label="Kampüs"
              name="campusId"
              isLoading={statCampus !== Status.success}
              control={props.control}
              options={dataCampus?.map((item: any) => ({ value: item.id, label: item.name })) ?? []}
              onChange={(campusId) => {
                props.setValue("buildId", null);
                props.setValue("floorId", null);
                props.setValue("roomId", null);
                resetBuild();
                resetFloor();
                resetRoom();
                if (campusId) {
                  getBuild(parseInt(campusId));
                }
              }}
            />
          </Col>
          <Col xs={3}>
            <SelectBox
              label="Bina"
              name="buildId"
              control={props.control}
              options={dataBuild?.map((item: any) => ({ value: item.id, label: item.name })) ?? []}
              isLoading={statBuild !== Status.success}
              onChange={(buildId) => {
                props.setValue("floorId", null);
                props.setValue("roomId", null);
                resetFloor();
                resetRoom();
                if (buildId) {
                  getFloor(parseInt(buildId));
                }
              }}
            />
          </Col>
          <Col xs={3}>
            <SelectBox
              label="Kat"
              name="floorId"
              control={props.control}
              options={dataFloor?.map((item: any) => ({ value: item.id, label: item.name })) ?? []}
              isLoading={statFloor !== Status.success}
              onChange={(floorId) => {
                props.setValue("roomId", null);
                resetRoom();
                if (floorId) {
                  getRoom(parseInt(floorId));
                }
              }}
            />
          </Col>
          <Col xs={3}>
            <SelectBox
              label="Oda"
              name="roomId"
              control={props.control}
              options={dataRoom?.map((item: any) => ({ value: item.id, label: item.name })) ?? []}
              isLoading={statRoom !== Status.success}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default LocationInfos;
