import React, { ReactNode, ReactNodeArray, memo, Fragment } from "react";
import { Link } from "react-router-dom";

type SubHeaderChildren = {
  children?: ReactNode | ReactNodeArray;
  title: string;
  breadcrumbs?: Array<{
    title: string;
    path: string;
  }>;
};

const SubHeader = ({ children, title, breadcrumbs }: SubHeaderChildren) => (
  <div className="subheader py-2 py-lg-4 subheader-solid">
    <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <div className="d-flex align-items-center flex-wrap mr-2">
        <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">{title}</h5>
        {!!breadcrumbs && (
          breadcrumbs.map((_crumb, _index) => (
            <Crumb {..._crumb} key={_index} />
          ))
        )}
      </div>
      <div className="d-flex align-items-center">{children || null}</div>
    </div>
  </div>
);

export default memo(SubHeader);

function Crumb({ path, title }: any) {
  return (
    <Fragment>
      <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>
      <Link to={path} className="text-muted font-weight-bold mr-4">
        {title}
      </Link>
    </Fragment>
  );
}
