import React, { ReactElement, useLayoutEffect } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import { Badge, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Page, SubHeader } from "layout/components";
import DataTable from "components/DataTable";
import { Status, useGet, usePost, useRedux, useToggle } from "hooks";
import { roomUrls, RoomListParams } from "services/location";
import Moment from "moment";
import ReactDOM from "react-dom";
import RoomForm from "./RoomForm";

import LocationBreadcrumb from "components/LocationBreadcrumb";

interface Props {}

function RoomList({
  match: {
    params: { floorID, buildID, campusID },
  },
}: Props & RouteComponentProps<{ campusID: string; buildID: string; floorID: string }>): ReactElement {
  const [isOpen, openToggle] = useToggle();
  const [
    {
      auth: { user },
    },
  ] = useRedux();
  const [getRooms, roomsStat, rooms] = useGet<any[], RoomListParams>(roomUrls.roomList);
  const [update] = usePost("/Room/UpdateRoom");
  const [add] = usePost("/Room/AddRoom");
  const [getCampus, campusStatus, campus] = useGet<any, { campusId: number | string }>("/Campus/GetCampusById");
  const [getBuild, buildStatus, build] = useGet<any, { buildId: number | string }>("/Build/GetBuildById");
  const [getFloor, floorStatus, floor] = useGet<any, { floorId: number | string }>("/Floor/GetFloorById");

  async function getLocations() {
    if (!!campusID) {
      getCampus({ campusId: campusID });
    }
    if (!!buildID) {
      getBuild({ buildId: buildID });
    }
    if (!!floorID) {
      getFloor({ floorId: floorID });
    }
  }

  async function onValid(fields: any) {
    await add({
      name: fields.name,
      projectID: user!.project!.id!,
      campusID: parseInt(campusID),
      buildID: parseInt(buildID),
      floorID: parseInt(fields.floorId),
    });
    openToggle();
    getRooms({ floorId: floorID });
  }

  async function handleClickToggle(row: any) {
    await update({
      ...row,
      isActive: !row.isActive,
    });
    getRooms({ floorId: floorID });
  }

  useLayoutEffect(() => {
    getRooms({ floorId: floorID });
    getLocations();
  }, []);

  return (
    <Page loading={roomsStat === Status.pending}>
      <SubHeader
        title="Odalar"
        breadcrumbs={[
          { title: "Katlar", path: `/campus/${campusID}/builds/${buildID}/floors` },
          { title: "Binalar", path: `/campus/${campusID}/builds` },
          { title: "Kampüsler", path: "/campus-list" },
        ]}
      >
        <Button size="sm" color="primary" onClick={openToggle}>
          Yeni Oda Ekle
        </Button>
      </SubHeader>
      <LocationBreadcrumb campusId={parseInt(campusID)} floorId={parseInt(floorID)} buildId={parseInt(buildID)} />
      {!!rooms && campusStatus === "fulfilled" && buildStatus === "fulfilled" && floorStatus === "fulfilled" && (
        <DataTable
          columns={[
            {
              name: "Kampüs İsmi",
              selector: "campusName",
            },
            {
              name: "Bina İsmi",
              selector: "buildName",
            },
            {
              name: "Kat İsmi",
              selector: "floorName",
            },
            {
              name: "Oda İsmi",
              selector: "name",
            },
            {
              name: "Eklenme Tarihi",
              selector: "createDate",
              cell: function (row: any) {
                return Moment(row.createDate).format("DD/MM/YYYY HH:mm");
              },
            },
            {
              name: "Durum",
              selector: "isActive",
              // render: function(data: any, type: any, row: any) {

              // },
              cell: function (row: any) {
                return <Badge color={row.isActive ? "success" : "secondary"}>{row.isActive ? "Aktif" : "Pasif"}</Badge>;
              },
            },
            {
              name: "Button",
              selector: "id",
              cell: function (row: any) {
                return (
                  <div>
                    <Button
                      color="info"
                      size="sm"
                      className="ml-1"
                      onClick={() => {
                        handleClickToggle(row);
                      }}
                    >
                      <i className="flaticon-interface-7 p-0"></i>
                    </Button>
                  </div>
                );
              },
            },
          ]}
          data={rooms.map((room) => ({
            ...room,
            campusName: campus.name,
            buildName: build.name,
            floorName: floor.name,
          }))}
        />
      )}

      <Modal isOpen={isOpen} toggle={openToggle}>
        <ModalHeader toggle={openToggle}>Kampüs Ekle</ModalHeader>
        <ModalBody>
          <RoomForm
            {...{
              campusId: campusID,
              floorId: floorID,
              buildId: buildID,
              onValid: onValid,
              projectID: user!.project!.id!,
            }}
          />
        </ModalBody>
      </Modal>
    </Page>
  );
}

export default RoomList;
