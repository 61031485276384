import InventoryEdit from "./InventoryEdit";

type Props = {
  barcode: string;
  onEdit: VoidFunction;
};

const InventoryEditModal = (props: Props) => {
  return (
    <div>
      <InventoryEdit barcode={props.barcode} onEdit={props.onEdit} />
    </div>
  );
};

export default InventoryEditModal;
