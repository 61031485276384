import React from "react";
import { Switch, Route } from "react-router-dom";
import { AuthRouter, UnauthRouter } from "router";
import Layout from "layout";

export const AuthedSwitch = () => (
  <Switch>
    <Route exact={true} path="/report-form" component={require("pages/report-pages/report").default} />
    <Route exact={true} path="/list-on-pdf" component={require("pages/report-pages/InventoryListOnPdf/index").default} />
    <Layout>
      {AuthRouter.map((route, key) => (
        <Route key={` route-${key}`} {...route} />
      ))}
    </Layout>
  </Switch>
);

export const UnauthedSwitch = () => (
  <Switch>
    {UnauthRouter.map((route, key) => (
      <Route key={` route-${key}`} {...route} />
    ))}
  </Switch>
);

export const ProjectSelectSwitch = () => (
  <Switch>
    <Route key={`route-1`} component={require("pages/ProjectSelect/").default} path="" />
  </Switch>
);
