import { Page, SubHeader } from "layout/components";
import { FC } from "react";
import { RouteChildrenProps } from "react-router-dom";
import InventoryEdit from "./InventoryEdit";

const ReportEdit: FC<RouteChildrenProps<{ barcode: string }, any>> = (props) => {
  const { barcode } = props.match!.params;

  return (
    <Page>
      <SubHeader title="Kayıt Düzenle" />
      <InventoryEdit barcode={barcode} onEdit={() => {}} />
    </Page>
  );
};

export default ReportEdit;
