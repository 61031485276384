import { useGet, useRedux } from "hooks";
import { Page, SubHeader } from "layout/components";
import { Inventory } from "models";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Card, CardBody, Spinner, Input, Button } from "reactstrap";
import InventoryTable from "../components/InventoryTable";
import { Params } from "../Detail/services";
import { Res, apiUrls } from "../MatchInventory/service";
import ODataTable from "components/ReactTable/ODataTable";
import { httpOData } from "helper/http";
import { ResponseOData } from "models/InvertoryOData";

type FilterProperty = {
  id: string;
  value: string;
};

var queryFilter = "isActive eq true and IsSanalBarcode eq true";

function SanalBarcode() {
  const [tableData, setTableData] = useState<any>();
  const [tableExcelData, setTableExcelData] = useState<any>();
  const [filterState, setFilterState] = useState<any>(null);
  const [tableShowCount, setTableShowCount] = useState(10);
  const [skipCount, setSkipCount] = useState(0);
  const [countState, setCountState] = useState<any>();
  const [allGet, allStatus, allData, allError] = useGet<Res, Params>("/Inventory/GetSanalInventoryList");
  const [
    {
      auth: { user },
    },
  ] = useRedux();
  async function _get() {
    const allData = await allGet({ projectId: user!.project!.id! });
  }

  async function getAllListInventory(filter: string, top: number, skip: number) {
    setTableData(null);
    const params: Record<string, any> = {
      projectID: user?.project?.id,
      top: top,
      skip: skip,
      count: "true",
    };

    if (filter.length > 0) {
      params.filter = filter;
    }
    const response = await httpOData().get("/odata/InventoryOdata", {
      params: params,
    });
    const countResponse = (response.data as unknown as ResponseOData)["@odata.count"];
    setCountState(countResponse);
    setTableData(response.data.value);
  }

  async function getAllExcelListInventory(filter: string) {
    setTableExcelData(null);
    const params: Record<string, any> = {
      projectID: user?.project?.id,
      count: "false",
    };

    if (filter.length > 0) {
      params.filter = filter;
    }
    const response = await httpOData().get("/odata/InventoryOdata", {
      params: params,
    });
    setTableExcelData(response.data.value);
  }

  useEffect(() => {
    if (!!filterState) {
      for (const key in filterState) {
        if (Object.prototype.hasOwnProperty.call(filterState, key)) {
          const property = filterState[key as keyof typeof filterState] as FilterProperty;
          const label = property?.value;
          if (key == "startDate" || key == "endDate") {
            key == "startDate"
              ? (queryFilter = queryFilter + ` and createDate gt ${label}`)
              : (queryFilter = queryFilter + ` and createDate lt ${label}`);
          } else {
            queryFilter = queryFilter + ` and ${key} eq ${property.id}`;
          }
        }
      }
      getAllListInventory(queryFilter, tableShowCount, skipCount);
      getAllExcelListInventory(queryFilter);
    }
  }, [filterState]);

  useLayoutEffect(() => {
    getAllListInventory(queryFilter, tableShowCount, skipCount);
    //_get();
  }, []);

  return (
    <Page>
      <SubHeader title="Sanal Barkod" />

      {!!tableData ? (
        <Card className="mt-3">
          <CardBody>
            <ODataTable
              onFilterState={(filter) => {
                setFilterState(filter);
              }}
              onSearchBarcodeState={(barcode) => {
                queryFilter = `isActive eq true and contains(Barcode, \'${barcode}\')`;
                setSkipCount(0);
                getAllListInventory(queryFilter, tableShowCount, 0);
              }}
              resetFilterButtonVisible={!!filterState}
              data={tableData}
              pageSize={tableShowCount}
              excelData={tableExcelData}
              bottomTableElement={
                <>
                  <div>
                    <span style={{ whiteSpace: "nowrap" }} className="d-flex align-items-center gap-1">
                      Satır Göster:
                      <Input
                        className="w-60px"
                        type="select"
                        value={tableShowCount}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          setTableShowCount(value);
                          getAllListInventory(queryFilter, value, skipCount);
                          getAllExcelListInventory(queryFilter);
                        }}
                      >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            {pageSize}
                          </option>
                        ))}
                      </Input>
                    </span>
                  </div>

                  <span className="flex items-center gap-1">
                    <div>
                      <strong>{countState} veri bulundu</strong>
                    </div>
                  </span>

                  <div className="d-flex align-items-center gap-2">
                    <div className="mr-2">
                      {skipCount + 1} / {Math.ceil(countState / tableShowCount)}
                    </div>
                    <Button
                      className="bg-transparent border-none"
                      onClick={() => {
                        setSkipCount(0);
                        getAllListInventory(queryFilter, tableShowCount, 0);
                        getAllExcelListInventory(queryFilter);
                      }}
                      disabled={skipCount === 0}
                    >
                      <i className="flaticon2-fast-back p-0" />
                    </Button>
                    <Button
                      className="bg-transparent"
                      onClick={() => {
                        setSkipCount(skipCount - 1);
                        getAllListInventory(queryFilter, tableShowCount, (skipCount - 1) * tableShowCount);
                        getAllExcelListInventory(queryFilter);
                      }}
                      disabled={skipCount + 1 === 1}
                    >
                      <i className="flaticon2-left-arrow p-0" />
                    </Button>
                    <Button
                      className="bg-transparent"
                      onClick={() => {
                        setSkipCount(skipCount + 1);
                        getAllListInventory(queryFilter, tableShowCount, (skipCount + 1) * tableShowCount);
                        getAllExcelListInventory(queryFilter);
                      }}
                      disabled={skipCount + 1 === Math.ceil(countState / tableShowCount)}
                    >
                      <i className="flaticon2-right-arrow p-0" />
                    </Button>
                    <Button
                      className="bg-transparent border-none"
                      onClick={() => {
                        setSkipCount(Math.ceil(countState / tableShowCount) - 1);
                        getAllListInventory(
                          queryFilter,
                          tableShowCount,
                          (Math.ceil(countState / tableShowCount) - 1) * tableShowCount
                        );
                        getAllExcelListInventory(queryFilter);
                      }}
                      disabled={skipCount === Math.ceil(countState / tableShowCount) - 1}
                    >
                      <i className="flaticon2-fast-next p-0" />
                    </Button>
                  </div>
                </>
              }
            />
          </CardBody>
        </Card>
      ) : (
        <Card className="mt-3">
          <CardBody>
            <div className="p-5 d-flex justify-content-center">
              <Spinner />
            </div>
          </CardBody>
        </Card>
      )}
    </Page>
  );
}

export default SanalBarcode;
