import InventoryAdd from "components/InventoryAdd/InventoryAdd";
import { Page, SubHeader } from "layout/components";
import React, { useLayoutEffect, useState } from "react";
import { Badge, Card, CardBody } from "reactstrap";

const InventoryBulkUpdate = () => {
  const [invetories, setInventories] = useState<any[] | null>(null);

  function getInventoriesFromStorage() {
    const inventoriesStr = localStorage.getItem("bulk-update-ids");
    if (inventoriesStr) {
      setInventories(JSON.parse(inventoriesStr));
    }
  }

  useLayoutEffect(() => {
    getInventoriesFromStorage();
  }, []);

  return (
    <Page>
      <SubHeader title="Toplu Kayıt Düzenleme" />

      <Card>
        <CardBody>
          <h6 className="h6">Düzenlenecek Zimmetler</h6>
          <div className="d-flex gap-1 flex-wrap">
          {invetories && invetories.map((inventory) => <Badge color="warning">{inventory.barcode}</Badge>)}
          </div>
        </CardBody>
      </Card>

      <InventoryAdd isEdit />
    </Page>
  );
};

export default InventoryBulkUpdate;
