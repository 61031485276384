import React, { useState, useMemo, useLayoutEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { setUser, setProject, setFullUser } from "store/actions/user-actions";
import { useRedux } from "hooks";
import Layout from "layout";
import { AuthedSwitch, UnauthedSwitch, ProjectSelectSwitch } from "components/Switch";
import Numberal from 'numeral'

import { getItem } from "helper/storageManager";

const App = () => {
  const [
    {
      auth: { isLogged },
    },
    dp,
  ] = useRedux();

  function getActiveSwitch() {
    switch (isLogged) {
      case "freeze":
        return null;
      case "login":
        return <AuthedSwitch />;
      case "unlogin":
        return <UnauthedSwitch />;
      case "project-select":
        return <ProjectSelectSwitch />;
      default:
        return null;
    }
  }

  const activeSwitch = useMemo(() => getActiveSwitch(), [isLogged]);

  useLayoutEffect(() => {
    const storageData = getItem();
    if (storageData) {
      if (storageData.user.project) {
        dp(setFullUser(storageData.user));
      } else {
        dp(setUser(storageData.user, storageData.token));
      }
    }

    Numberal.register("locale", "tr", {
      delimiters: {
        thousands: ".",
        decimal: ",",
      },
      abbreviations: {
        thousand: "bin",
        million: "milyon",
        billion: "milyar",
        trillion: "trilyon",
      },
      ordinal: function (number) {
        return "null";
      },
      currency: {
        symbol: "\u20BA",
      },
    });
    
    Numberal.locale("tr");
  }, []);

  return (
    <BrowserRouter>
      {/* {isLogged} */}
      {activeSwitch}
    </BrowserRouter>
  );
};

export default App;
