import { useRedux, useGet, Status } from "hooks";
import { Inventory } from "models";
import React, { useLayoutEffect } from "react";
import { Spinner } from "reactstrap";
import { BarcodeParameters, reportUrls } from "../ReportSearch/service";
import EditForm from "./EditForm";

type Props = {
  barcode: string;
  onEdit: () => void;
};

const InventoryEdit = (props: Props) => {
  const { barcode } = props;
  const [
    {
      auth: { user },
    },
  ] = useRedux();
  const [barcodeGet, barcodeStatus, barcodeData] = useGet<Inventory, BarcodeParameters>(reportUrls.listByBarcode);
  useLayoutEffect(() => {
    if (barcode) {
      barcodeGet({ projectId: user!.project!.id!, barcode });
    }
  }, []);

  return (
    <>
      {barcodeStatus !== Status.success && (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      )}
      {barcodeData && (
        <EditForm
          onEdit={props.onEdit}
          oldData={barcodeData}
          defaultValues={{
            photoPath: barcodeData.photoPath,
            barcode: barcodeData.barcode,
            campusId: barcodeData.campusID,
            buildId: barcodeData.buildID,
            floorId: barcodeData.floorID,
            roomId: barcodeData.roomID,
            departmentId: barcodeData.departmentID,
            costId: barcodeData.costID,
            inventoryStatId: barcodeData.inventoryStatusID,
            e: barcodeData.e,
            b: barcodeData.b,
            y: barcodeData.y,
            text1: barcodeData.text1,
            text2: barcodeData.text2,
            text3: barcodeData.text3,
            text4: barcodeData.text4,
            inventoryNo: barcodeData.inventoryNo,
            invoiceNo: barcodeData.invoiceNo,
            purchaseData: barcodeData.purchaseData,
            price: barcodeData.price,
            mainGroupId: barcodeData.mainGrupID,
            groupId: barcodeData.grupID,
            brandId: barcodeData.brandID,
            modelId: barcodeData.modelID,
            colorId: barcodeData.colorID,
            serialNumber: barcodeData.serialNumber,
            employeeId: barcodeData.empleyooID,
            notes: barcodeData.notes,
            isActive: barcodeData.isActive,
          }}
        />
      )}
    </>
  );
};

export default InventoryEdit;
