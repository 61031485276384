import React, { useState } from "react";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { http } from "helper/http";
import { Store } from "store/store";

export function useToggle(defaultValue: boolean = false): [boolean, () => void] {
  const [data, setData] = useState<boolean>(defaultValue);
  function toggle() {
    setData(!data);
  }
  return [data, toggle];
}

export function useRedux(): [Store, Dispatch<any>] {
  const reduxStore = useSelector<Store, Store>((store) => store);
  const dispatch = useDispatch();
  return [reduxStore, dispatch];
}

export enum Status {
  success = "fulfilled",
  pending = "pending",
  error = "rejected",
  initial = "initial",
}

interface FetchResponse<Data> {
  data: Data | null;
  success: boolean;
  message: string | null;
}

type UsePost<Request, Response, Params> = [
  (request: Request, params?: Params | null) => Promise<Response | null>,
  Status,
  Response | null,
  string | null,
  VoidFunction
];
export function usePost<Request, Response, Params>(queryUrl: string): UsePost<Request, Response, Params> {
  const [stat, setStat] = useState<Status>(Status.initial);
  const [response, setResponse] = useState<Response | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  async function onProcessAsync(request: Request, params: Params | null | undefined = null): Promise<Response | null> {
    try {
      setStat(Status.pending);
      const response: FetchResponse<Response> = await http()
        .post(queryUrl, request, { params })
        .then((res) => res.data);
      if (response.success) {
        setStat(Status.success);
        setErrorMessage(response.message);
        setResponse(response.data);
        return response.data;
      } else {
        setStat(Status.error);
        setErrorMessage(response.message);
        return null;
      }
    } catch (error) {
      setStat(Status.error);
      setErrorMessage("Sunucu Hatası. Lütfen destek sağlayıcısı ile iletişime geçiniz!");
      return null;
    }
  }
  function reset() {
    setStat(Status.initial);
    setResponse(null);
    setErrorMessage(null);
  }

  return [onProcessAsync, stat, response, errorMessage, reset];
}

type UseGet<Response, Params> = [
  (params?: Params | null) => Promise<Response | null>,
  Status,
  Response | null,
  string | null,
  () => void
];

export function useGet<Response, Params>(queryUrl: string): UseGet<Response, Params> {
  const [stat, setStat] = useState<Status>(Status.initial);
  const [response, setResponse] = useState<Response | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  async function onProcessAsync(params: Params | null | undefined = null): Promise<Response | null> {
    try {
      setStat(Status.pending);
      const response: FetchResponse<Response> = await http()
        .get(queryUrl, { params })
        .then((res) => res.data);
      if (response.success) {
        setResponse(response.data);
        setErrorMessage(response.message);
        setStat(Status.success);
        return response.data;
      } else {
        setErrorMessage(response.message);
        setStat(Status.error);
        return null;
      }
    } catch (error) {
      setErrorMessage("Sunucu Hatası. Lütfen destek sağlayıcısı ile iletişime geçiniz!");
      setStat(Status.error);
      return null;
    }
  }

  function reset() {
    setStat(Status.initial);
    setResponse(null);
    setErrorMessage(null);
  }

  return [onProcessAsync, stat, response, errorMessage, reset];
}
