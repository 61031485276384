import React, { useLayoutEffect } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { useForm } from "react-hook-form";
import { Status, useGet } from "hooks";
import { Campus, campusUrls } from "services/location";

interface Props {
  campusId: any;
  onValid: any;
  defaultValue?: any;
  projectID: any;
}

const BuildForm = ({ campusId, onValid, defaultValue, projectID }: Props) => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [getCampusList, campusListStat, campusList] = useGet<Campus[], { projectId: number }>(campusUrls.campusList);

  async function beforeRenderAsync() {
    await getCampusList({projectId: projectID});
    setValue("campusID", campusId);
  } 

  useLayoutEffect(() => {
    beforeRenderAsync();
  }, []);

  return (
    <Form onSubmit={handleSubmit(onValid)}>
      <FormGroup>
        <Label>Kampüs</Label>
        <Input type="select" name="campusID" innerRef={register({required: true})}>
          {campusListStat === Status.success &&
            !!campusList &&
            campusList.map((campus, index) => <option key={index} value={`${campus.id}`}>{campus.name}</option>)}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>Bina Adı</Label>
        <Input type="text" name="name" innerRef={register({ required: true })} defaultValue={defaultValue?.name} />
      </FormGroup>
      <FormGroup>
        <Button color="primary">Kaydet</Button>
      </FormGroup>
    </Form>
  );
};

export default BuildForm;
