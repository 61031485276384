import React from "react";
import { Spinner, Card, CardBody } from "reactstrap";
import { ChildrenProps } from "../";

interface PageProps {
  loading?: boolean;
}

const Page = ({ children, loading }: ChildrenProps & PageProps) => (
  <div className="d-flex flex-column-fluid" style={{ minHeight: "calc(100vh - 55px - 65px - 65px - 50px)" }}>
    <div className="container-fluid">
      {!loading ? (
        children
      ) : (
        <div className="text-center">
          <Spinner />
          <h3 className="mt-3">Yükleniyor</h3>
        </div>
      )}
    </div>
  </div>
);

export default Page;
