import React, { Fragment, useState, useLayoutEffect } from "react";
import { Page, SubHeader } from "layout/components";
import Stat from "components/Stat";
import ProgressStat from "components/ProgressStat";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { Status, useGet, useRedux } from "hooks";
import Statu from "./partials/Statu";
import Readable from "./partials/Readable";
import { Params } from "pages/report-pages/Detail/services";
import { Res, apiUrls } from "pages/report-pages/MatchInventory/service";
import { InvertoryOData, ResponseOData } from "models/InvertoryOData";
import { httpOData } from "helper/http";

interface Props {}

const Dashboard = (props: Props) => {
  const [tableData, setTableData] = useState<any>();
  const [countState, setCountState] = useState<any>();
  const [
    {
      auth: { user },
    },
  ] = useRedux();
  const [get, status, data] = useGet<any, { projectId: number }>("/Inventory/GetMatchedAndMisMatchInventoryList");
  const [allGetFetch, allStatus, allData, allError] = useGet<Res, Params>(apiUrls.all);
  const [lengthSanalBarcodes, setLSB] = useState<number>();

  async function getAllListInventory() {
    setTableData(null);
    const params: Record<string, any> = {
      projectID: user?.project?.id,
      count: "true",
    };

    const response = await httpOData().get("/odata/InventoryOdata", {
      params: params,
    });
    const countResponse = (response.data as unknown as ResponseOData)["@odata.count"];
    if (response) {
      let sumSanalBarcodes = 0;
      response.data.value.forEach((inventory: InvertoryOData) => {
        if (inventory.IsSanalBarcode) {
          sumSanalBarcodes++;
        }
      });
      setLSB(sumSanalBarcodes);
    }
    setCountState(countResponse);
    setTableData(response.data.value);
  }

  // async function allGet() {
  //   const _data = await allGetFetch({ projectId: user!.project!.id! });
  //   if (_data) {
  //     let sumSanalBarcodes = 0;
  //     _data.forEach((inventory) => {
  //       if (inventory.isSanalBarcode) {
  //         sumSanalBarcodes++;
  //       }
  //     });
  //     setLSB(sumSanalBarcodes);
  //   }
  // }

  useLayoutEffect(() => {
    get({ projectId: user!.project!.id! });
    getAllListInventory();
  }, [user]);

  return (
    <Page loading={status === Status.pending || status === Status.initial}>
      <SubHeader title="Kontrol Merkezi" />
      {status === Status.success && (
        <Fragment>
          <Row>
            <Col md={4}>
              <Stat title="Toplam Kayıt Sayısı" value={data.inventoryCount} color="dark" />
            </Col>
            <Col md={4}>
              <Stat title="Eşleşen Kayıt Sayısı" value={data.matchCount} color="primary" />
            </Col>
            <Col md={4}>
              <Stat title="Eşleşmeyen Kayıt Sayısı" value={data.misMatchCount} color="danger" />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <ProgressStat
                title="Eşleşen Kayıt Sayısı"
                value={Math.round((data.matchCount * 100) / data.inventoryCount)}
                color="success"
              />
            </Col>
            <Col md={3}>
              <ProgressStat
                title="Eşleşmeyen Kayıt Sayısı"
                value={Math.round((data.misMatchCount * 100) / data.inventoryCount)}
                color="warning"
              />
            </Col>
            <Col md={2}>
              <Stat title="RFID" value={0} color="dark" />
            </Col>
            <Col md={2}>
              {(!!lengthSanalBarcodes || lengthSanalBarcodes === 0) && !!tableData ? (
                <Stat title="Barkod" value={countState - lengthSanalBarcodes} color="info" />
              ) : (
                <Card className="bg-info">
                  <CardBody className="text-center">
                    <Spinner />
                  </CardBody>
                </Card>
              )}
            </Col>
            <Col md={2}>
              {!!lengthSanalBarcodes || lengthSanalBarcodes === 0 ? (
                <Stat title="Sanal" value={lengthSanalBarcodes} color="warning" />
              ) : (
                <Card className="bg-warning">
                  <CardBody className="text-center">
                    <Spinner />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs="12" md={6}>
              {!!tableData ? (
                <Statu data={tableData} />
              ) : (
                <Card>
                  <CardBody className="text-center">
                    <Spinner />
                  </CardBody>
                </Card>
              )}
            </Col>
            <Col xs="12" md={6}>
              {!!tableData ? (
                <Readable data={tableData} />
              ) : (
                <Card>
                  <CardBody className="text-center">
                    <Spinner />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Fragment>
      )}
    </Page>
  );
};

export default Dashboard;
