import React, { FC, useState, useEffect, useRef, HTMLProps } from "react";
import { Card, CardBody, Table, Button, Input, Badge, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import {
  ColumnDef,
  flexRender,
  getFilteredRowModel,
  getPaginationRowModel,
  getCoreRowModel,
  useReactTable,
  VisibilityState,
  ColumnOrderState,
  FilterFn,
  SortingState,
  getSortedRowModel,
  ColumnFiltersState,
} from "@tanstack/react-table";
import { Inventory, InvertoryOData } from "models";
import Lightbox from "react-image-lightbox";
import { Link, useHistory } from "react-router-dom";
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import ExcelButton from "components/ExcelButton";
import InventoryEditModal from "pages/report-pages/Edit/InventoryEditModal";
import InventoryODataFilter from "pages/report-pages/components/InventoryODataFilter";
import "react-image-lightbox/style.css";

import Numberal from "numeral";
import Moment from "moment";
import { imageUrlManipulate } from "helper/urlHelper";
import { initDB, addData, deleteData, Stores } from "helper/indexed-db";
import { usePost, useRedux } from "hooks";
import { UserEnum } from "models/user-enum";

type DataTableProps = {
  data: any;
  readablePage?: boolean;
  passivePage?: boolean;
  onFilterState: (val: any) => void;
  bottomTableElement: any;
  pageSize: any;
  resetFilterButtonVisible: boolean;
  excelData: any;
  removeAllDebit?: boolean;
  onSearchBarcodeState: (val: any) => void;
};

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta, ...rest) => {
  // Rank the item
  let isPassed = false;
  let columnValue: string = row.getValue(columnId);
  if (columnValue && typeof columnValue === "number") {
    columnValue = `${columnValue}`;
  }
  if (columnValue && typeof columnValue === "string") {
    isPassed = columnValue.includes(value) || value.includes(columnValue);
  }

  // Return if the item should be filtered in/out
  return isPassed;
};

const ODataTable: FC<DataTableProps> = (props) => {
  const [data, setData] = useState(() => [...props.data]);
  const [
    {
      auth: { user },
    },
  ] = useRedux();
  const history = useHistory();

  const defaultColumns: ColumnDef<any>[] = [
    {
      id: "select",
      accessorKey: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    },
    {
      id: "#ID",
      header: () => <span>#ID</span>,
      accessorKey: "Id",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "buttons",
      header: () => <span>Butonlar</span>,
      accessorKey: "buttons",
      cell: (info) => {
        return (
          <div className="d-flex">
            <Button
              title="Notlar"
              size="sm"
              disabled={!info.row.original.Notes}
              className="mr-1"
              onClick={() => {
                setNote(info.row.original.Notes);
              }}
              color={info.row.original.Notes ? "primary" : "secondary"}
            >
              <i className="flaticon2-notepad p-0" />
            </Button>
            <Button
              title="Fotoğraf"
              size="sm"
              disabled={!info.row.original.PhotoPath}
              className="mr-1"
              color={!!info.row.original.PhotoPath ? "info" : "secondary"}
              onClick={() => {
                if (info.row.original.PhotoPath) {
                  const imageUrl = imageUrlManipulate(info.row.original.PhotoPath);
                  setLightBoxPath(imageUrl);
                }
              }}
            >
              <i className="flaticon2-photo-camera  p-0" />
            </Button>
            <Button
              title="Döküman"
              size="sm"
              className="mr-1"
              color="danger"
              onClick={() => {
                onPdf(info.row.original);
              }}
            >
              <i className="flaticon2-file-2 p-0" />
            </Button>
            {user?.userRoleID !== UserEnum.basic && (
              <>
                <Button
                  size="sm"
                  className="mr-1"
                  title="Düzenle"
                  color="warning"
                  onClick={() => {
                    toggleOpen(info.row.original);
                  }}
                >
                  <i className="flaticon-edit-1 p-0" />
                </Button>
                <Link
                  to={`/report-edit/${info.row.original.Barcode}`}
                  target="_blank"
                  title="Yeni sayfada düzenle"
                  color="info"
                  onClick={() => {}}
                  className="btn btn-sm btn-info mr-1"
                >
                  <i className="flaticon-edit-1 p-0" />
                </Link>
                <Link to={`/create-report/${info.row.original.Barcode}`} target="_blank">
                  <Button size="sm" title="Kopya Kayıt Oluştur" color="success">
                    <i className="flaticon2-copy p-0" />
                  </Button>
                </Link>
                <Button
                  color="info"
                  size="sm"
                  className="ml-1"
                  onClick={() => {
                    setModalState(info.row.original);
                  }}
                >
                  <i className="flaticon-interface-7 p-0"></i>
                </Button>
              </>
            )}
          </div>
        );
      },
    },
    {
      id: "Barkod",
      header: () => <span>Barkod</span>,
      accessorKey: "Barcode",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Aktif/Pasif",
      header: () => <span>Durum</span>,
      accessorKey: "IsActive",
      cell: (info: any) => {
        return <Badge color={info.getValue() ? "success" : "secondary"}>{info.getValue() ? "Aktif" : "Pasif"}</Badge>;
      },
    },
    {
      id: "Envantor",
      header: () => <span>Envantor No</span>,
      accessorKey: "InventoryNo",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Alım Tarihi",
      header: () => <span>Alım Tarihi</span>,
      accessorKey: "PurchaseData",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Alım Fiyatı",
      header: () => <span>Alım Fiyatı</span>,
      accessorKey: "Price",
      cell: (info: any) => {
        return info.getValue() ? Numberal(info.getValue().split(".").join(",")).format("0,0.00") : "";
      },
    },
    {
      id: "Fatura No",
      header: () => <span>Fatura No</span>,
      accessorKey: "InvoiceNo",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Bölüm",
      header: () => <span>Bölüm</span>,
      accessorKey: "DepartmentName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Masraf Yeri",
      header: () => <span>Masraf Yeri</span>,
      accessorKey: "CostName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Kampüs",
      header: () => <span>Kampüs</span>,
      accessorKey: "CampusName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Bina",
      header: () => <span>Bina</span>,
      accessorKey: "BuildName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Kat",
      header: () => <span>Kat</span>,
      accessorKey: "FloorName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Oda",
      header: () => <span>Oda</span>,
      accessorKey: "RoomName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Ana Grup",
      header: () => <span>Ana Grup</span>,
      accessorKey: "MainGrupName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Grup",
      header: () => <span>Grup</span>,
      accessorKey: "GrupName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Tip",
      header: () => <span>Tip</span>,
      accessorKey: "Text1",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Marka",
      header: () => <span>Marka</span>,
      accessorKey: "BrandName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Model",
      header: () => <span>Model</span>,
      accessorKey: "ModelName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Seri No",
      header: () => <span>Seri No</span>,
      accessorKey: "SerialNumber",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Zimmetli",
      header: () => <span>Zimmetli</span>,
      accessorKey: "EmpleyooName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Zimmet Tarihi",
      header: () => <span>Zimmet Tarihi</span>,
      accessorKey: "LastDebitedDate",
      cell: (info: any) => {
        if (!info.getValue()) {
          return "";
        } else {
          return Moment(info.getValue())?.format("DD.MM.YYYY HH:mm");
        }
      },
    },
    {
      id: "Zimmet Oluşturan Kişi",
      header: () => <span>Zimmet Oluşturan Kişi</span>,
      accessorKey: "DebitedByName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Durum",
      header: () => <span>Durum</span>,
      accessorKey: "InventoryStatusName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Renk",
      header: () => <span>Renk</span>,
      accessorKey: "ColorName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "E-B-Y",
      header: () => <span>E-B-Y</span>,
      accessorKey: "E-B-Y",
      cell: (info: any) => {
        return `${info.row.original.E ?? "*"}-${info.row.original.B ?? "*"}-${info.row.original.Y ?? "*"}`;
      },
    },
    {
      id: "Notlar",
      header: () => <span>Notlar</span>,
      accessorKey: "Notes",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Diğer 2",
      header: () => <span>Diğer 2</span>,
      accessorKey: "Text2",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Diğer 3",
      header: () => <span>Diğer 3</span>,
      accessorKey: "Text3",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Diğer 4",
      header: () => <span>Diğer 4</span>,
      accessorKey: "Text4",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Oluşturma Tarihi",
      header: () => <span>Oluşturma Tarihi</span>,
      accessorKey: "CreateDate",
      cell: (info: any) => {
        return Moment(info.getValue()).format("DD.MM.YYYY HH:mm");
      },
    },
    {
      id: "Oluşturan Kişi",
      header: () => <span>Oluşturan Kişi</span>,
      accessorKey: "AddUserName",
      cell: (info: any) => info.getValue(),
    },
  ];

  if (!!props.readablePage) {
    defaultColumns.push({
      id: "Sayım Tarihi",
      header: () => <span>Sayım Tarihi</span>,
      accessorKey: "LastReadDate",
      cell: (info: any) => info.getValue(),
    });
    defaultColumns.push({
      id: "Sayımı Gerçekleştiren",
      header: () => <span>Sayımı Gerçekleştiren</span>,
      accessorKey: "LastReadUserName",
      cell: (info: any) => info.getValue(),
    });
  }

  if (!!props.passivePage) {
    defaultColumns.push({
      id: "Pasif Yapım Tarihi",
      header: () => <span>Pasif Yapım Tarihi</span>,
      accessorKey: "ActivePassiveStatusChangedDate",
      cell: (info: any) => info.getValue(),
    });
    defaultColumns.push({
      id: "Pasif Yapan Kişi",
      header: () => <span>Pasif Yapan Kişi</span>,
      accessorKey: "ActivePassiveStatusChangedUserName",
      cell: (info: any) => info.getValue(),
    });
  }

  const [columns] = useState<typeof defaultColumns>(() => [...defaultColumns]);
  const [colonSettings, setColonSettings] = useState<boolean>(false);
  const [removeAllDebitModal, setRemoveAllDebitModal] = useState<boolean>(false);

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnPinning, setColumnPinning] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [filter, setFilter] = useState<boolean>(false);
  const [note, setNote] = useState<string | null>(null);
  const [lightBoxPath, setLightBoxPath] = useState<string | null>(null);
  const linkRef = useRef<any>(null);
  const [isOpen, toggleOpen] = useState<InvertoryOData | null>(null);
  const [isFilterOpen, toggleFilter] = useState<boolean>(false);
  const [isDBReady, setIsDBReady] = useState<boolean>(false);

  const [modalState, setModalState] = useState<InvertoryOData | null>(null);
  const [postUpdate, statUpdate, dataUpdate, messageUpdate, resetUpdate] = usePost("/InventoryAdd/UpdateInventory");
  const [removeAllDebit, statAllDebit, , removeAllDebitmsg] = usePost("/Inventory/RemoveDebits");

  const tableData = data.filter((item) => {
    let isFiltered = true;
    columnFilters.forEach((cF) => {
      let isMatch = false;
      Object.values(item).forEach((_value) => {
        if (_value === cF.value) {
          isMatch = true;
        }
      });
      isFiltered = isMatch;
    });
    return isFiltered;
  });

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnVisibility,
      columnOrder,
      columnPinning,
      //globalFilter,
      sorting,
      rowSelection,
      columnFilters,
    },
    globalFilterFn: fuzzyFilter,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    //onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onColumnPinningChange: setColumnPinning,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  function getExcelData() {
    let tmpData: any[] = [];
    props.excelData?.forEach((item: any, index: number) => {
      let tmpItem: any = {};
      const _keys = [
        "Id",
        "Barcode",
        "IsActive",
        "InventoryNo",
        "PurchaseData",
        "Price", // Add
        "InvoiceNo",
        "DepartmentName",
        "CostName",
        "CampusName",
        "BuildName",
        "FloorName",
        "RoomName",
        "MainGrupName",
        "GrupName",
        "Text1",
        "BrandName",
        "ModelName",
        "SerialNumber",
        "EmpleyooName", //add
        "InventoryStatusName",
        "ColorName",
        "EBY",
        "Notes", // add
        "Text2",
        "Text3",
        "Text4",
        "CreateDate",
        "AddUserName",
        "PhotoPath",
      ];
      if (Object.keys(rowSelection).length > 0) {
        Object.keys(rowSelection).map((data: any) => {
          if (index === +data) {
            _keys.forEach((key) => {
              if (key === "CreateDate") {
                tmpItem["Oluşturulma Tarihi"] = item["CreateDate"]
                  ? Moment(item["CreateDate"], "DD.MM.YYYY").format("YYYY.MM.DD")
                  : "";
              } else if (key === "EBY") {
                tmpItem["En Boy Yükseklik"] = item["EBY"];
              } else if (key === "IsActive") {
                tmpItem["Aktif / Pasif"] = item["IsActiveFormatted"];
              } else if (key === "Price") {
                tmpItem["Alım Fiyatı"] = item["Price"];
              } else if (key === "EmpleyooName") {
                tmpItem["Zimmetli"] = item["EmpleyooName"];
              } else if (key === "Notes") {
                tmpItem["Not"] = item["Notes"];
              } else if (key === "PhotoPath") {
                tmpItem["Fotoğraf"] = item["PhotoPath"];
              } else if (key === "PurchaseData") {
                tmpItem["Alım Tarihi"] = item["PurchaseData"]
                  ? Moment(item["PurchaseData"], "DD.MM.YYYY").format("YYYY.MM.DD")
                  : "";
              } else {
                columns.find((data: any) => {
                  if (data.accessorKey === key) {
                    tmpItem[data.id as string] = item[data.accessorKey as string];
                  }
                });
              }
            });
            tmpData.push(tmpItem);
          }
        });
      } else {
        _keys.forEach((key) => {
          if (key === "CreateDate") {
            tmpItem["Oluşturulma Tarihi"] = item["CreateDateFormatted"]
              ? Moment(item["CreateDateFormatted"], "DD.MM.YYYY")?.format("YYYY.MM.DD")
              : "";
          } else if (key === "EBY") {
            tmpItem["En Boy Yükseklik"] = item["EBY"];
          } else if (key === "IsActive") {
            tmpItem["Aktif / Pasif"] = item["IsActiveFormatted"];
          } else if (key === "Price") {
            tmpItem["Alım Fiyatı"] = item["Price"];
          } else if (key === "EmpleyooName") {
            tmpItem["Zimmetli"] = item["EmpleyooName"];
          } else if (key === "Notes") {
            tmpItem["Not"] = item["Notes"];
          } else if (key === "PhotoPath") {
            tmpItem["Fotoğraf"] = item["PhotoPath"];
          } else if (key === "PurchaseData") {
            tmpItem["Alım Tarihi"] = item["PurchaseData"]
              ? Moment(item["PurchaseData"], "DD.MM.YYYY")?.format("YYYY.MM.DD")
              : "";
          } else {
            columns.find((data: any) => {
              if (data.accessorKey === key) {
                tmpItem[data.id as string] = item[data.accessorKey as string];
              }
            });
          }
        });
        tmpData.push(tmpItem);
      }
    });

    if (!columnFilters) {
      return tmpData;
    }

    return tmpData.filter((item) => {
      let isFiltered = true;

      Object.entries(item).forEach(([key, value]: any) => {
        const filterItem = columnFilters.find((_filterItem) => _filterItem.id === key);
        if (filterItem) {
          if (filterItem.value !== value) {
            isFiltered = false;
          }
        }
      });

      return isFiltered;
    });
  }

  function handleClickTableToPdf() {
    localStorage.setItem("pdf-column-filter", JSON.stringify(columnFilters));
    window.open("/list-on-pdf", "_new");
  }

  function onPdf(inventory: Inventory) {
    localStorage.removeItem("@pdf-report");
    localStorage.setItem("@pdf-report", JSON.stringify(inventory));
    linkRef.current.click();
  }

  async function handleInitDB() {
    const status = await initDB();
    setIsDBReady(status);
  }
  const selectedRows = table.getSelectedRowModel().rows.map((row) => row.original);

  useEffect(() => {
    table.setPageSize(props.pageSize);
  }, [props.pageSize]);

  useEffect(() => {
    handleInitDB();
  }, []);

  async function onValid(fields: any) {
    const body: any = {
      id: fields.Id,
      isActive: !fields.IsActive,
      campusID: parseInt(fields.CampusID),
      buildID: parseInt(fields.BuildID),
      floorID: parseInt(fields.FloorID),
      roomID: parseInt(fields.RoomID),
      grupId: parseInt(fields.GrupID),
      brandID: parseInt(fields.BrandID),
      modelID: fields.ModelID ?? null,
      colorID: fields.ColorID ? parseInt(fields.ColorID) : 0,
      colorName: fields.ColorName,
      inventoryStatusID: fields.InventoryStatusID ? parseInt(fields.InventoryStatusID) : 0,
      inventoryNo: fields.InventoryNo,
      serialNumber: fields.SerialNumber,
      invoiceNo: fields.InvoiceNo,
      purchaseData: fields.PurchaseData,
      notes: fields.Notes,
      departmentID: parseInt(fields.DepartmentId),
      costID: fields.DostId ?? null,
      price: fields.Price ? fields.Price.split(",").join(".") : "",
      text1: fields.Text1,
      text2: fields.Text2,
      text3: fields.Text3,
      text4: fields.Text4,
      empleyooID: parseInt(fields.EmployeeID),
      projectID: fields.ProjectID,
      projectName: fields.ProjectName,
      campusName: fields.CampusName,
      buildName: fields.BuildName,
      floorName: fields.FloorName,
      roomName: fields.RoomName,
      mainGrupID: fields.MainGrupID,
      mainGrupName: fields.MainGrupName,
      grupName: fields.GrupName,
      brandName: fields.BrandName,
      modelName: fields.ModelName,
      departmentName: fields.DepartmentName,
      costName: fields.CostName,
      empleyooName: fields.EmpleyooName,
      empleyooSicil: fields.EmpleyooSicil,
      inventoryStatusName: fields.InventoryStatusName,
      barcode: fields.Barcode,
      e: fields.E,
      b: fields.B,
      y: fields.Y,
      text5: fields.Text5,
      isSanalBarcode: fields.IsSanalBarcode,
      addUserID: fields.AddUserID,
      addUserName: fields.AddUserName,
      createDate: fields.CreateDate,
      eby: fields.Eby,
      isActiveFormatted: fields.IsActiveFormatted,
      createDateFormatted: fields.CreateDateFormatted,
      lastReadDate: fields.LastReadDate,
      lastReadUserName: fields.LastReadUserName,
      activePassiveStatusChangedUserName: fields.ActivePassiveStatusChangedUserName,
      activePassiveStatusChangedDate: fields.ActivePassiveStatusChangedDate,
      photoPath: fields.PhotoPath,
    };
    try {
      await postUpdate(body);
      window.location.href = "/report-match";
    } catch (error) {}
  }

  async function removeAllDebitRequest() {
    const inventoryIds: any[] = [];
    selectedRows.map((data: any) => {
      inventoryIds.push(data.id);
    });
    await removeAllDebit({ inventoryIds: inventoryIds });
  }

  return (
    <Card className="mt-3">
      <CardBody>
        <div className="d-flex justify-content-between align-items-center w-100 mb-5">
          <div>
            {selectedRows.length > 0 && (
              <>
                {/* <Button
                  color="warning"
                  onClick={() => {
                    localStorage.setItem(
                      "bulk-update-ids",
                      JSON.stringify(selectedRows.map((row) => ({ id: row.id, barcode: row.barcode })))
                    );
                    window.open("/report-edit-bulk", "new");
                  }}
                >
                  Düzenle
                </Button> */}
              </>
            )}
            {user?.userRoleID !== UserEnum.basic && (
              <div className="d-flex gap-2 align-items-center">
                <Button size="sm" className="mr-1" title="Düzenle" color="warning">
                  <i className="flaticon-edit-1 p-0" />
                </Button>
                <span className="ml-1">Modal içerisinde düzenle</span>
                <Button title="Yeni Pencerede Düzenle" color="info" className="btn btn-sm btn-info mr-1">
                  <i className="flaticon-edit-1 p-0" />
                </Button>
                <span className="ml-1">Yeni sayfada düzenle</span>
                <Button size="sm" title="Düzenle" color="success">
                  <i className="flaticon2-copy p-0" />
                </Button>
                <span className="ml-1">Kopya Kayıt Oluştur</span>
                <Button color="info" size="sm" className="ml-1" onClick={() => {}}>
                  <i className="flaticon-interface-7 p-0"></i>
                </Button>
                <span className="ml-1">Aktif/Pasif et</span>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center">
            <Input
              type="text"
              style={{ maxWidth: 200 }}
              className="mr-1"
              placeholder="Barcode Ara"
              onChange={({ currentTarget: { value } }) => {
                setGlobalFilter(value);
              }}
            />
            {globalFilter && (
              <Button
                color="primary"
                onClick={() => props?.onSearchBarcodeState(globalFilter)}
                className="ml-1"
                style={{ minWidth: 110 }}
              >
                Barcode Ara
              </Button>
            )}
            {props?.removeAllDebit && selectedRows.length > 0 && (
              <Button
                color="danger"
                onClick={() => setRemoveAllDebitModal(true)}
                className="ml-1"
                style={{ minWidth: 180 }}
              >
                Zimmetleri kaldır
              </Button>
            )}
            {!!props.excelData ? (
              <ExcelButton data={getExcelData()} />
            ) : (
              <Button outline color="success" className="ml-1">
                <Spinner size="sm" />
              </Button>
            )}

            {selectedRows.length > 0 && (
              <Button
                onClick={async () => {
                  if (props.data) {
                    if (!isDBReady) {
                      handleInitDB();
                    } else {
                      await deleteData(Stores.Docs, 1);
                      await addData(Stores.Docs, {
                        id: 1,
                        data: selectedRows,
                      });
                      window.open("/list-on-pdf", "_new");
                    }
                  }
                }}
                className="ml-1"
                color="danger"
                outline
              >
                Pdf({selectedRows.length})
              </Button>
            )}

            <Button
              outline
              color="primary"
              className="ml-1"
              style={{ minWidth: 120 }}
              onClick={() => setColonSettings(true)}
            >
              Kolon Ayarları
            </Button>
            {props.resetFilterButtonVisible ? (
              <Button
                onClick={() => {
                  setFilter(false);
                  props.onFilterState(null);
                  window.location.reload();
                }}
                style={{ minWidth: 100 }}
                outline
                color="danger"
                className="ml-1"
              >
                Filtre Sıfırla
              </Button>
            ) : (
              <Button onClick={() => toggleFilter(true)} outline color="warning" className="ml-1">
                Filtre
              </Button>
            )}
          </div>
        </div>

        <Table responsive>
          <div className="scroll-position-content">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      <div style={{ whiteSpace: "nowrap" }} className="d-flex align-items-center px-5">
                        {header.isPlaceholder ? null : header.id !== "buttons" && header.id !== "select" ? (
                          <div
                            {...{
                              className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: (
                                <i
                                  style={{ color: "#000", fontSize: "12px", marginLeft: "5px" }}
                                  className="flaticon2-up p-0"
                                />
                              ),
                              desc: (
                                <i
                                  style={{ color: "#000", fontSize: "12px", marginLeft: "5px" }}
                                  className="flaticon2-down p-0"
                                />
                              ),
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        ) : (
                          <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      <div style={{ whiteSpace: "nowrap" }} className="d-flex min-h-40px align-items-center px-5">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </div>
        </Table>

        <div className="d-flex mt-5 align-items-center justify-content-between gap-2">{props.bottomTableElement}</div>
      </CardBody>

      <Modal
        isOpen={colonSettings}
        toggle={() => {
          setColonSettings(false);
        }}
      >
        <ModalHeader>Kolonları Göster / Gizle</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-wrap">
            {table.getAllLeafColumns().map(
              (column) =>
                column.id !== "select" && (
                  <div key={column.id} className="d-flex w-50 align-items-center mb-2">
                    <label>
                      <input
                        {...{
                          type: "checkbox",
                          checked: column.getIsVisible(),
                          onChange: column.getToggleVisibilityHandler(),
                        }}
                      />{" "}
                      {column.id}
                    </label>
                  </div>
                )
            )}
          </div>
        </ModalBody>
      </Modal>
      <Modal size="xl" isOpen={!!isOpen} fade={false}>
        <ModalBody>
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="m-0">Sabit Kıymet Düzenle</h3>
            <Button
              color="danger"
              size="sm"
              onClick={() => {
                toggleOpen(null);
              }}
            >
              Kapat
            </Button>
          </div>
          <hr />
          {!!isOpen && (
            <InventoryEditModal
              barcode={isOpen.Barcode!}
              onEdit={() => {
                toggleOpen(null);
              }}
            />
          )}
        </ModalBody>
      </Modal>
      <Modal
        isOpen={!!note}
        toggle={() => {
          setNote(null);
        }}
        centered
      >
        <ModalHeader>Not</ModalHeader>
        <ModalBody>
          <p>{note}</p>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={isFilterOpen}
        toggle={() => {
          toggleFilter(!isFilterOpen);
        }}
        size="xl"
        clasName="w-300"
      >
        <ModalBody>
          <InventoryODataFilter
            isOpen={isFilterOpen}
            toggle={() => {
              toggleFilter(!isFilterOpen);
            }}
            onFilter={(v) => {
              setFilter(true);
              props.onFilterState(v);
            }}
            onReset={() => {
              setFilter(false);
              props.onFilterState(null);
            }}
            data={data}
          />
        </ModalBody>
      </Modal>
      {!!lightBoxPath && (
        <Lightbox
          mainSrc={lightBoxPath}
          onCloseRequest={() => {
            setLightBoxPath(null);
          }}
        />
      )}
      {!!isOpen && (
        <InventoryEditModal
          barcode={isOpen.Barcode!}
          onEdit={() => {
            toggleOpen(null);
          }}
        />
      )}
      <Modal
        isOpen={removeAllDebitModal}
        toggle={() => {
          setRemoveAllDebitModal(false);
        }}
        centered
      >
        <ModalHeader>Uyarı</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-wrap">Bütün Zimmetleri kaldırmak istediğinize emin misiniz?</div>
          <div className="d-flex justify-content-end mt-5">
            <Button color="danger" className="mr-5" onClick={() => removeAllDebitRequest()}>
              Kaldır
            </Button>
            <Button
              color="success"
              onClick={() => {
                setRemoveAllDebitModal(false);
              }}
            >
              Kapat
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={!!modalState} toggle={() => setModalState(null)} centered>
        <ModalHeader>Rapor Pasif Et</ModalHeader>
        <ModalBody>
          <div>
            Raporu {modalState?.IsActive ? <strong>pasif</strong> : <strong>aktif</strong>} etmek istediğinize emin
            misiniz?
          </div>
          <div className="d-flex justify-content-end gap-2">
            <Button size="md" color="danger" title="Kapat" onClick={() => setModalState(null)}>
              Kapat
            </Button>
            <Button size="md" color="success" title="Evet" onClick={() => onValid(modalState)}>
              Evet
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Link
        to="/report-form"
        target="_blank"
        innerRef={linkRef}
        style={{ position: "absolute", width: 0, height: 0 }}
      ></Link>
    </Card>
  );
};

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return <input type="checkbox" ref={ref} className={className + " cursor-pointer"} {...rest} />;
}

export default ODataTable;
