import { useRedux } from "hooks";
import { UserEnum } from "models/user-enum";
import { useState } from "react";
import { Link } from "react-router-dom";
import { sidebarItems } from "./item-list";

export type Stat = "hover" | "open" | "close";

interface Props {
  stat: Stat;
  onClickToggle: () => void;
  onHover: () => void;
  onHoverOut: () => void;
}

const Sidebar = ({ stat, onClickToggle, onHover, onHoverOut }: Props) => {
  const [
    {
      auth: { user },
    },
  ] = useRedux();
  const role = user?.userRoleID === UserEnum.basic;
  var filteredData = sidebarItems;
  if (role) {
    filteredData = sidebarItems.filter((item) => item.path !== "/employee" && item.path !== "/user");
  }

  return (
    <div className="aside aside-left aside-fixed d-flex flex-column flex-row-auto" onMouseLeave={onHoverOut}>
      <div className="brand flex-column-auto">
        <img alt="Logo" src={user?.companyLogo} style={{ height: 40 }} />

        <button className="brand-toggle btn btn-sm px-0" onClick={onClickToggle}>
          <span className="svg-icon svg-icon svg-icon-xl">
            <BannerSvg />
          </span>
        </button>
      </div>

      <div className="aside-menu-wrapper flex-column-fluid" onMouseEnter={onHover}>
        <div className="aside-menu my-4">
          <ul className="menu-nav">
            {filteredData.map((item, key) =>
              item.subs ? <MenuItemArray {...item} subs={item.subs} key={key} /> : <MenuItem key={key} {...item} />
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

interface MenuItemProps {
  active?: boolean;
  title: string;
  icon: string;
  path: string;
}
function MenuItem({ active, icon, path, title }: MenuItemProps) {
  return (
    <li className={`menu-item ${active ? "menu-item-active" : ""}`}>
      <Link to={path} className="menu-link">
        <span className="svg-icon menu-icon">
          <MenuItemSvg />
        </span>
        <span className="menu-text">{title}</span>
      </Link>
    </li>
  );
}

interface MenuItemArrayProps {
  defaultOpen?: boolean;
  title: string;
  path: string;
  subs: { title: string; path: string }[];
}
function MenuItemArray({ defaultOpen, path, title, subs }: MenuItemArrayProps) {
  const [isOpen, setOpen] = useState<boolean>(!!defaultOpen);
  return (
    <li className={`menu-item menu-item-submenu ${isOpen ? "menu-item-open" : ""}`}>
      <a
        href="javascript:;"
        className="menu-link menu-toggle"
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        <span className="svg-icon menu-icon">
          <MenuArrow />
        </span>
        <span className="menu-text">{title}</span>
        <i className="menu-arrow"></i>
      </a>
      <div className="menu-submenu">
        <i className="menu-arrow"></i>
        <ul className="menu-subnav">
          <li className="menu-item menu-item-parent" aria-haspopup="true">
            <span className="menu-link">
              <span className="menu-text">{title}</span>
            </span>
          </li>
          {subs.map((sub, key) => (
            <MenuItemArraySub {...sub} key={key} parentPath={path} />
          ))}
        </ul>
      </div>
    </li>
  );
}

interface MenuItemArraySubProps {
  active?: boolean;
  path: string;
  parentPath: string;
  title: string;
}
function MenuItemArraySub({ parentPath, path, title, active }: MenuItemArraySubProps) {
  return (
    <li className="menu-item">
      <Link to={`${parentPath}${path}`} className="menu-link">
        <i className="menu-bullet menu-bullet-dot">
          <span></span>
        </i>
        <span className="menu-text">{title}</span>
      </Link>
    </li>
  );
}

function Section({ title }: { title: string }) {
  return (
    <li className="menu-section">
      <h4 className="menu-text">{title}</h4>
      <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
    </li>
  );
}

function MenuArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z"></path>
        <rect width="7" height="7" x="4" y="4" fill="#000" rx="1.5"></rect>
        <path
          fill="#000"
          d="M5.5 13h4a1.5 1.5 0 011.5 1.5v4A1.5 1.5 0 019.5 20h-4A1.5 1.5 0 014 18.5v-4A1.5 1.5 0 015.5 13zm9-9h4A1.5 1.5 0 0120 5.5v4a1.5 1.5 0 01-1.5 1.5h-4A1.5 1.5 0 0113 9.5v-4A1.5 1.5 0 0114.5 4zm0 9h4a1.5 1.5 0 011.5 1.5v4a1.5 1.5 0 01-1.5 1.5h-4a1.5 1.5 0 01-1.5-1.5v-4a1.5 1.5 0 011.5-1.5z"
          opacity="0.3"
        ></path>
      </g>
    </svg>
  );
}

function BannerSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0L24 0 24 24 0 24z"></path>
        <path
          fill="#000"
          fillRule="nonzero"
          d="M12.707 6.707a1 1 0 00-1.414-1.414l-6 6a1 1 0 00-.03 1.383l5.5 6a1 1 0 001.474-1.352L7.384 12.03l5.323-5.323z"
        ></path>
        <path
          fill="#000"
          fillRule="nonzero"
          d="M19.707 6.707a1 1 0 10-1.414-1.414l-6 6a1 1 0 00-.03 1.383l5.5 6a1 1 0 101.474-1.352l-4.853-5.294 5.323-5.323z"
          opacity="0.3"
        ></path>
      </g>
    </svg>
  );
}

function MenuItemSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0L24 0 24 24 0 24z"></path>
        <path
          fill="#000"
          fillRule="nonzero"
          d="M12.934 16.072l6.426-5.116.158-.125a1.5 1.5 0 00.003-2.35l-6.587-5.238a1.5 1.5 0 00-1.868 0L4.475 8.485a1.5 1.5 0 00-.001 2.347l.156.124 6.436 5.117a1.5 1.5 0 001.868 0z"
        ></path>
        <path
          fill="#000"
          d="M11.056 18.67l-5.72-4.547a.903.903 0 00-1.276.154.91.91 0 00.166 1.279l6.861 5.247a1.5 1.5 0 001.823 0l6.858-5.243a.915.915 0 00.167-1.288.91.91 0 00-1.286-.155l-5.725 4.554a1.5 1.5 0 01-1.868 0z"
          opacity="0.3"
        ></path>
      </g>
    </svg>
  );
}
