import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
// import logger from "redux-logger";
import { userReducer, IUserStore } from "./reducers/user-reducer";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const allEnhancers = composeEnhancers(
  applyMiddleware(thunk),
)

export interface Store {
  auth: IUserStore,
}

export const store = createStore(
  combineReducers({
    auth: userReducer,
  }),
  allEnhancers
);

