import SkyInput from "components/Forms/SkyInput";
import SelectBox from "components/SelectBox";
import { useRedux, useGet, Status } from "hooks";
import React, { useEffect } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

type Props = {
  control: any;
  setValue: any;
  defaults: any;
};

const EmployeeInfo = (props: Props) => {
  const [
    {
      auth: { user },
    },
  ] = useRedux();
  const [getEmployeeFetch, statEmployee, dataEmployee] = useGet<any[], any>("/Employee/GetEmployeeListByProjectID");

  function getColors() {
    getEmployeeFetch({ projectId: user!.project!.id });
  }

  useEffect(() => {
    getColors();
  }, []);

  return (
    <Card>
      <CardBody>
        <h2 className="h4 mb-3">Zimmet</h2>
        <Row>
          <Col xs={3}>
            <SelectBox
              label="Personel"
              name="employeeId"
              isLoading={statEmployee !== Status.success}
              control={props.control}
              options={dataEmployee?.map((item: any) => ({ value: item.id, label: item.nameSurname })) ?? []}
              addParams={{
                projectId: user!.project!.id!,
                companyId: user!.companyID,
              }}
              addurl="/Employee/AddEmployee"
              getList={getColors}
            />
          </Col>

          <Col xs={3}>
            <SkyInput name="notes" control={props.control} label="Notlar" isTextarea />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default EmployeeInfo;
