export const userUrls = {
  list: "/User/getUserListByInProject",
  add: "/User/addUser",
};

export interface UserListParams {
  projectId: string | number;
}

export interface UserCreate {
  projectID?:    number;
  userRoleID?:   number;
  companyID?:    number;
  name?:         string;
  surname?:      string;
  userName?:     string;
  email?:        string;
  passwordSalt?: string;
  passwordHash?: string;
  phone?:        string;
}

export interface User {
  id:                   number;
  userRoleID?:           number;
  companyID?:            number;
  name?:                 string;
  surname?:              string;
  userName?:             string;
  email?:                null;
  passwordSalt?:         null;
  passwordHash?:         null;
  phone?:                null;
  notificationToken?:    null;
  isNotificationEnable?: null;
  isActive?:             boolean;
  createDate?:           Date;
}
