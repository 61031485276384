import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store/store";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/bootstrap.css";
import "./assets/plugins/flaticon/flaticon.css";
import "./assets/plugins/flaticon2/flaticon.css";
import "./assets/plugins/keenthemes-icons/font/ki.css";
import "./assets/css/themes/layout/header/base/light.css";
import "./assets/css/themes/layout/header/menu/light.css";
import "./assets/css/themes/layout/brand/dark.css";
import "./assets/css/themes/layout/aside/dark.css";
import "./assets/css/custom.css";

ReactDOM.render(
  <Provider {...{ store }}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
