import { useGet, useRedux } from "hooks";
import { Page, SubHeader } from "layout/components";
import React, { useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Card, CardBody, Form, Button } from "reactstrap";
import { Radio } from "../MatchInventory";
import { apiUrls, Params, Res } from "../MatchInventory/service";
import { Inventory } from "models";
import swal from "sweetalert2";
import InventoryTable from "../components/InventoryTable";
import DataTable from "components/ReactTable/DataTable";

interface Props {}

const Readable = ({}: Props) => {
  const [
    {
      auth: { user },
    },
  ] = useRedux();
  const [allGet, allStatus, allData, allError] = useGet<Res, Params>(apiUrls.all);
  const [goPassive, passivestatus] = useGet<any, Params>("/Inventory/UpdatePassiveForNotLastReadDate");
  const [goRest, resetStatus] = useGet<any, Params>("/Inventory/ResetLastReadDate");
  const [inventories, setInventories] = useState<Inventory[] | null>(null);
  const [isShow, setShow] = useState(false);

  const { handleSubmit, register } = useForm();

  function onValid({ matchReport }: { matchReport: "1" | "2" }) {
    setShow(false);
    if (allData) {
      if (matchReport === "1") {
        setInventories(allData.filter((item) => !!item.lastReadDate));
      } else {
        setInventories(allData.filter((item) => !item.lastReadDate));
        setShow(true);
      }
    }
  }

  function handleClickDoPassive() {
    swal
      .fire({
        icon: "warning",
        title: "Bulunamayan Kayıtlar Pasif Yapılacak",
        text: "Tabloda listelenen tüm bulunamayan kayıtlar pasif yapılacaktır. İşleme devam edilsin mi?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Devam Et",
        cancelButtonText: "Vazgeç",
      })
      .then((value) => {
        if (value.isConfirmed) {
          setInventories(null);
          goPassive({ projectId: user!.project!.id! }).then(() => {
            allGet({ projectId: user!.project!.id! });
          });
        }
      });
  }
  function handleClickDoReset() {
    swal
      .fire({
        icon: "warning",
        title: "Sayım Sonuçları Sıfırlanacak",
        text: "Tüm sayım sonuçları sıfırlanacaktır. İşleme devam edilsin mi?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Devam Et",
        cancelButtonText: "Vazgeç",
      })
      .then((value) => {
        if (value.isConfirmed) {
          setInventories(null);
          goRest({ projectId: user!.project!.id! }).then(() => {
            allGet({ projectId: user!.project!.id! });
          });
        }
      });
  }

  useLayoutEffect(() => {
    allGet({ projectId: user!.project!.id! });
  }, []);

  return (
    <Page loading={allStatus !== "fulfilled" || passivestatus === "pending" || resetStatus === "pending"}>
      <SubHeader
        title="Sayım Sonuçları"
        children={
          <div className="d-flex">
            <Button color="warning" size="sm" onClick={handleClickDoReset}>
              Sayımı Sıfırla
            </Button>
            <Button color="danger ml-1" size="sm" onClick={handleClickDoPassive}>
              Bulunamayan Kayıtları Pasif Yap
            </Button>
          </div>
        }
      />
      <Card>
        <CardBody>
          <h3>Sayım Sonuçları Raporu</h3>
          <Form onSubmit={handleSubmit(onValid)}>
            <div className="d-flex align-items-center mt-5">
              <Radio margin={5} title="Sayılan Kayıtlar" selected innerRef={register()} value={"1"} />
              <Radio title="Bulunamayan Kayıtlar" innerRef={register()} value={"2"} />
              <Button color="primary ml-10">Getir</Button>
            </div>
          </Form>
        </CardBody>
      </Card>
      {inventories && (
        <div className="mt-3">
          <DataTable data={inventories} readablePage />
        </div>
      )}
    </Page>
  );
};

export default Readable;
