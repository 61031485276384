import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { Spinner } from "reactstrap";
import { usePost, useGet, useRedux, Status } from "hooks";
import { setUser } from "store/actions/user-actions";
import { authUrls } from "services/auth";
import { User } from "models";
import { setItem } from "helper/storageManager";
import Logo from "assets/media/logos/logo-letter-9.png";
import BackgroundImage from "assets/media/bg/bg-1.jpg";
import "assets/css/pages/login/classic/login-3.css";

interface Props {}

interface LoginForm {
  username: string;
  password: string;
}

const LoginPage = (props: Props) => {
  const { errors, register, handleSubmit } = useForm<LoginForm>();
  const [, dispatch] = useRedux();
  const [onLogin, loginStatus, loginData, message] = useGet<
    User,
    { username: string; pass: string }
  >(authUrls.login);

  async function onValid(data: LoginForm) {
    await onLogin({ username: data.username, pass: data.password });
  }

  function showErrorMessage(message: string) {
    Swal.fire({
      title: message,
      icon: "error",
      confirmButtonText: "Tamam",
      confirmButtonColor: "#4220AA",
    });
  }

  useEffect(() => {
    if (loginStatus === Status.success) {
      setItem(loginData!.accessToken!, loginData!);
      dispatch(setUser(loginData!, loginData!.accessToken!));
    } else if (loginStatus === Status.error) {
      showErrorMessage(message || "");
    }
  }, [loginStatus]);
  return (
    <div className="header-fixed header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed aside-minimize-hoverable page-loading">
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-3 login-signin-on d-flex flex-row-fluid"
          id="kt_login"
        >
          <div
            className="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"
            style={{
              backgroundImage: `url(${BackgroundImage})`,
              minHeight: "100vh",
            }}
          >
            <div className="login-form text-center text-white p-7 position-relative overflow-hidden">
              <div className="d-flex flex-center mb-15 bg-light p-3 py-5 rounded shadow">
                <a href="#">
                  <img src={"https://skyapi.tesisyonetim.pro/Images/Company/sky.png"} className="max-h-100px" alt="" />
                </a>
              </div>
              <div className="login-signin">
                <div className="mb-20">
                  <h3>SKY | SABİT KIYMET YÖNETİMİ</h3>
                  <p className="opacity-60 font-weight-bold">
                    PORTAL GİRİŞİ
                  </p>
                </div>
                <form
                  className="form"
                  id="kt_login_signin_form"
                  onSubmit={handleSubmit(onValid)}
                >
                  <div className="form-group">
                    <input
                      className="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8 mb-5"
                      type="text"
                      placeholder="Kullanıcı Adı"
                      name="username"
                      autoComplete="off"
                      ref={register({ required: true })}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8 mb-5"
                      type="password"
                      placeholder="Parola"
                      name="password"
                      ref={register({ required: true })}
                    />
                  </div>
                  <div className="form-group d-flex flex-wrap justify-content-between align-items-center px-8">
                    <div className="checkbox-inline">
                     
                    </div>
                  </div>
                  <div className="form-group text-center mt-10">
                    <button
                      id="kt_login_signin_submit"
                      className="btn btn-pill btn-outline-white font-weight-bold opacity-90 px-15 py-3"
                    >
                      Giriş Yap{" "}
                      {loginStatus === Status.pending && <Spinner size="sm" />}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
