import { Inventory } from "models";

export const apiUrls = {
  mismatch: "/Inventory/GetMisMatchedInventoryList",
  match: "/Inventory/GetMatchedInventoryList",
  all: "/Inventory/GetAllInventoryList",
};

export interface Params {
  projectId: number | string;
}

export type Res = Inventory[];
