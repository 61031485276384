import SkyInput from "components/Forms/SkyInput";
import SelectBox from "components/SelectBox";
import { watch } from "fs";
import { Status, useGet, useRedux } from "hooks";
import React, { useEffect } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

type Props = {
  control: any;
  setValue: any;
  defaults: any;
  watch: any;
};

const TechInfos = (props: Props) => {
  const [
    {
      auth: { user },
    },
  ] = useRedux();
  const [getMainGroupFetch, statMainGroup, dataMainGroup] = useGet<any[], any>(
    "/Group/GetMainGroupsByProjectId?projectId=" + user!.project!.id
  );
  const [getGroupFetch, statGroup, dataGroup] = useGet<any[], any>("/Group/GetGroupListByGroupMainID");
  const [getBrandFetch, statBrand, dataBrand] = useGet<any[], any>("/Group/GetBrandListByGroupID");
  const [getModelFetch, statModel, dataModel] = useGet<any[], any>("/Group/GetModelListByBrandID");

  function getMainGroup() {
    getMainGroupFetch();
  }

  function getGroup(mainGroupId: number) {
    getGroupFetch({ groupMainID: mainGroupId });
  }

  function getBrand(groupId: number) {
    getBrandFetch({ groupId });
  }

  function getModel(brandId: number) {
    getModelFetch({ brandId });
  }

  useEffect(() => {
    Promise.all([
      getMainGroup(),
      getGroup(props.defaults.mainGroupId),
      props.defaults.groupId ? getBrand(props.defaults.groupId) : undefined,
      props.defaults.brandId ? getModel(props.defaults.brandId) : undefined,
    ]);
  }, []);

  return (
    <Card>
      <CardBody>
        <h2 className="h4 mb-3">Teknik Bilgiler</h2>
        <Row>
          <Col xs={3}>
            <SelectBox
              label="Ana Grup"
              name="mainGroupId"
              isLoading={statMainGroup !== Status.success}
              control={props.control}
              options={dataMainGroup?.map((item: any) => ({ value: item.id, label: item.name })) ?? []}
              onChange={(mainGroupId) => {
                props.setValue("groupId", null);
                props.setValue("modelId", null);
                props.setValue("brandId", null);
                if (mainGroupId) {
                  getGroup(parseInt(mainGroupId));
                }
              }}
              addurl="/Group/AddGroupMain"
              addParams={{}}
              getList={() => getGroup(parseInt(props.watch("mainGroupId")))}
            />
          </Col>
          <Col xs={3}>
            <SelectBox
              label="Grup"
              name="groupId"
              isLoading={statGroup !== Status.success}
              control={props.control}
              options={dataGroup?.map((item: any) => ({ value: item.id, label: item.name })) ?? []}
              onChange={(groupId) => {
                props.setValue("brandId", null);
                if (groupId) {
                  getBrand(parseInt(groupId));
                }
              }}
              addurl="/Group/AddGroup"
              addParams={{
                groupMainID: parseInt(props.watch("mainGroupId")),
              }}
              getList={() => getGroup(parseInt(props.watch("mainGroupId")))}
            />
          </Col>
          <Col xs={3}>
            <SelectBox
              label="Marka"
              name="brandId"
              isLoading={statBrand !== Status.success}
              control={props.control}
              options={dataBrand?.map((item: any) => ({ value: item.id, label: item.name })) ?? []}
              onChange={(brandId) => {
                props.setValue("modelId", null);
                if (brandId) {
                  getModel(parseInt(brandId));
                }
              }}
              addurl="/Group/AddBrand"
              addParams={{
                grupID: parseInt(props.watch("groupId")),
              }}
              getList={() => getBrand(parseInt(props.watch("groupId")))}
            />
          </Col>
          <Col xs={3}>
            <SelectBox
              label="Model"
              name="modelId"
              isLoading={statModel !== Status.success}
              control={props.control}
              options={dataModel?.map((item: any) => ({ value: item.id, label: item.name })) ?? []}
              addurl="/Group/AddModel"
              addParams={{
                brandId: parseInt(props.watch("brandId")),
                grupId: parseInt(props.watch("groupId")),
              }}
              getList={() => getModel(parseInt(props.watch("brandId")))}
            />
          </Col>
          <Col xs={3} className="mt-3">
            <SkyInput name="text1" control={props.control} label="Tip" />
          </Col>
          <Col xs={3} className="mt-3">
            <SkyInput name="serialNumber" control={props.control} label="Seri No" />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default TechInfos;
