import React, { useState, useMemo, ReactNode, ReactNodeArray } from "react";
import {
  Content,
  Footer,
  Header,
  Sidebar,
  Stat,
  SubHeader,
  Wrapper,
  Page,
} from "./components";

export type ChildrenProps = { children: ReactNode | ReactNodeArray };


const Layout = ({children}: ChildrenProps) => {
  const [sidebarStat, setSidebarStat] = useState<Stat>("open");

  function getSidebarClassName(): string {
    switch (sidebarStat) {
      case "open":
        return "";
      case "close":
        return "aside-minimize";
      case "hover":
        return "aside-minimize-hover";
      default:
        return "";
    }
  }

  const sidebarClassName = useMemo(() => getSidebarClassName(), [sidebarStat]);
  return (
    <div
      className={`header-fixed header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed aside-minimize-hoverable ${sidebarClassName}`}
    >
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          <Sidebar
            stat={sidebarStat}
            onHover={() => {
              if (sidebarStat === "close") {
                setSidebarStat("hover");
              }
            }}
            onHoverOut={() => {
              if (sidebarStat === "hover") {
                setSidebarStat("close");
              }
            }}
            onClickToggle={() => {
              setSidebarStat(sidebarStat === "open" ? "close" : "open");
            }}
          />
          <Header />
          <Wrapper>
            <Content>
              {children}
            </Content>
            <Footer />
          </Wrapper>
        </div>
      </div>
    </div>
  );
};

export default Layout;
