import React from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { User } from "models";
import { useForm } from "react-hook-form";

interface Props {
  user: any;
  onValid: any;
  defaultValue?: any
}

const CampusForm = ({ user, onValid, defaultValue }: Props) => {
  const { register, handleSubmit, reset } = useForm();

  return (
    <Form onSubmit={handleSubmit(onValid)}>
      <FormGroup>
        <Label>Proje</Label>
        <Input type="select" disabled>
          <option selected disabled>
            {user?.project?.name}
          </option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>Kampüs Adı</Label>
        <Input type="text" name="name" innerRef={register({ required: true })} defaultValue={defaultValue?.name} />
      </FormGroup>
      <FormGroup>
        <Label>Adres</Label>
        <Input type="textarea" name="location" innerRef={register({ required: true })} defaultValue={defaultValue?.location}/>
      </FormGroup>
      <FormGroup>
        <Button color="primary">Kaydet</Button>
      </FormGroup>
    </Form>
  );
};

export default CampusForm;
