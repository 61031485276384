export const reportUrls = {
  listByBarcode: "/Inventory/GetInventoryByBarcode",
  listBySerial: "/Inventory/GetInventoryBySerialNumber",
  listByInvoice: "/Inventory/GetInventoryByInvoiceNo",
  listByInventory: "/Inventory/GetInventoryByInventoryNo",
};

interface SearchParams {
  projectId: number;
}

export interface BarcodeParameters extends SearchParams {
  barcode: string;
}

export interface SerialParameters extends SearchParams {
  serialNumber: string;
}
export interface InvoiceNoParameters extends SearchParams {
  invoiceNo: string;
}
export interface InventoryParameters extends SearchParams {
  inventoryNo: string;
}