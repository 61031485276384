import React, { useLayoutEffect } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { useForm } from "react-hook-form";
import { Status, useGet } from "hooks";
import { floorUrls } from "services/location";

interface Props {
  campusId: any;
  floorId: any;
  buildId: any;
  onValid: any;
  defaultValue?: any;
  projectID: any;
}

const RoomForm = ({ campusId, onValid, defaultValue, projectID, floorId, buildId }: Props) => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [floorGet, floorStat, floorList] = useGet<any[], { buildId: number | string }>(floorUrls.floorList);

  async function beforeRenderAsync() {
    await floorGet({ buildId: buildId });
    setValue("floorId", floorId);
  }

  useLayoutEffect(() => {
    beforeRenderAsync();
  }, []);

  return (
    <Form onSubmit={handleSubmit(onValid)}>
      <FormGroup>
        <Label>Kat</Label>
        <Input type="select" name="floorId" innerRef={register({ required: true })}>
          {floorStat === Status.success &&
            !!floorList &&
            floorList.map((floor, index) => (
              <option key={index} value={`${floor.id}`}>
                {floor.name}
              </option>
            ))}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>Oda Adı</Label>
        <Input type="text" name="name" innerRef={register({ required: true })} defaultValue={defaultValue?.name} />
      </FormGroup>
      <FormGroup>
        <Button color="primary">Kaydet</Button>
      </FormGroup>
    </Form>
  );
};

export default RoomForm;
