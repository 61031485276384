import React, { ReactElement, useEffect, useLayoutEffect, useState } from "react";
import { Page, SubHeader } from "layout/components";
import { useForm } from "react-hook-form";
import { useRedux } from "hooks";

import { Card, CardBody, Form, Label, Input, Button } from "reactstrap";
import Loading from "../components/Loading";
import ODataTable from "../../../components/ReactTable/ODataTable";

import { httpOData } from "helper/http";
import { ResponseOData } from "models/InvertoryOData";

interface Props {}

enum MatchReports {
  all = "1",
  match = "2",
  inmatch = "3",
}

type FilterProperty = {
  id: string;
  value: string;
};

interface ReportForm {
  matchReport: MatchReports;
}

var queryFilter = "isActive eq true";

function MatchInventoryPage({}: Props): ReactElement {
  const { register, handleSubmit, setValue, watch } = useForm<ReportForm>();
  const [tableData, setTableData] = useState<any>();
  const [tableExcelData, setTableExcelData] = useState<any>();
  const [filterState, setFilterState] = useState<any>(null);

  const [tableShowCount, setTableShowCount] = useState(10);
  const [skipCount, setSkipCount] = useState(0);
  const [countState, setCountState] = useState<any>();

  const [activeReport, setActiveReport] = useState<MatchReports>(MatchReports.all);
  const [
    {
      auth: { user },
    },
  ] = useRedux();

  async function getAllListInventory(filter: string, top: number, skip: number) {
    setTableData(null);
    const params: Record<string, any> = {
      projectID: user?.project?.id,
      top: top,
      skip: skip,
      count: "true",
    };

    if (filter.length > 0) {
      params.filter = filter;
    }
    const response = await httpOData().get("/odata/InventoryOdata", {
      params: params,
    });
    const countResponse = (response.data as unknown as ResponseOData)["@odata.count"];
    setCountState(countResponse);
    setTableData(response.data.value);
  }

  async function getAllExcelListInventory(filter: string) {
    setTableExcelData(null);
    const params: Record<string, any> = {
      projectID: user?.project?.id,
    };

    if (filter.length > 0) {
      params.filter = filter;
    }
    const response = await httpOData().get("/odata/InventoryOdata", {
      params: params,
    });
    setTableExcelData(response.data.value);
  }

  function onValid({ matchReport }: ReportForm) {
    setActiveReport(matchReport);
    switch (matchReport) {
      case MatchReports.all:
        queryFilter = "";
        break;
      case MatchReports.inmatch:
        queryFilter = queryFilter + ` and inventoryNo eq null`;
        break;
      case MatchReports.match:
        queryFilter = queryFilter + ` and inventoryNo ne null`;
        break;
    }
    getAllListInventory(queryFilter, tableShowCount, skipCount);
    getAllExcelListInventory(queryFilter);
  }

  useLayoutEffect(() => {
    setValue("matchReport", MatchReports.all);
    getAllListInventory(queryFilter, tableShowCount, skipCount);
    getAllExcelListInventory(queryFilter);
  }, []);

  useEffect(() => {
    if (!!filterState) {
      for (const key in filterState) {
        if (Object.prototype.hasOwnProperty.call(filterState, key)) {
          const property = filterState[key as keyof typeof filterState] as FilterProperty;
          const label = property?.value;
          if (key == "startDate" || key == "endDate") {
            key == "startDate"
              ? (queryFilter = queryFilter + ` and createDate gt ${label}`)
              : (queryFilter = queryFilter + ` and createDate lt ${label}`);
          } else {
            queryFilter = queryFilter + ` and ${key} eq ${property.id}`;
          }
        }
      }
      getAllListInventory(queryFilter, tableShowCount, skipCount);
      getAllExcelListInventory(queryFilter);
    }
  }, [filterState]);

  return (
    <Page>
      <SubHeader title="Eşleşme Raporu" />
      <Card>
        <CardBody>
          <h3>Tüm Kayıtlar</h3>
          <Form onSubmit={handleSubmit(onValid)}>
            <div className="d-flex align-items-center mt-5">
              <Radio margin={5} title="Tüm Kayıtlar" selected innerRef={register()} value={MatchReports.all} />
              <Radio title="Eşleşen Kayıtlar" innerRef={register()} value={MatchReports.match} />
              <Radio title="Eşleşmeyen Kayıtlar" innerRef={register()} value={MatchReports.inmatch} />
              <Button color="primary ml-10">Getir</Button>
            </div>
          </Form>
        </CardBody>
      </Card>

      {false && (
        <Card className="mt-3">
          <CardBody>
            <Loading long />
          </CardBody>
        </Card>
      )}

      {!!tableData && (
        <Card className="mt-3">
          <CardBody>
            <ODataTable
              onFilterState={(filter) => {
                setFilterState(filter);
              }}
              onSearchBarcodeState={(barcode) => {
                queryFilter = `isActive eq true and contains(Barcode, \'${barcode}\')`;
                setSkipCount(0)
                getAllListInventory(queryFilter, tableShowCount, 0);
              }}
              resetFilterButtonVisible={!!filterState}
              data={tableData}
              pageSize={tableShowCount}
              excelData={tableExcelData}
              bottomTableElement={
                <>
                  <div>
                    <span style={{ whiteSpace: "nowrap" }} className="d-flex align-items-center gap-1">
                      Satır Göster:
                      <Input
                        className="w-60px"
                        type="select"
                        value={tableShowCount}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          setTableShowCount(value);
                          getAllListInventory(queryFilter, value, skipCount);
                          getAllExcelListInventory(queryFilter);
                        }}
                      >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            {pageSize}
                          </option>
                        ))}
                      </Input>
                    </span>
                  </div>

                  <span className="flex items-center gap-1">
                    <div>
                      <strong>{countState} veri bulundu</strong>
                    </div>
                  </span>

                  <div className="d-flex align-items-center gap-2">
                    <div className="mr-2">
                      {skipCount + 1} / {Math.ceil(countState / tableShowCount)}
                    </div>
                    <Button
                      className="bg-transparent border-none"
                      onClick={() => {
                        setSkipCount(0);
                        getAllListInventory(queryFilter, tableShowCount, 0);
                        getAllExcelListInventory(queryFilter);
                      }}
                      disabled={skipCount === 0}
                    >
                      <i className="flaticon2-fast-back p-0" />
                    </Button>
                    <Button
                      className="bg-transparent"
                      onClick={() => {
                        setSkipCount(skipCount - 1);
                        getAllListInventory(queryFilter, tableShowCount, (skipCount - 1) * tableShowCount);
                        getAllExcelListInventory(queryFilter);
                      }}
                      disabled={skipCount + 1 === 1}
                    >
                      <i className="flaticon2-left-arrow p-0" />
                    </Button>
                    <Button
                      className="bg-transparent"
                      onClick={() => {
                        setSkipCount(skipCount + 1);
                        getAllListInventory(queryFilter, tableShowCount, (skipCount + 1) * tableShowCount);
                        getAllExcelListInventory(queryFilter);
                      }}
                      disabled={skipCount + 1 === Math.ceil(countState / tableShowCount)}
                    >
                      <i className="flaticon2-right-arrow p-0" />
                    </Button>
                    <Button
                      className="bg-transparent border-none"
                      onClick={() => {
                        setSkipCount(Math.ceil(countState / tableShowCount) - 1);
                        getAllListInventory(
                          queryFilter,
                          tableShowCount,
                          (Math.ceil(countState / tableShowCount) - 1) * tableShowCount
                        );
                        getAllExcelListInventory(queryFilter);
                      }}
                      disabled={skipCount === Math.ceil(countState / tableShowCount) - 1}
                    >
                      <i className="flaticon2-fast-next p-0" />
                    </Button>
                  </div>
                </>
              }
            />
          </CardBody>
        </Card>
      )}
    </Page>
  );
}

export default MatchInventoryPage;

interface RadioProps {
  selected?: boolean;
  title?: string;
  margin?: string | number;
  innerRef: any;
  value: any;
}
export function Radio({ margin, selected, title, innerRef, value }: RadioProps) {
  return (
    <div className={`ml-${margin ?? 10}`}>
      <Label check>
        <Input type="radio" name="matchReport" value={value} innerRef={innerRef} /> {title}
      </Label>
    </div>
  );
}
