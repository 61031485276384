import SkyInput from "components/Forms/SkyInput";
import { Status, useGet, usePost, useRedux } from "hooks";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from "reactstrap";
import Cogo from "cogo-toast";
import EmployeeInfo from "./EmployeeInfo";
import EnvanterInfo from "./EnvanterInfo";
import EnvanterSelectInfos from "./EnvanterSelectInfos";
import LocationInfos from "./LocationInfos";
import TechInfos from "./TechInfos";
import { register } from "numeral";
import SelectBox from "components/SelectBox";
import ImageUploading from "react-images-uploading";

type EditFormProps = {
  defaultValues: any;
  oldData: any;
  onEdit: VoidFunction;
};

interface ImageItem {
  data_url: string | ArrayBuffer | null;
  file: any;
}

const EditForm: FC<EditFormProps> = (props) => {
  const { defaultValues } = props;
  const [
    {
      auth: { user },
    },
  ] = useRedux();
  const [postUpdate, statUpdate, dataUpdate, messageUpdate, resetUpdate] = usePost("/InventoryAdd/UpdateInventory");
  const [getColorsFetch, statColors, dataColors] = useGet<any[], any>("/Color/GetColorListByProjectID");
  const [image, setImage] = useState<ImageItem[]>([]);
  const { control, setValue, watch, handleSubmit, register } = useForm({
    defaultValues: defaultValues,
  });

  async function onValid(fields: any) {
    const body: any = {
      ...props.oldData,
      isActive: fields.isActive,
      campusID: parseInt(fields.campusId),
      buildID: parseInt(fields.buildId),
      floorID: parseInt(fields.floorId),
      roomID: parseInt(fields.roomId),
      grupId: parseInt(fields.groupId),
      brandID: parseInt(fields.brandId),
      modelID: fields.modelId ?? null,
      colorID: fields.colorId ? parseInt(fields.colorId) : 0,
      inventoryStatusID: fields.inventoryStatId ? parseInt(fields.inventoryStatId) : 0,
      inventoryNo: fields.inventoryNo,
      serialNumber: fields.serialNumber,
      invoiceNo: fields.invoiceNo,
      purchaseData: fields.purchaseData,
      notes: fields.notes,
      departmentID: parseInt(fields.departmentId),
      costID: fields.costId ?? null,
      price: fields.price ? fields.price.split(",").join(".") : "",
      text1: fields.text1,
      text2: fields.text2,
      text3: fields.text3,
      text4: fields.text4,
      empleyooID: parseInt(fields.employeeId),
      photoPath: !!fields.photoPath
        ? fields.photoPath.includes("https")
          ? fields.photoPath.split("https://skyapi.tesisyonetim.pro")[1]
          : fields.photoPath
        : undefined,
    };
    try {
      await postUpdate(body);
      window.location.href="/report-match"
    } catch (error) {}
  }

  async function fetchImage() {
    try {
      const response = await fetch(defaultValues.photoPath);
      const blob = await response.blob();

      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result;
        const newData: ImageItem[] = [
          {
            data_url: base64Data,
            file: {},
          },
        ];
        setImage(newData);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.log("Error fetching image:", error);
    }
  }

  useEffect(() => {
    getColorsFetch({ projectId: user?.project?.id });
  }, []);

  useEffect(() => {
    if (statUpdate === Status.success) {
      Cogo.success("Başarıyla güncellendi!", { position: "bottom-right" });
      resetUpdate();
      props.onEdit();
    } else if (statUpdate === Status.error) {
      Cogo.error(messageUpdate ?? "Güncellerken hata oluştu! Lütfen zorunlu alanları kontrol ediniz!", {
        position: "bottom-right",
      });
    }
  }, [statUpdate]);

  useEffect(() => {
    register("photoPath");
  });

  useEffect(() => {
    fetchImage();
  }, []);

  return (
    <form onSubmit={handleSubmit(onValid)}>
      <Card className="mb-3">
        <CardBody>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              Barkod: <h3 className="h3">{defaultValues.barcode}</h3>
            </div>
            <Button color="success" type="submit">
              Kaydet
            </Button>
          </div>
        </CardBody>
      </Card>

      <div className="d-flex flex-column gap-4">
        <LocationInfos
          control={control}
          setValue={setValue}
          defaults={{
            campusId: defaultValues.campusId,
            buildId: defaultValues.buildId,
            floorId: defaultValues.floorId,
            roomId: defaultValues.roomId,
          }}
        />

        <Card>
          <CardBody>
            <h2 className="h4 mb-3">Envanter Bilgisi</h2>
            <EnvanterInfo control={control} watch={watch} setValue={setValue} />
            <EnvanterSelectInfos
              watch={watch}
              control={control}
              setValue={setValue}
              defaults={{
                departmentId: defaultValues.departmentId,
                costId: defaultValues.costId,
                inventoryStatId: defaultValues.inventoryStatId,
              }}
            />
          </CardBody>
        </Card>

        <TechInfos
          watch={watch}
          control={control}
          setValue={setValue}
          defaults={{
            mainGroupId: defaultValues.mainGroupId,
            groupId: defaultValues.groupId,
            brandId: defaultValues.brandId,
            modelId: defaultValues.modelId,
            colorId: defaultValues.colorId,
            serialNumber: defaultValues.serialNumber,
          }}
        />

        <EmployeeInfo
          control={control}
          setValue={setValue}
          defaults={{
            mainGroupId: defaultValues.mainGroupId,
            groupId: defaultValues.groupId,
            brandId: defaultValues.brandId,
            modelId: defaultValues.modelId,
            colorId: defaultValues.colorId,
            serialNumber: defaultValues.serialNumber,
          }}
        />

        <Card>
          <CardBody>
            <h2 className="h4 mb-3">Boyut</h2>
            <Row>
              <Col xs={4}>
                <SkyInput name="e" control={control} label="En" />
              </Col>
              <Col xs={4}>
                <SkyInput name="b" control={control} label="Boy" />
              </Col>
              <Col xs={4}>
                <SkyInput name="y" control={control} label="Yükseklik" />
              </Col>
              <Col xs={3}>
                <SelectBox
                  label="Renk"
                  name="colorId"
                  isLoading={statColors !== Status.success}
                  control={control}
                  options={dataColors?.map((item: any) => ({ value: item.id, label: item.colorName })) ?? []}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h2 className="h4 mb-3">Diğer</h2>
            <Row>
              <Col xs={3}>
                <SkyInput name="text2" control={control} label="Diğer 2" />
              </Col>
              <Col xs={3}>
                <SkyInput name="text3" control={control} label="Diğer 3" />
              </Col>
              <Col xs={3}>
                <SkyInput name="text4" control={control} label="Diğer 4" />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" name="isActive" innerRef={register()} />{" "}
                {watch("isActive") ? (
                  <span>
                    <span className="text-primary">Aktif</span> / Pasif
                  </span>
                ) : (
                  <span>
                    Aktif / <span className="text-primary">Pasif</span>
                  </span>
                )}
              </Label>
            </FormGroup>
          </CardBody>
        </Card>
        <Card className="mt-5">
          <CardBody className="p-4">
            <h5 className="mt-1">Fotograf</h5>
            <hr />
            <Row>
              <Col md={3}>
                <ImageUploading
                  multiple
                  value={image}
                  onChange={(imageList) => {
                    setImage(imageList as never[]);
                    setValue("photoPath", imageList[0].data_url?.split(",")[1]);
                  }}
                  dataURLKey="data_url"
                >
                  {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
                    <div className="upload__image-wrapper">
                      <div
                        style={{ border: "1px dashed" }}
                        className="d-flex justify-content-center p-5 align-items-center"
                      >
                        {imageList.length > 0 ? (
                          imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image["data_url"]} alt="" width="100%" />
                              <div className="d-flex justify-content-between mt-10 image-item__btn-wrapper">
                                <Button color="warning" onClick={() => onImageUpdate(index)}>
                                  Resim Değiştir
                                </Button>
                                <Button color="danger" onClick={() => onImageRemove(index)}>
                                  Resim Kaldır
                                </Button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <Button color="success" onClick={onImageUpload} {...dragProps}>
                            Fotoğraf Ekle
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </ImageUploading>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>

      <div className="d-flex justify-content-end mt-3">
        <Button color="success" type="submit">
          Kaydet
        </Button>
      </div>
    </form>
  );
};

export default EditForm;
