import React, { ReactElement, useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import { RouteComponentProps, Link, useHistory } from "react-router-dom";
import { Badge, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Page, SubHeader } from "layout/components";
import DataTable from "components/Table";
import JqueryTable from "components/DataTable";
import { Status, useGet, usePost, useRedux, useToggle } from "hooks";
import { buildUrls, Build } from "services/location";
import Moment from "moment";
import BuildForm from "./BuildForm";
import LocationBreadcrumb from "components/LocationBreadcrumb";

interface Props {}

function BuildList({
  match: {
    params: { campusID },
  },
}: Props & RouteComponentProps<{ campusID: string }>): ReactElement {
  const history = useHistory();
  const [isOpenNew, openNewToggle] = useToggle();
  const [getBuilds, buildsStat, builds] = useGet<Build[], { campusId: number | string }>(buildUrls.buildList);
  const [add, addStat] = usePost<{ projectID: number; campusID: number; name: string }, {}, {}>("/Build/AddBuild");
  const [updateBuild] = usePost("/Build/UpdateBuild");
  const [
    {
      auth: { user },
    },
  ] = useRedux();

  const [getCampus, campusStatus, campus] = useGet<any, { campusId: number | string }>("/Campus/GetCampusById");

  async function getLocations() {
    if (!!campusID) {
      getCampus({ campusId: campusID });
    }
  }

  useLayoutEffect(() => {
    getBuilds({ campusId: campusID });
    getLocations();
  }, []);

  async function onValid(fields: any) {
    await add({ name: fields.name, campusID: parseInt(fields.campusID), projectID: user!.project!.id! });
    openNewToggle();
    getBuilds({ campusId: campusID });
  }

  async function handleClickToggle(row: any) {
    await updateBuild({
      ...row,
      isActive: !row.isActive,
    });
    getBuilds({ campusId: campusID });
  }

  return (
    <Page loading={buildsStat === Status.pending}>
      <SubHeader title="Binalar" breadcrumbs={[{ title: "Kampüsler", path: "/campus-list" }]}>
        <Button size="sm" color="primary" onClick={openNewToggle}>
          Yeni Bina Ekle
        </Button>
      </SubHeader>
      <LocationBreadcrumb campusId={parseInt(campusID)} />

      {builds && (
        <JqueryTable
          columns={[
            {
              name: "Kampüs İsmi",
              selector: "campusName",
            },
            {
              name: "Bina İsmi",
              selector: "name",
            },

            {
              name: "Eklenme Tarihi",
              selector: "createDate",
              cell: function (row: any) {
                return Moment(row.createDate).format("DD/MM/YYYY HH:mm");
              },
            },
            {
              name: "Durum",
              selector: "isActive",
              cell: function (cell: any) {
                return (
                  <Badge color={cell.isActive ? "success" : "secondary"}>{cell.isActive ? "Aktif" : "Pasif"}</Badge>
                );
              },
            },
            {
              name: "Button",
              selector: "id",
              cell: function (row: any) {
                return (
                  <div>
                    <Button
                      color="info"
                      size="sm"
                      className="ml-1"
                      onClick={() => {
                        handleClickToggle(row);
                      }}
                    >
                      <i className="flaticon-interface-7 p-0"></i>
                    </Button>
                    <Button
                      className="btn btn-primary btn-sm ml-1"
                      color="primary"
                      onClick={() => {
                        history.push("/campus/" + campusID + "/builds/" + row.id + "/floors");
                      }}
                    >
                      Katlar
                    </Button>
                  </div>
                );
              },
            },
          ]}
          data={
            builds && campusStatus === "fulfilled"
              ? builds.map((build) => ({
                  ...build,
                  campusName: campus.name,
                }))
              : []
          }
        />
      )}

      <Modal isOpen={isOpenNew} toggle={openNewToggle}>
        <ModalHeader toggle={openNewToggle}>Kampüs Ekle</ModalHeader>
        <ModalBody>
          <BuildForm {...{ campusId: campusID, onValid: onValid, projectID: user!.project!.id! }} />
        </ModalBody>
      </Modal>
    </Page>
  );
}

export default BuildList;
