import React, { ReactElement } from "react";
import { BootstrapColor } from "models";

interface Props {
  title: string;
  value: number;
  color: BootstrapColor;
}

function ProgressStat({ color, title, value }: Props): ReactElement {
  return (
    <div className={`card card-custom bg-${color} card-stretch gutter-b`}>
      <div className="card-body">
        <span className="card-title font-weight-bolder text-white font-size-h6 mb-4 text-hover-state-dark d-block">
          {title}
        </span>
        <div className="font-weight-bold text-white font-size-sm">
          <span className="font-size-h2 mr-2">{value}%</span>
        </div>
        <div className="progress progress-xs mt-7 bg-white-o-90">
          <div className="progress-bar bg-white" role="progressbar" style={{ width: `${value}%` }}></div>
        </div>
      </div>
    </div>
  );
}

ProgressStat.defaultProps = {
  color: "primary",
  icon: "statistic",
};

export default ProgressStat;
