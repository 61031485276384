import React, { ReactElement, useLayoutEffect, useState } from "react";
import { Row, Col, Form, FormGroup, Input, Button, FormText, CardBody, CardHeader, Card, Label } from "reactstrap";
import { useForm } from "react-hook-form";
import CaretRight from "icons/CaretRight";
import { useGet, Status, useRedux } from "hooks";
import { setProject } from "store/actions/user-actions";
import { authUrls, Project } from "services/auth";

function ProjectSelectPage(): ReactElement {
  const [
    {
      auth: { user },
    },
    dispatch,
  ] = useRedux();
  const [getProjects, status, projects, message] = useGet<Project[], { userId: number }>(authUrls.projectList);
  const [_isMounted, setMounted] = useState<boolean>(false);
  const { register, handleSubmit, errors } = useForm<{ project: string }>();

  useLayoutEffect(() => {
    if (_isMounted) {
    } else {
      getProjects({ userId: user!.id });
      setMounted(true);
    }
  }, [status]);

  function onValid(data: { project: string }) {
    const project = projects?.find((project) => project.id === parseInt(data.project));
    dispatch(setProject(project));
  }

  return (
    <div className=" d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
      <Row className="w-100 justify-content-center">
        <Col xs={12} sm={10} md={6}>
          <Card>
            <CardHeader>Proje Seçiniz</CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(onValid)}>
                <FormGroup>
                  <Label>Proje</Label>
                  <Input
                    type="select"
                    placeholder={status === Status.pending ? "Yükleniyor" : ""}
                    disabled={status !== Status.success}
                    innerRef={register({ required: true })}
                    name="project"
                  >
                    {status === Status.success &&
                      projects?.map((project, key) => (
                        <option key={key} value={project.id}>
                          {project.name}
                        </option>
                      ))}
                  </Input>
                  <FormText>Paneline girmek istediğiniz projeyi seçiniz</FormText>
                </FormGroup>
                <FormGroup>
                  <div className="d-flex justify-content-end">
                    <Button color="primary">
                      <span>Seç</span>
                      <CaretRight />
                    </Button>
                  </div>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ProjectSelectPage;
