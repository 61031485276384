export const authUrls = {
  login: "User/login",
  projectList: "/User/getUserProjectByUserID",
};

export interface Project {
  id?: number;
  companyID?: number;
  name?: string;
  isActive?: boolean;
  createDate?: string;
}
