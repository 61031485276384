import React, { ReactElement, useState } from "react";
import { Card, CardBody, Form, FormGroup, Input, Button, Row, Col, Label, FormText, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { Page, SubHeader } from "layout/components";
import { useGet, useRedux, Status } from "hooks";
import { BarcodeParameters, reportUrls, InventoryParameters, InvoiceNoParameters, SerialParameters } from "./service";
import { Inventory } from "models";

import InventoryTable from "../components/InventoryTable";
import Loading from "../components/Loading";
import DataTable from "components/ReactTable/DataTable";

interface Props {}

enum SearchType {
  envanter = "1",
  seri = "2",
  fatura = "3",
  barkod = "4",
}

interface SearchForm {
  searchType: SearchType;
  value: string;
}

function ReportSearchPage({}: Props): ReactElement {
  const { register, handleSubmit, errors, reset } = useForm<SearchForm>();
  const [activeInventory, setActiveInventory] = useState<SearchType | null>(null);
  const [barcodeGet, barcodeStatus, barcodeData] = useGet<Inventory, BarcodeParameters>(reportUrls.listByBarcode);
  const [inventoryGet, inventoryStatus, inventoryData] = useGet<Inventory, InventoryParameters>(
    reportUrls.listByInventory
  );
  const [invoiceGet, invoiceStatus, invoiceData] = useGet<Inventory, InvoiceNoParameters>(reportUrls.listByInvoice);
  const [serialGet, serialStatus, serialData] = useGet<Inventory, SerialParameters>(reportUrls.listBySerial);

  const [
    {
      auth: { user },
    },
  ] = useRedux();

  function getInventory(searchType: SearchType, value: string) {
    switch (searchType) {
      case SearchType.barkod:
        barcodeGet({ projectId: user!.project!.id!, barcode: value });
        break;
      case SearchType.envanter:
        inventoryGet({ projectId: user!.project!.id!, inventoryNo: value });
        break;
      case SearchType.seri:
        serialGet({ projectId: user!.project!.id!, serialNumber: value });
        break;
      case SearchType.fatura:
        invoiceGet({ projectId: user!.project!.id!, invoiceNo: value });
        break;
      default:
        break;
    }
  }

  function onValid(fields: SearchForm) {
    setActiveInventory(fields.searchType);
    getInventory(fields.searchType, fields.value);
  }

  function getData() {
    switch (activeInventory) {
      case SearchType.barkod:
        return barcodeData;
      case SearchType.envanter:
        return inventoryData;
      case SearchType.fatura:
        return invoiceData;
      case SearchType.seri:
        return serialData;
      default:
        return null;
    }
  }

  function getLoading() {
    switch (activeInventory) {
      case SearchType.barkod:
        return barcodeStatus === Status.pending;
      case SearchType.envanter:
        return inventoryStatus === Status.pending;
      case SearchType.fatura:
        return invoiceStatus === Status.pending;
      case SearchType.seri:
        return serialStatus === Status.pending;
      default:
        return null;
    }
  }

  function getSuccess() {
    switch (activeInventory) {
      case SearchType.barkod:
        return barcodeStatus === Status.success && !!barcodeData;
      case SearchType.envanter:
        return inventoryStatus === Status.success && !!inventoryData;
      case SearchType.fatura:
        return invoiceStatus === Status.success && !!invoiceData;
      case SearchType.seri:
        return serialStatus === Status.success && !!serialData;
      default:
        return null;
    }
  }

  const isLoading = getLoading();
  const isSuccess = getSuccess();
  const data = getData();

  return (
    <Page>
      <SubHeader title="Kayıt Arama" />
      <Card>
        <CardBody>
          <Form onSubmit={handleSubmit(onValid)}>
            <Row>
              <Col xs={12} md={6}>
                <Label>Arama Tipi</Label>
                <Input
                  type="select"
                  defaultValue={SearchType.envanter}
                  name="searchType"
                  innerRef={register({ required: true })}
                >
                  <option value={SearchType.envanter}>Envanter No</option>
                  <option value={SearchType.seri}>Seri No</option>
                  <option value={SearchType.fatura}>Fatura No</option>
                  <option value={SearchType.barkod}>Barkod No</option>
                </Input>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label>Aranacak No</Label>
                  <Input type="text" name="value" innerRef={register({ required: true })} invalid={!!errors["value"]} />
                  <FormText>Seçtiğiniz tipe göre arama yapmak istediğiniz no giriniz!</FormText>
                </FormGroup>
              </Col>
            </Row>
            <div className="d-flex justify-content-end">
              <Button color="primary">Getir</Button>
            </div>
          </Form>
        </CardBody>
      </Card>
      {!!activeInventory && (
        <Card className="mt-3">
          <CardBody>
            {isLoading && <Loading />}
            {isSuccess && !!data && <DataTable data={[data]}  />}
          </CardBody>
        </Card>
      )}
    </Page>
  );
}

export default ReportSearchPage;
