import React, { ReactElement } from "react";
import { BootstrapColor } from 'models';

interface Props {
  title: string;
  value: string | number;
  color: BootstrapColor;
  icon: "user" | "statistic";
}


function Stat({color, title, value, icon}: Props): ReactElement {
  
  return (
    <div className={`card card-custom bg-${color} card-stretch gutter-b`}>
      <div className="card-body">
        <span className="svg-icon svg-icon-2x svg-icon-white">
          {icon === "user" ? <User /> : <Statistic />}
        </span>
        <span className="card-title font-weight-bolder text-white font-size-h2 mb-0 mt-6 d-block">
          {value}
        </span>
        <span className="font-weight-bold text-white font-size-sm">
          {title}
        </span>
      </div>
    </div>
  );
}

Stat.defaultProps = {
  color: "primary",
  icon: "statistic"
}


export default Stat;


function Statistic() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z"></path>
        <rect
          width="3"
          height="16"
          x="13"
          y="4"
          fill="#000"
          opacity="0.3"
          rx="1.5"
        ></rect>
        <rect width="3" height="11" x="8" y="9" fill="#000" rx="1.5"></rect>
        <rect width="3" height="9" x="18" y="11" fill="#000" rx="1.5"></rect>
        <rect width="3" height="7" x="3" y="13" fill="#000" rx="1.5"></rect>
      </g>
    </svg>
  );
}

function User() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0L24 0 24 24 0 24z"></path>
        <path
          fill="#000"
          fillRule="nonzero"
          d="M18 14a3 3 0 110-6 3 3 0 010 6zm-9-3a4 4 0 110-8 4 4 0 010 8z"
          opacity="0.3"
        ></path>
        <path
          fill="#000"
          fillRule="nonzero"
          d="M17.601 15c3.407.038 6.188 1.76 6.397 5.4.009.147 0 .6-.542.6H19.6c0-2.25-.744-4.328-1.999-6zm-17.6 5.2C.388 15.426 4.26 13 8.983 13c4.788 0 8.722 2.293 9.015 7.2.012.195 0 .8-.751.8H.727c-.25 0-.747-.54-.726-.8z"
        ></path>
      </g>
    </svg>
  );
}