import React, { FC, useState, useEffect, useRef, HTMLProps } from "react";
import { Card, CardBody, Table, Button, Input, Badge, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  ColumnDef,
  flexRender,
  getFilteredRowModel,
  getPaginationRowModel,
  getCoreRowModel,
  useReactTable,
  VisibilityState,
  ColumnOrderState,
  FilterFn,
  SortingState,
  getSortedRowModel,
  ColumnFiltersState,
} from "@tanstack/react-table";
import { Inventory } from "models";
import Lightbox from "react-image-lightbox";
import { Link, useHistory } from "react-router-dom";
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import ExcelButton from "components/ExcelButton";
import InventoryEditModal from "pages/report-pages/Edit/InventoryEditModal";
import InventoryFilter2 from "pages/report-pages/components/InventoryFilter2";
import "react-image-lightbox/style.css";

import Numberal from "numeral";
import Moment from "moment";
import { imageUrlManipulate } from "helper/urlHelper";
import { usePost, useRedux } from "hooks";
import { UserEnum } from "models/user-enum";

type DataTableProps = {
  data: any;
  readablePage?: boolean;
  passivePage?: boolean;
  removeAllDebit?: boolean;
};

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta, ...rest) => {
  // Rank the item
  let isPassed = false;
  let columnValue: string = row.getValue(columnId);
  if (columnValue && typeof columnValue === "number") {
    columnValue = `${columnValue}`;
  }
  if (columnValue && typeof columnValue === "string") {
    isPassed = columnValue.includes(value) || value.includes(columnValue);
  }

  // Return if the item should be filtered in/out
  return isPassed;
};

const DataTable: FC<DataTableProps> = (props) => {
  const [data, setData] = useState(() => [...props.data]);

  const [
    {
      auth: { user },
    },
  ] = useRedux();

  const history = useHistory();

  const defaultColumns: ColumnDef<any>[] = [
    {
      id: "select",
      accessorKey: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    },
    {
      id: "#ID",
      header: () => <span>#ID</span>,
      accessorKey: "id",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "buttons",
      header: () => <span>Butonlar</span>,
      accessorKey: "buttons",
      cell: (info) => {
        return (
          <div className="d-flex">
            <Button
              title="Notlar"
              size="sm"
              disabled={!info.row.original.notes}
              className="mr-1"
              onClick={() => {
                setNote(info.row.original.notes);
              }}
              color={info.row.original.notes ? "primary" : "secondary"}
            >
              <i className="flaticon2-notepad p-0" />
            </Button>
            <Button
              title="Fotoğraf"
              size="sm"
              disabled={!info.row.original.photoPath}
              className="mr-1"
              color={!!info.row.original.photoPath ? "info" : "secondary"}
              onClick={() => {
                if (info.row.original.photoPath) {
                  const imageUrl = imageUrlManipulate(info.row.original.photoPath);
                  setLightBoxPath(imageUrl);
                }
              }}
            >
              <i className="flaticon2-photo-camera  p-0" />
            </Button>
            <Button
              title="Döküman"
              size="sm"
              className="mr-1"
              color="danger"
              onClick={() => {
                onPdf(info.row.original);
              }}
            >
              <i className="flaticon2-file-2 p-0" />
            </Button>
            {user?.userRoleID !== UserEnum.basic && (
              <>
                <Button
                  size="sm"
                  className="mr-1"
                  title="Düzenle"
                  color="warning"
                  onClick={() => {
                    toggleOpen(info.row.original);
                  }}
                >
                  <i className="flaticon-edit-1 p-0" />
                </Button>
                <Link
                  to={`/report-edit/${info.row.original.barcode}`}
                  target="_blank"
                  title="Yeni Pencerede Düzenle"
                  color="info"
                  onClick={() => {}}
                  className="btn btn-sm btn-info mr-1"
                >
                  <i className="flaticon-edit-1 p-0" />
                </Link>
                <Link to={`/create-report/${info.row.original.barcode}`} target="_blank">
                  <Button size="sm" title="Düzenle" color="success">
                    <i className="flaticon2-copy p-0" />
                  </Button>
                </Link>
              </>
            )}
          </div>
        );
      },
    },
    {
      id: "Barkod",
      header: () => <span>Barkod</span>,
      accessorKey: "barcode",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Aktif/Pasif",
      header: () => <span>Durum</span>,
      accessorKey: "isActive",
      cell: (info: any) => {
        return <Badge color={info.getValue() ? "success" : "secondary"}>{info.getValue() ? "Aktif" : "Pasif"}</Badge>;
      },
    },
    {
      id: "Envantor",
      header: () => <span>Envantor No</span>,
      accessorKey: "inventoryNo",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Alım Tarihi",
      header: () => <span>Alım Tarihi</span>,
      accessorKey: "purchaseData",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Alım Fiyatı",
      header: () => <span>Alım Fiyatı</span>,
      accessorKey: "price",
      cell: (info: any) => {
        return info.getValue() ? Numberal(info.getValue().split(".").join(",")).format("0,0.00") : "";
      },
    },
    {
      id: "Fatura No",
      header: () => <span>Fatura No</span>,
      accessorKey: "invoiceNo",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Bölüm",
      header: () => <span>Bölüm</span>,
      accessorKey: "departmentName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Masraf Yeri",
      header: () => <span>Masraf Yeri</span>,
      accessorKey: "costName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Kampüs",
      header: () => <span>Kampüs</span>,
      accessorKey: "campusName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Bina",
      header: () => <span>Bina</span>,
      accessorKey: "buildName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Kat",
      header: () => <span>Kat</span>,
      accessorKey: "floorName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Oda",
      header: () => <span>Oda</span>,
      accessorKey: "roomName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Ana Grup",
      header: () => <span>Ana Grup</span>,
      accessorKey: "mainGrupName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Grup",
      header: () => <span>Grup</span>,
      accessorKey: "grupName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Tip",
      header: () => <span>Tip</span>,
      accessorKey: "text1",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Marka",
      header: () => <span>Marka</span>,
      accessorKey: "brandName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Model",
      header: () => <span>Model</span>,
      accessorKey: "modelName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Seri No",
      header: () => <span>Seri No</span>,
      accessorKey: "serialNumber",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Zimmetli",
      header: () => <span>Zimmetli</span>,
      accessorKey: "empleyooName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Zimmet Tarihi",
      header: () => <span>Zimmet Tarihi</span>,
      accessorKey: "lastDebitedDate",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Durum",
      header: () => <span>Durum</span>,
      accessorKey: "inventoryStatusName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Renk",
      header: () => <span>Renk</span>,
      accessorKey: "colorName",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "E-B-Y",
      header: () => <span>E-B-Y</span>,
      accessorKey: "e-b-y",
      cell: (info: any) => {
        return `${info.row.original.e ?? "*"}-${info.row.original.b ?? "*"}-${info.row.original.y ?? "*"}`;
      },
    },
    {
      id: "Notlar",
      header: () => <span>Notlar</span>,
      accessorKey: "notes",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Diğer 2",
      header: () => <span>Diğer 2</span>,
      accessorKey: "text2",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Diğer 3",
      header: () => <span>Diğer 3</span>,
      accessorKey: "text3",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Diğer 4",
      header: () => <span>Diğer 4</span>,
      accessorKey: "text4",
      cell: (info: any) => info.getValue(),
    },
    {
      id: "Oluşturma Tarihi",
      header: () => <span>Oluşturma Tarihi</span>,
      accessorKey: "createDate",
      cell: (info: any) => {
        return Moment(info.getValue()).format("DD.MM.YYYY HH:mm");
      },
    },
    {
      id: "Oluşturan Kişi",
      header: () => <span>Oluşturan Kişi</span>,
      accessorKey: "addUserName",
      cell: (info: any) => info.getValue(),
    },
  ];

  if (!!props.readablePage) {
    defaultColumns.push({
      id: "Sayım Tarihi",
      header: () => <span>Sayım Tarihi</span>,
      accessorKey: "lastReadDate",
      cell: (info: any) => info.getValue(),
    });
    defaultColumns.push({
      id: "Sayımı Gerçekleştiren",
      header: () => <span>Sayımı Gerçekleştiren</span>,
      accessorKey: "lastReadUserName",
      cell: (info: any) => info.getValue(),
    });
  }

  if (!!props.passivePage) {
    defaultColumns.push({
      id: "Pasif Yapım Tarihi",
      header: () => <span>Pasif Yapım Tarihi</span>,
      accessorKey: "activePassiveStatusChangedDate",
      cell: (info: any) => info.getValue(),
    });
    defaultColumns.push({
      id: "Pasif Yapan Kişi",
      header: () => <span>Pasif Yapan Kişi</span>,
      accessorKey: "activePassiveStatusChangedUserName",
      cell: (info: any) => info.getValue(),
    });
  }

  const [columns] = useState<typeof defaultColumns>(() => [...defaultColumns]);
  const [colonSettings, setColonSettings] = useState<boolean>(false);
  const [removeAllDebitModal, setRemoveAllDebitModal] = useState<boolean>(false);

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnPinning, setColumnPinning] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [note, setNote] = useState<string | null>(null);
  const [lightBoxPath, setLightBoxPath] = useState<string | null>(null);
  const linkRef = useRef<any>(null);
  const [isOpen, toggleOpen] = useState<Inventory | null>(null);
  const [isFilterOpen, toggleFilter] = useState<boolean>(false);
  const [removeAllDebit, statAllDebit, , removeAllDebitmsg] = usePost("/Inventory/RemoveDebits");

  const tableData = data.filter((item) => {
    let isFiltered = true;
    columnFilters.forEach((cF) => {
      let isMatch = false;
      Object.values(item).forEach((_value) => {
        if (_value === cF.value) {
          isMatch = true;
        }
      });
      isFiltered = isMatch;
    });
    return isFiltered;
  });

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnVisibility,
      columnOrder,
      columnPinning,
      globalFilter,
      sorting,
      rowSelection,
      columnFilters,
    },
    globalFilterFn: fuzzyFilter,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onColumnPinningChange: setColumnPinning,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  function getExcelData() {
    let tmpData: any[] = [];
    data?.forEach((item: any, index: number) => {
      let tmpItem: any = {};
      const _keys = [
        "id",
        "barcode",
        "isActive",
        "inventoryNo",
        "purchaseData",
        "price", // Add
        "invoiceNo",
        "departmentName",
        "costName",
        "campusName",
        "buildName",
        "floorName",
        "roomName",
        "mainGrupName",
        "grupName",
        "text1",
        "brandName",
        "modelName",
        "serialNumber",
        "empleyooName", //add
        "inventoryStatusName",
        "colorName",
        "eby",
        "notes", // add
        "text2",
        "text3",
        "text4",
        "createDate",
        "addUserName",
        "photoPath",
      ];
      if (Object.keys(rowSelection).length > 0) {
        Object.keys(rowSelection).map((data: any) => {
          if (index === +data) {
            _keys.forEach((key) => {
              if (key === "createDate") {
                tmpItem["Oluşturulma Tarihi"] = item["createDateFormatted"];
              } else if (key === "eby") {
                tmpItem["En Boy Yükseklik"] = item["eby"];
              } else if (key === "isActive") {
                tmpItem["Aktif / Pasif"] = item["isActiveFormatted"];
              } else if (key === "price") {
                tmpItem["Alım Fiyatı"] = item["price"];
              } else if (key === "empleyooName") {
                tmpItem["Zimmetli"] = item["empleyooName"];
              } else if (key === "notes") {
                tmpItem["Not"] = item["notes"];
              } else if (key === "photoPath") {
                tmpItem["Fotoğraf"] = item["photoPath"];
              } else {
                columns.find((data: any) => {
                  if (data.accessorKey === key) {
                    tmpItem[data.id as string] = item[data.accessorKey as string];
                  }
                });
              }
            });
            tmpData.push(tmpItem);
          }
        });
      } else {
        _keys.forEach((key) => {
          if (key === "createDate") {
            tmpItem["Oluşturulma Tarihi"] = item["createDateFormatted"];
          } else if (key === "eby") {
            tmpItem["En Boy Yükseklik"] = item["eby"];
          } else if (key === "isActive") {
            tmpItem["Aktif / Pasif"] = item["isActiveFormatted"];
          } else if (key === "price") {
            tmpItem["Alım Fiyatı"] = item["price"];
          } else if (key === "empleyooName") {
            tmpItem["Zimmetli"] = item["empleyooName"];
          } else if (key === "notes") {
            tmpItem["Not"] = item["notes"];
          } else if (key === "photoPath") {
            tmpItem["Fotoğraf"] = item["photoPath"];
          } else {
            columns.find((data: any) => {
              if (data.accessorKey === key) {
                tmpItem[data.id as string] = item[data.accessorKey as string];
              }
            });
          }
        });
        tmpData.push(tmpItem);
      }
    });

    if (!columnFilters) {
      return tmpData;
    }

    return tmpData.filter((item) => {
      let isFiltered = true;

      Object.entries(item).forEach(([key, value]: any) => {
        const filterItem = columnFilters.find((_filterItem) => _filterItem.id === key);
        if (filterItem) {
          if (filterItem.value !== value) {
            isFiltered = false;
          }
        }
      });

      return isFiltered;
    });
  }

  function handleClickTableToPdf() {
    localStorage.setItem("pdf-column-filter", JSON.stringify(columnFilters));
    window.open("/list-on-pdf", "_new");
  }

  function onPdf(inventory: Inventory) {
    localStorage.removeItem("@pdf-report");
    localStorage.setItem("@pdf-report", JSON.stringify(inventory));
    linkRef.current.click();
  }

  const selectedRows = table.getSelectedRowModel().rows.map((row) => row.original);

  async function removeAllDebitRequest() {
    const inventoryIds: any[] = [];
    selectedRows.map((data: any) => {
      inventoryIds.push(data.id);
    });
    await removeAllDebit({ inventoryIds: inventoryIds });
  }

  useEffect(() => {
    if (statAllDebit === "fulfilled") {
      window.location.reload();
    }
  }, [statAllDebit]);

  return (
    <Card className="mt-3">
      <CardBody>
        <div className="d-flex justify-content-between align-items-center w-100">
          <div>
            {selectedRows.length > 0 && (
              <>
                {/* <Button
                  color="warning"
                  onClick={() => {
                    localStorage.setItem(
                      "bulk-update-ids",
                      JSON.stringify(selectedRows.map((row) => ({ id: row.id, barcode: row.barcode })))
                    );
                    window.open("/report-edit-bulk", "new");
                  }}
                >
                  Düzenle
                </Button> */}
              </>
            )}
          </div>
          <div className="d-flex align-items-center">
            <Input
              type="text"
              style={{ maxWidth: 200 }}
              className="mr-1"
              placeholder="Ara..."
              onChange={({ currentTarget: { value } }) => {
                setGlobalFilter(value);
              }}
            />
            {props?.removeAllDebit && selectedRows.length > 0 && (
              <Button
                color="danger"
                onClick={() => setRemoveAllDebitModal(true)}
                className="ml-1"
                style={{ minWidth: 180 }}
              >
                Zimmetleri kaldır
              </Button>
            )}
            <ExcelButton data={getExcelData()} />
            <Button onClick={handleClickTableToPdf} className="ml-1" color="danger" outline>
              Pdf
            </Button>
            <Button
              outline
              color="primary"
              className="ml-1"
              style={{ minWidth: 120 }}
              onClick={() => setColonSettings(true)}
            >
              Kolon Ayarları
            </Button>
            {Object.keys(columnFilters).length > 0 ? (
              <Button onClick={() => setColumnFilters([])} outline color="danger" className="ml-1">
                Filtre Sıfırla
              </Button>
            ) : (
              <Button onClick={() => toggleFilter(true)} outline color="warning" className="ml-1">
                Filtre
              </Button>
            )}
          </div>
        </div>
        <Table responsive>
          <div className="scroll-position-content">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      <div style={{ whiteSpace: "nowrap" }} className="d-flex align-items-center px-5">
                        {header.isPlaceholder ? null : header.id !== "buttons" && header.id !== "select" ? (
                          <div
                            {...{
                              className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: (
                                <i
                                  style={{ color: "#000", fontSize: "12px", marginLeft: "5px" }}
                                  className="flaticon2-up p-0"
                                />
                              ),
                              desc: (
                                <i
                                  style={{ color: "#000", fontSize: "12px", marginLeft: "5px" }}
                                  className="flaticon2-down p-0"
                                />
                              ),
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        ) : (
                          <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      <div style={{ whiteSpace: "nowrap" }} className="d-flex min-h-40px align-items-center px-5">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </div>
        </Table>
        <div className="d-flex mt-5 align-items-center justify-content-between gap-2">
          <div>
            <span style={{ whiteSpace: "nowrap" }} className="d-flex align-items-center gap-1">
              Satır Göster:
              <Input
                className="w-60px"
                type="select"
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Input>
            </span>
          </div>

          <span className="flex items-center gap-1">
            <div>
              <strong>{tableData.length} veri bulundu</strong>
            </div>
          </span>

          <div className="d-flex align-items-center gap-2">
            <div className="mr-2">
              {table.getState().pagination.pageIndex + 1} /{" "}
              {Math.ceil(tableData.length / table.getState().pagination.pageSize)}
            </div>
            <Button
              className="bg-transparent border-none"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <i className="flaticon2-fast-back p-0" />
            </Button>
            <Button
              className="bg-transparent"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <i className="flaticon2-left-arrow p-0" />
            </Button>
            <Button
              className="bg-transparent"
              onClick={() => {
                table.nextPage();
              }}
              disabled={!table.getCanNextPage()}
            >
              <i className="flaticon2-right-arrow p-0" />
            </Button>
            <Button
              className="bg-transparent"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <i className="flaticon2-fast-next p-0" />
            </Button>
          </div>
        </div>
      </CardBody>

      <Modal
        isOpen={colonSettings}
        toggle={() => {
          setColonSettings(false);
        }}
      >
        <ModalHeader>Kolonları Göster / Gizle</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-wrap">
            {table.getAllLeafColumns().map(
              (column) =>
                column.id !== "select" && (
                  <div key={column.id} className="d-flex w-50 align-items-center mb-2">
                    <label>
                      <input
                        {...{
                          type: "checkbox",
                          checked: column.getIsVisible(),
                          onChange: column.getToggleVisibilityHandler(),
                        }}
                      />{" "}
                      {column.id}
                    </label>
                  </div>
                )
            )}
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={removeAllDebitModal}
        toggle={() => {
          setRemoveAllDebitModal(false);
        }}
        centered
      >
        <ModalHeader>Uyarı</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-wrap">Bütün Zimmetleri kaldırmak istediğinize emin misiniz?</div>
          <div className="d-flex justify-content-end mt-5">
            <Button color="danger" className="mr-5" onClick={() => removeAllDebitRequest()}>
              Kaldır
            </Button>
            <Button
              color="success"
              onClick={() => {
                setRemoveAllDebitModal(false);
              }}
            >
              Kapat
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal size="xl" isOpen={!!isOpen} fade={false}>
        <ModalBody>
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="m-0">Sabit Kıymet Düzenle</h3>
            <Button
              color="danger"
              size="sm"
              onClick={() => {
                toggleOpen(null);
              }}
            >
              Kapat
            </Button>
          </div>
          <hr />
          {!!isOpen && (
            <InventoryEditModal
              barcode={isOpen.barcode!}
              onEdit={() => {
                toggleOpen(null);
              }}
            />
          )}
        </ModalBody>
      </Modal>
      <Modal
        isOpen={!!note}
        toggle={() => {
          setNote(null);
        }}
        centered
      >
        <ModalHeader>Not</ModalHeader>
        <ModalBody>
          <p>{note}</p>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={isFilterOpen}
        toggle={() => {
          toggleFilter(!isFilterOpen);
        }}
        clasName="w-100"
      >
        <ModalBody>
          <InventoryFilter2
            isOpen={isFilterOpen}
            toggle={() => {
              toggleFilter(!isFilterOpen);
            }}
            onFilter={(v) => {
              setColumnFilters(v);
            }}
            onReset={() => {
              setColumnFilters([]);
            }}
            data={data}
          />
        </ModalBody>
      </Modal>
      {!!lightBoxPath && (
        <Lightbox
          mainSrc={lightBoxPath}
          onCloseRequest={() => {
            setLightBoxPath(null);
          }}
        />
      )}
      {!!isOpen && (
        <InventoryEditModal
          barcode={isOpen.barcode!}
          onEdit={() => {
            toggleOpen(null);
          }}
        />
      )}
      <Link
        to="/report-form"
        target="_blank"
        innerRef={linkRef}
        style={{ position: "absolute", width: 0, height: 0 }}
      ></Link>
    </Card>
  );
};

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return <input type="checkbox" ref={ref} className={className + " cursor-pointer"} {...rest} />;
}

export default DataTable;
