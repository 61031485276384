import { Status, useGet, useRedux } from "hooks";
import moment from "moment";
import { useLayoutEffect, useState } from "react";
import { apiUrls } from "../MatchInventory/service";
import InventoryPdfItem from "./IntentoryPdfItem";
import { getStoreData, Stores } from "helper/indexed-db";
import Loading from "../components/Loading";
import "./master.css";

function InventoryListOnPdf() {
  const [allGet, allStatus, allData, allError] = useGet(apiUrls.all);
  const [data, setData] = useState([]);
  const [
    {
      auth: { user },
    },
  ] = useRedux();

  const getData = () => {
    //const columnFiltersStr = localStorage.getItem("pdf-column-filter");
    // if (columnFiltersStr) {
    //   const columnFilters = JSON.parse(columnFiltersStr);
    //   data = data.filter((item) => {
    //     let isFiltered = true;
    //     columnFilters.forEach((cF) => {
    //       let isMatch = false;
    //       Object.values(item).forEach((_value) => {
    //         if (_value === cF.value) {
    //           isMatch = true;
    //         }
    //       });
    //       isFiltered = isMatch;
    //     });
    //     return isFiltered;
    //   });
    // }
    const chunkSize = 4;
    let returnData = [];
    for (let i = 0; i < data.length; i += chunkSize) {
      const chunk = data.slice(i, i + chunkSize);
      returnData.push(chunk);
    }
    return returnData;
  };

  async function handleGetDocs() {
    const data = await getStoreData(Stores.Docs);
    setData(data[0].data);
  }

  useLayoutEffect(() => {
    handleGetDocs();
  }, []);

  if (!data)
    return (
      <div>
        <Loading long />
      </div>
    );

  return (
    <div className="a4">
      <div className="body">
        {getData().map((chunk, index) => (
          <div className="page" style={{ position: "relative" }} key={index}>
            <div className="pb-2 d-flex justify-content-between align-items-center">
              <img src={user.companyLogo} style={{ height: "30px", width: "auto" }} alt="logo" />
              <div>{moment().format("DD.MM.YYYY")}</div>
            </div>
            {chunk.map((item, jindex) => (
              <InventoryPdfItem item={item} key={jindex} />
            ))}
            <div style={{ position: "absolute", width: "90%", textAlign: "center" }}>{index + 1}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default InventoryListOnPdf;
