export function imageUrlManipulate(url: string) {
  var photoPath: string = url;
  const duzenliIfade = /(https:\/\/skyapi\.tesisyonetim\.pro){2,}/;
  photoPath = photoPath.replace(duzenliIfade, "https://skyapi.tesisyonetim.pro");
  if (photoPath && photoPath.split(".").length > 4) {
    const photoUrlPath = photoPath.split(".Images");
    const splitedPath = photoUrlPath[1].split(".");
    const splitedPathFilteredExtension = splitedPath.filter((_, index) => index !== splitedPath.length - 1);
    const newPath = `${photoUrlPath[0]}/Images${splitedPathFilteredExtension.join("/")}.${
      splitedPath[splitedPath.length - 1]
    }`;
    return newPath;
  }
  return photoPath;
}
