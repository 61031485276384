export const campusUrls = {
  campusList: "/Campus/GetCampusListByProjectID/",
  campusCreate: "/Campus/AddCampus",
};

export interface CampusCreateFields {
  projectID: number;
  companyID: number;
  name: string;
  location: string;
}

export interface Campus {
  companyID?: number;
  createDate?: Date;
  id: number;
  isActive?: boolean;
  location?: string;
  name?: string;
  projectID?: number;
}

export const buildUrls = {
  buildList: "/Build/GetBuildListByCampusID",
};

export interface Build {
  campusID?: number;
  createDate?: Date;
  id: number;
  isActive?: boolean;
  name?: string;
  projectID?: number;
}

export const floorUrls = {
  floorList: "/Floor/GetFloorListByBuildID",
};

export const roomUrls = {
  roomList: "/Room/GetRoomListByFloorID",
};

export interface RoomListParams {
  floorId: number | string;
}
