import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { FormGroup, Input, Label } from "reactstrap";

type SkyInputProps = {
  control: any;
  rules?: any;
  name: string;
  label: string;
  isTextarea?: boolean;
  error?: any;
};

const SkyInput: FC<SkyInputProps> = (props) => {
  return (
    <Controller
      control={props.control}
      rules={props.rules}
      name={props.name}
      render={(fields) => (
        <FormGroup>
          <Label>
            {props.rules?.isRequired && <span className="text-danger">* </span>}
            {props.label}
          </Label>

          <Input
            {...fields}
            type={props.isTextarea ? "textarea" : "text"}
            className="resize-none"
            style={{ borderColor: "#ccc", borderRadius: "4px" }}
          />

          {props?.error && (
            <ErrorMessage
              errors={props?.error}
              name={props.name}
              render={({ message }) => <span className="text-danger">{message}</span>}
            />
          )}
        </FormGroup>
      )}
    />
  );
};

export default SkyInput;
