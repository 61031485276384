import React, { ReactElement } from 'react'
import { Spinner } from 'reactstrap';


function Loading({long}: any) {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: 200 }}>
      <Spinner size="lg" color="primary" />
      <h4 className="text-center mt-5">Yükleniyor</h4>
      {!!long && <h5>Bu işlem bir kaç dakika sürebilir.</h5>}
    </div>
  );
}

export default Loading
