import { Inventory } from "models";

export const apiUrls = {
  passive: "/Inventory/GetPassiveInventoryList",
};

export interface Params {
  projectId: number | string;
}

export type Res = Inventory[];
