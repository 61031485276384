import { TUser } from "../types/user-types";
import { TUserAction } from "../actions/user-actions";
import { SET_USER, CLEAR_USER, SET_PROJECT, SET_FULL_USER } from "../constants/user-constant";
import { setItem } from "helper/storageManager";

export interface IUserStore {
  isLogged: "login" | "unlogin" | "project-select" | "freeze";
  user: TUser | null;
  token: string | null;
}

const initialState: IUserStore = {
  isLogged: "unlogin",
  user: null,
  token: null,
};

export function userReducer(state: IUserStore = initialState, action: TUserAction): IUserStore {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        isLogged: "project-select",
        user: action.payload.user,
        token: action.payload.token,
      };
    case SET_PROJECT:
      setItem(state.token!, {
        ...state.user!,
        project: action.payload,
      });
      return {
        ...state,
        user: {
          ...state.user!,
          project: action.payload,
        },
        isLogged: "login",
      };
    case SET_FULL_USER:
      return {
        ...state,
        user: action.payload,
        isLogged: "login",
        token: action.payload.accessToken!,
      };
    case CLEAR_USER:
      return { ...initialState };
    default:
      return { ...state };
  }
}
