import React, { ReactElement, useLayoutEffect, useState } from "react";
import { Badge, Modal, ModalBody, Form, FormGroup, Input, Button, Label, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";

import { useForm } from "react-hook-form";
import { Page, SubHeader } from "layout/components";
import DataTable from "components/DataTable";
import { useGet, Status, useToggle, useRedux, usePost } from "hooks";
import { employeeUrls, EmployeeListParams, Employee } from "services/employee";
import Moment from "moment";
import ReactDOM from "react-dom";
import userEvent from "@testing-library/user-event";
import { editEmployeeForm } from "models/IEmployeeEdit";
import EmpleyooEditForm from "components/EmployeeEditForm/EmployeeEditForm";

interface EmpoyeeCreateField {
  nameSurname: string;
  sicilID: string;
}

function EmployeeList(): ReactElement {
  const { register, handleSubmit, errors } = useForm<EmpoyeeCreateField>();
  const [isMounted, setMounted] = useState<boolean>(false);
  const [getEmployees, employeesStat, employees] = useGet<Employee[], EmployeeListParams>(employeeUrls.list);
  const [createEmployee, createEmployeeStat] = usePost<any, Employee, {}>(employeeUrls.add);
  const [update,updateStats] = usePost<any, Employee, {}>("/Employee/UpdateEmployee");
  const [{ auth }] = useRedux();
  const [isOpenNew, toggleOpenNew] = useToggle();
  const [editEmloyeeForm, setEmloyeeEditForm] = useState<editEmployeeForm>({ modalOpen: false, defaultValues: null });

  useLayoutEffect(() => {
    if (isMounted) {
      if (createEmployeeStat === Status.error) {
        Swal.fire("Personel Eklenemedi", "Hatanız sistemimize eklendi. En kısa zamanda sorun çözülecektir!", "error");
      } else if (createEmployeeStat === Status.success) {
        toggleOpenNew();
        onGetEmployees();
        Swal.fire("Başarılı!", "Personel Başarıyla Eklendi!", "success");
      }
    } else {
      onGetEmployees();
      setMounted(true);
    }
  }, [createEmployeeStat]);

  async function handleClickToggle(row: Employee) {
    await update({
      ...row,
      isActive: !row.isActive,
    });
    onGetEmployees();
  }

  function onGetEmployees() {
    getEmployees({ projectID: auth.user!.project!.id! });
  }

  async function onValid(fields: EmpoyeeCreateField) {
    try {
      await createEmployee({ ...fields, projectID: auth.user?.project?.id, companyID: auth.user?.companyID });
    } catch (error) {}
  }

  useLayoutEffect(() => {
    onGetEmployees();
  }, [updateStats]);

  return (
    <Page loading={employeesStat === Status.pending}>
      <SubHeader title="Personel Listesi">
        <Button
          size="sm"
          color="primary"
          onClick={() => {
            toggleOpenNew();
          }}
        >
          Yeni Ekle
        </Button>
      </SubHeader>
      {!!employees && (
        <DataTable
          columns={[
            {
              name: "Personel Adı Soyadı",
              selector: "nameSurname",
            },
            {
              name: "Personel Sicili",
              selector: "sicilID",
            },
            {
              name: "Proje Adı",
              selector: "projectID",
              cell: function (row: any) {
                return auth.user!.project?.name;
              },
            },
            {
              name: "Oluşturulma Tarihi",
              selector: "createDate",
              cell: function (row: any) {
                return Moment(row.createDate).format("DD/MM/YYYY HH:mm");
              },
            },
            {
              name: "Durum",
              selector: "isActive",
              cell: function (row: any) {
                return <Badge color={row.isActive ? "success" : "secondary"}>{row.isActive ? "Aktif" : "Pasif"}</Badge>;
              },
            },
            {
              name: "Düzenle",
              cell: (row: any) => {
                return (
                  <Button
                    size="xs"
                    style={{ background: "#008080", color: "#FFF" }}
                    //disabled={auth.user?.userRoleID !== UserEnum.admin ? false : row.userRoleID === UserEnum.admin}
                    onClick={() =>
                      setEmloyeeEditForm({
                        modalOpen: true,
                        defaultValues: {
                          id: row.id,
                          companyID: row.companyID,
                          projectID: row.projectID,
                          nameSurname: row.nameSurname,
                          sicilID: row.sicilID,
                          isActive: row.isActive,
                          createDate: row.createDate,
                        },
                      })
                    }
                  >
                    Düzenle
                  </Button>
                );
              },
            },
            {
              name: "Pasif",
              selector: "id",
              cell: function (row: any) {
                return (
                  <Button
                    color="info"
                    size="sm"
                    className="ml-1"
                    onClick={() => {
                      handleClickToggle(row);
                    }}
                  >
                    <i className="flaticon-interface-7 p-0"></i>
                  </Button>
                );
              },
            },
          ]}
          data={employees}
        />
      )}
      <Modal isOpen={isOpenNew} toggle={toggleOpenNew}>
        <ModalHeader toggle={toggleOpenNew}>Personel Ekle</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit(onValid)}>
            <FormGroup>
              <Label>Personel Ad Soyadı</Label>
              <Input
                type="text"
                name="nameSurname"
                placeholder="Ad Soyad Giriniz..."
                innerRef={register({ required: true })}
                invalid={!!errors["nameSurname"]}
              />
            </FormGroup>
            <FormGroup>
              <Label>Personel Sicil Kodu</Label>
              <Input
                type="text"
                name="sicilID"
                placeholder="Sicil Kodu Giriniz..."
                innerRef={register({ required: true })}
                invalid={!!errors["sicilID"]}
              />
            </FormGroup>
            <FormGroup className="text-right">
              <Button type="button" color="danger" className="mr-2" outline onClick={toggleOpenNew}>
                Vazgeç
              </Button>
              <Button color="primary">Ekle</Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={editEmloyeeForm?.modalOpen}
        toggle={() => setEmloyeeEditForm({ modalOpen: false, defaultValues: null })}
        centered
      >
        <ModalHeader>Personel Düzenle</ModalHeader>
        <ModalBody>
          {editEmloyeeForm?.defaultValues && (
            <EmpleyooEditForm
              setEmloyeeEditForm={setEmloyeeEditForm}
              editEmployeeRequest={update}
              defaultValues={editEmloyeeForm.defaultValues}
            />
          )}
        </ModalBody>
      </Modal>
    </Page>
  );
}

export default EmployeeList;
