import React, { FC } from "react";
import { usePost, useToggle } from "hooks";
import { Controller, useController, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import RSelect from "react-select";
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap";

type SelectBoxProps = {
  options: Array<{ value: string; label: string }>;
  control: any;
  rules?: any;
  name: string;
  invalid?: boolean;
  isLoading?: boolean;
  onChange?: (value: string | undefined) => void;
  label?: string;
  addurl?: string;
  addParams?: any;
  getList?: any;
  isEmployee?: any;
  error?: any;
};

const SelectBox: FC<SelectBoxProps> = (props) => {
  const { register, handleSubmit, errors, trigger } = useForm();

  const [postAddFetch, statusAdd, dataAdd] = usePost(props.addurl ?? "");
  const [isOpen, toggle] = useToggle();

  async function onValid(values: any) {
    await postAddFetch({ ...values, ...props.addParams });
    toggle();
    if (props.getList) {
      props.getList();
    }
  }
  
  return (
    <div className="w-100">
      {props.label && <label>{props.label}</label>}
      <div className="d-flex">
        <div className="w-100">
          <Controller
            control={props.control}
            rules={props.rules}
            name={props.name}
            render={(fields) => (
              <>
                <RSelect
                  name={fields.name}
                  isLoading={props.isLoading}
                  isDisabled={props.isLoading}
                  isClearable
                  onBlur={fields.onBlur}
                  placeholder="Seçim yapınız"
                  value={props.options.find((val) => val.value === fields.value) ?? null}
                  options={props.options}
                  onChange={(_value) => {
                    fields.onChange(_value?.value);
                    if (fields.onChange) {
                      fields.onChange(_value?.value);
                    }
                  }}
                />
                {props?.error && (
                  <ErrorMessage
                    errors={props?.error}
                    name={props.name}
                    render={({ message }) => {
                      console.log(message, "message");
                      return <span className="text-danger">{message}</span>;
                    }}
                  />
                )}
              </>
            )}
          />
        </div>

        {props.addurl && (
          <Button className="ml-1" style={{ maxHeight: "38px" }} color="success" type="button" onClick={toggle}>
            +
          </Button>
        )}
      </div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>{props.label} Ekle</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>İsim</Label>
            <Input type="text" name={props.isEmployee ? "userName" : "name"} innerRef={register()} />
          </FormGroup>
          {props.isEmployee && (
            <FormGroup>
              <Label>Sicil</Label>
              <Input type="text" name={"sicilID"} innerRef={register()} />
            </FormGroup>
          )}
          <div className="d-flex justify-content-end">
            <Button
              type="button"
              color="success"
              onClick={() => {
                handleSubmit(onValid)();
              }}
            >
              Ekle
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

type SelectBoxFormlessProps = {
  name: string;
  options: Array<{ value: string; label: string }>;
  onChange: (value: string) => void;
  value?: string;
  isDisabled?: boolean;
};

export const SelectBoxFormless: FC<SelectBoxFormlessProps> = (props) => {
  return (
    <div>
      <RSelect
        name={props.name}
        isClearable
        isDisabled={props.isDisabled}
        placeholder="Seçim yapınız"
        value={props.options.find((val) => val.value === props.value)}
        options={props.options}
        onChange={(_value) => {
          props.onChange(_value!.value);
        }}
      />
    </div>
  );
};

export default SelectBox;
