import SelectBox from "components/SelectBox";
import { Status, useGet, useRedux } from "hooks";
import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";

type Props = {
  control: any;
  setValue: any;
  defaults: any;
  watch: any;
};

const EnvanterSelectInfos = (props: Props) => {
  const [
    {
      auth: { user },
    },
  ] = useRedux();

  const [getDepartmentFetch, statDepartment, dataDepartment] = useGet<any[], any>(
    "/Department/GetDepartmentListByProjectID"
  );
  const [getStatsFetch, statStat, dataStats] = useGet<any[], {}>("/Inventory/GetInventoryStatusList");

  const [getCoastFetch, statCoast, dataCoast] = useGet<any[], any>("/Department/GetCostListByDepartmentID");

  function getStats() {
    getStatsFetch({ projectId: user!.project!.id });
  }
  function getDepartment() {
    getDepartmentFetch({ projectId: user!.project!.id });
  }

  function getCoast(departmentId: number) {
    getCoastFetch({ departmentID: departmentId });
  }

  useEffect(() => {
    getStats();
    getDepartment();
    if (props.defaults.departmentId) {
      getCoast(props.defaults.departmentId);
    }
  }, []);

  function isLoading() {
    if (!dataStats) {
      return true;
    }
    if (!dataDepartment) {
      return true;
    }

    if (props.defaults.departmentId && !dataCoast) {
      return true;
    }

    return false;
  }

  if (isLoading()) {
    return <div>Loading</div>;
  }

  return (
    <Row className="mt-3">
      <Col xs={3}>
        <SelectBox
          label="Durum"
          name="inventoryStatId"
          isLoading={statStat !== Status.success}
          control={props.control}
          options={dataStats?.map((item: any) => ({ value: item.id, label: item.name })) ?? []}
        />
      </Col>
      <Col xs={3}>
        <SelectBox
          label="Bölüm"
          name="departmentId"
          isLoading={statDepartment !== Status.success}
          control={props.control}
          options={dataDepartment?.map((item: any) => ({ value: item.id, label: item.name })) ?? []}
          onChange={(departmentId) => {
            if (departmentId) {
              props.setValue("costID", null);
              getCoast(parseInt(departmentId));
            }
          }}
          addurl="/Department/AddDepartment"
          addParams={{
            projectId: user!.project!.id,
          }}
          getList={getDepartment}
        />
      </Col>
      <Col xs={3}>
        <SelectBox
          label="Masraf Yeri"
          name="costId"
          control={props.control}
          options={dataCoast?.map((item: any) => ({ value: item.id, label: item.name })) ?? []}
          isLoading={statCoast !== Status.success}
          addurl="/Department/AddCost"
          addParams={{
            departmentId: parseInt(props.watch("departmentId")),
          }}
          getList={() => getCoast(parseInt(props.watch("departmentId")))}
        />
      </Col>
    </Row>
  );
};

export default EnvanterSelectInfos;
