import React, { ReactElement, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Modal, ModalBody, ModalHeader, Form, FormGroup, Label, Input, Button, Spinner, Badge } from "reactstrap";
import { useForm } from "react-hook-form";
import { Page, SubHeader } from "layout/components";
import DataTable from "components/Table";
import JqueryTable from "components/DataTable";
import { Status, useGet, usePost, useRedux, useToggle } from "hooks";
import { Campus, campusUrls, CampusCreateFields } from "services/location";
import Moment from "moment";
import { Link, useHistory } from "react-router-dom";
import CampusForm from "./CampusForm";
import { UserEnum } from "models/user-enum";

interface Props {}

function CampusList({}: Props): ReactElement {
  const history = useHistory();
  const [loadingRow, setLoadingRow] = useState<number | string | null>(null);
  const [isOpenNew, setOpenNew] = useState<boolean>(false);
  const [isOpenEdit, editToggle] = useToggle();
  const [
    {
      auth: { user },
    },
  ] = useRedux();
  const [getCampusList, campusListStat, campusList] = useGet<Campus[], { projectId: number }>(campusUrls.campusList);
  const [getCampusOne, campusOneStat, campusOne] = useGet<Campus, { campusId: number | string }>(
    "/Campus/GetCampusById"
  );
  const [createCampus, createStat, createResp] = usePost<CampusCreateFields, {}, {}>(campusUrls.campusCreate);
  const [updateCampus] = usePost("/Campus/UpdateCampus");

  async function onValidAddCampus(data: { name: string; location: string }) {
    await createCampus({
      companyID: user!.companyID!,
      location: data.location,
      name: data.name,
      projectID: user!.project!.id!,
    });
    setOpenNew(false);
    getCampusList({ projectId: user!.project!.id! });
  }

  async function onValidUpdateCampus(data: { name: string; location: string }) {
    await updateCampus({
      ...campusOne,
      ...data,
    });
    editToggle();
    getCampusList({ projectId: user!.project!.id! });
  }

  async function handleClickEdit(campusId: string | number) {
    await getCampusOne({ campusId });
    editToggle();
  }

  async function handleClickToggle(row: any) {
    await updateCampus({
      ...row,
      isActive: !row.isActive,
    });
    getCampusList({ projectId: user!.project!.id! });
  }

  useEffect(() => {
    getCampusList({ projectId: user!.project!.id! });
  }, []);

  return (
    <Page loading={campusListStat === Status.pending}>
      <SubHeader title={"Kampüsler"}>
        {user?.userRoleID !== UserEnum.basic && (
          <Button
            size="sm"
            color="primary"
            onClick={() => {
              setOpenNew(true);
            }}
          >
            Yeni Kampüs Ekle
          </Button>
        )}
      </SubHeader>
      {campusList && (
        <JqueryTable
          columns={[
            {
              name: "Kampüs İsmi",
              selector: "name",
            },
            {
              name: "Adres",
              selector: "location",
            },
            {
              name: "Eklenme Tarihi",
              selector: "createDate",
              cell: function (row: any) {
                return Moment(row.createDate).format("DD/MM/YYYY HH:mm");
              },
            },
            {
              name: "Durum",
              selector: "isActive",
              // render: function(data: any, type: any, row: any) {

              // },
              cell: function (cell: any) {
                return (
                  <Badge
                    className="cursor-pointer"
                    onClick={() => {
                      handleClickToggle(cell);
                    }}
                    color={cell.isActive ? "success" : "secondary"}
                  >
                    {cell.isActive ? "Aktif" : "Pasif"}
                  </Badge>
                );
              },
            },
            {
              name: "Button",
              selector: "id",
              cell: function (row: any) {
                return user?.userRoleID !== UserEnum.basic ? (
                  <div>
                    <Button
                      color="info"
                      size="sm"
                      className="ml-1"
                      onClick={() => {
                        handleClickToggle(row);
                      }}
                    >
                      <i className="flaticon-interface-7 p-0"></i>
                    </Button>
                    <Button
                      className="btn btn-primary btn-sm ml-1"
                      color="primary"
                      onClick={() => {
                        history.push("/campus/" + row.id + "/builds/");
                      }}
                    >
                      Binalar
                    </Button>
                  </div>
                ) : (
                  <div>
                    <span>Yetkiniz yok</span>
                  </div>
                );
              },
            },
          ]}
          data={
            campusList
              ? campusList.map((campus) => ({
                  ...campus,
                }))
              : []
          }
        />
      )}
      <Modal
        isOpen={isOpenNew}
        toggle={() => {
          setOpenNew(!isOpenNew);
        }}
      >
        <ModalHeader
          toggle={() => {
            setOpenNew(!isOpenNew);
          }}
        >
          Kampüs Ekle
        </ModalHeader>
        <ModalBody>
          <CampusForm {...{ user, onValid: onValidAddCampus }} />
        </ModalBody>
      </Modal>
      <Modal isOpen={isOpenEdit} toggle={editToggle}>
        <ModalHeader>Kampüs Düzenle</ModalHeader>
        {campusOne && (
          <ModalBody>
            <CampusForm {...{ user, onValid: onValidUpdateCampus, defaultValue: campusOne }} />
          </ModalBody>
        )}
      </Modal>
      <Modal isOpen={campusOneStat === Status.pending} centered>
        <ModalBody>
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
          <h3 className="text-center">Yükleniyor</h3>
        </ModalBody>
      </Modal>
    </Page>
  );
}

export default CampusList;
