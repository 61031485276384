import React from "react";
import { useStore } from "react-redux";
import { Redirect } from "react-router-dom";
import Moment from "moment";
import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink, Image } from "@react-pdf/renderer";
import ProjectLogo from "./sky.png";
import fotoyok from "./foto-yok.png";

export function editText(text) {
  if (!text) {
    return editText("---");
  }
  if (typeof text !== "string") {
    return text;
  }
  var trMap = {
    çÇ: "c",
    ğĞ: "g",
    şŞ: "s",
    üÜ: "u",
    ıİ: "i",
    öÖ: "o",
  };
  for (var key in trMap) {
    text = text.replace(new RegExp("[" + key + "]", "g"), trMap[key]);
  }
  return text
    .replace(/[^-a-zA-Z0-9\s]+/gi, "")
    .replace(/\s/gi, " ")
    .replace(/[-]+/gi, "-")
    .toUpperCase();
}

export default function Report() {
  const { user } = useStore().getState().auth;
  // const { state } = props.location;
  function getData() {
    const d = localStorage.getItem("@pdf-report");
    if (d) {
      return JSON.parse(d);
    }
    return null;
  }
  const data = getData();

  if (!data) {
    return <Redirect to="/general-reports/completed-maintenance" />;
  }
  return (
    <>
      {/* <h1>Result Page</h1>
  <PDFDownloadLink document={<MyDocument />} fileName="somename.pdf">
    {({ blob, url, loading, error }) =>
      loading ? "Loading document..." : "Download now!"
    }
  </PDFDownloadLink> */}
      <PDFViewer style={{ width: "100%", height: "99vh" }}>
        <MyDocument logo={user.companyLogo} campanyName={user.companyName} data={data} />
      </PDFViewer>
    </>
  );
}

// Create Document Component
const MyDocument = ({ logo, data, campanyName }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <DocumentHeader logo={logo} campanyName={campanyName} />
        <Section title="Konum Bilgileri">
          <List
            items={[
              { title: editText("Kampüs"), value: editText(data.CampusName) },
              { title: editText("Bina"), value: editText(data.BuildName) },
              { title: editText("Kat"), value: editText(data.FloorName) },
              { title: editText("Oda"), value: editText(data.RoomName) },
              { title: editText("Kayıt Tarihi"), value: Moment(data.createDate).format("DD.MM.YYYY HH:mm") },
            ]}
          />
        </Section>
        <Section title="Envanter Bilgileri">
          <List
            items={[
              { title: editText("Barkod"), value: editText(data.Barcode) },
              { title: editText("Envanter No"), value: editText(data.InventoryNo) },
              { title: editText("Durumu"), value: editText(data.InventoryStatusName) },
              { title: editText("Alım Tarihi"), value: data.PurchaseData },
              { title: editText("Alım Fiyatı"), value: editText(data.Rrice) },
              { title: editText("Fatura No"), value: editText(data.InvoiceNo) },
              { title: editText("Bölüm"), value: editText(data.DepartmentName) },
              { title: editText("Masraf Yeri"), value: editText(data.CostName) },
            ]}
          />
        </Section>
        <Section title="Teknik Bilgiler">
          <List
            items={[
              { title: editText("Ana Grup"), value: editText(data.MainGrupName) },
              { title: editText("Grup"), value: editText(data.GrupName) },
              { title: editText("Tip"), value: editText(data.Text1) },
              { title: editText("Marka"), value: editText(data.BrandName) },
              { title: editText("Model"), value: editText(data.ModelName) },
              { title: editText("Seri No"), value: editText(data.SerialNumber) },
            ]}
          />
        </Section>
        <Section title="Zimmet Bilgileri">
          <List
            items={[
              { title: editText("Ad Soyad"), value: editText(data.EmpleyooName) },
              { title: editText("Sicil No"), value: editText(data.EmpleyooSicil) },
            ]}
          />
        </Section>
        <Section title="Boyut Bilgileri">
          <List
            items={[
              { title: editText("En"), value: editText(data.E) },
              { title: editText("Boy"), value: editText(data.B) },
              { title: editText("Yükseklik"), value: editText(data.Y) },
              { title: editText("Renk"), value: editText(data.ColorName) },
            ]}
          />
        </Section>
        <Section title="Diğer Bilgileri">
          <List
            items={[
              { title: editText("Diğer 2"), value: editText(data.Text2) },
              { title: editText("Diğer 3"), value: editText(data.Text3) },
              { title: editText("Diğer 4"), value: editText(data.Text4) },
              { title: editText("Notlar"), value: editText(data.Notes) },
            ]}
          />
        </Section>
        <Section title="Fotoğraf">
          <Image
            src={data.PhotoPath || fotoyok}
            style={data.PhotoPath ? { width: 130, height: 130 } : { width: 200, height: 100 }}
          />
        </Section>
      </Page>
    </Document>
  );
};

const Section = ({ children, title }) => (
  <View style={styles.section}>
    <Text style={styles.sectionTitle}>{editText(title)}</Text>
    <View style={styles.br} />
    <View style={styles.sectionContent}>{children}</View>
  </View>
);

const List = ({ items }) =>
  items.map((item) => (
    <View style={styles.listItem}>
      <Text style={styles.listTitle}>{item.title}</Text>
      <Text style={styles.listValue}>: {item.value}</Text>
    </View>
  ));

export const DocumentHeader = ({ logo, campanyName }) => (
  <View style={styles.documentHeader}>
    <View style={{}}>
      <Image src={ProjectLogo} style={{ height: 35, width: 120 }} />
    </View>
    <View
      style={{
        flexDirection: "column",
        alignItems: "center",
        fontSize: 16,
        fontWeight: "bold",
      }}
    >
      <Text>SABIT KIYMET YONETIM SISTEMI</Text>
    </View>
    <View style={{ fontSize: 14 }}>
      <Text>{Moment().format("DD.MM.YYYY")}</Text>
    </View>
  </View>
);

export const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 30,
    color: "#3B3838",
    position: "relative",
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    fontSize: 10,
  },
  listTitle: {
    fontWeight: "semibold",
    width: 100,
  },
  listValue: {},
  br: {
    width: "80%",
    height: 0.5,
    backgroundColor: "black",
    marginBottom: 4,
  },
  section: {
    margin: 10,
    marginBottom: 0,
  },
  sectionTitle: {
    fontSize: 11,
    fontWeight: 700,
    marginBottom: 4,
  },
  sectionBody: {},
  documentHeader: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#7F7F7F",
    paddingBottom: 10,
    paddingHorizontal: 10,
  },
});

// const InventoryInfo = ({ data }) => {
//   console.log({data});
//   const values = [
//     {
//       title: "Tesis",
//       value: editText(data.campusName),
//     },
//     {
//       title: "Bina",
//       value: editText(data.buildName),
//     },
//     {
//       title: "Kat",
//       value: editText(data.floorName),
//     },
//     {
//       title: "Oda",
//       value: editText(data.roomName),
//     },
//     {
//       title: "Kod",
//       value: editText(data.barcode),
//     },
//     {
//       title: "Envanter No",
//       value: editText(data.assetNo),
//     },
//     {
//       title: "Ekipman Adi",
//       value: editText(data.name),
//     },
//     {
//       title: "Grup",
//       value: editText(data.groupName),
//     },
//     {
//       title: "Marka",
//       value: editText(data.brandName),
//     },
//     {
//       title: "Model",
//       value: editText(data.modelName),
//     },
//     {
//       title: "Seri No",
//       value: editText(data.serialNumber),
//     },
//     {
//       title: "Üretim Yili",
//       value: editText(data.productionYear),
//     },
//     {
//       title: "Kapasite Birimi",
//       value: editText(data.inventoryCapacityName),
//     },
//     {
//       title: "Kapasite Degeri",
//       value: editText(data.unit),
//     },
//     {
//       title: "Adet",
//       value: editText(data.count),
//     },
//   ];
//   return (
//     <View
//       style={{ flexDirection: "column", marginTop: 40, paddingHorizontal: 10 }}
//     >
//       <Text style={{ fontSize: 16, fontWeight: 700, marginBottom: 10 }}>
//         Ekipman Bilgileri
//       </Text>
//       {values.map((item, index) => (
//         <View
//           style={{
//             flexDirection: "row",
//             alignItems: "center",
//             marginBottom: 5,
//           }}
//           key={index}
//         >
//           <Text style={{ fontSize: 13, width: 180 }}>{item.title}</Text>
//           <Text style={{ fontSize: 13, marginLeft: 10 }}>:</Text>
//           <Text style={{ fontSize: 13, marginLeft: 10 }}>{item.value}</Text>
//         </View>
//       ))}
//       <View
//         style={{
//           marginTop: 30,
//           flexDirection: "column",
//           alignItems: "center",
//           paddingBottom: 20,
//         }}
//       >
//         {/* <Text style={{ fontSize: 16, fontWeight: 700, marginBottom: 10 }}>
//           Ekipman Fotografi
//         </Text>
//         <Image
//           src={data.inventoryPhoto || fotoyok}
//           style={{ width: "auto", height: 250 }}
//         /> */}
//       </View>
//     </View>
//   );
// };
