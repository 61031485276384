// Set User
export const SET_USER = "SET_USER";
export type SET_USER = typeof SET_USER;
// Clear User
export const CLEAR_USER = "CLEAR_USER";
export type CLEAR_USER = typeof CLEAR_USER;
// Set Project
export const SET_PROJECT = "SET_PROJECT";
export type SET_PROJECT = typeof SET_PROJECT;
// Set Full
export const SET_FULL_USER = "SET_FULL_USER";
export type SET_FULL_USER = typeof SET_FULL_USER;