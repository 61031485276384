import React, { ReactElement, useLayoutEffect, useState } from "react";
import { Badge, Modal, ModalBody, Form, FormGroup, Input, Button, Label, ModalHeader, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Swal from "sweetalert2";

import { useForm } from "react-hook-form";
import { Page, SubHeader } from "layout/components";
import DataTable from "components/DataTable";
import { useGet, Status, useToggle, useRedux, usePost } from "hooks";
import { userUrls, UserListParams, User, UserCreate } from "services/user";
import Moment from "moment";
import { UserEnum } from "models/user-enum";
import UserEditForm from "components/UserEditForm/UserEditForm";
import { editUserForm } from "models/IUserEdit";

interface UserCreateField {
  name: string;
  surname: string;
  userName: string;
  email: string;
  password: string;
  phone: string;
}

interface Employees {
  id: number;
  userRoleID: number;
  companyID: number;
  name: string;
  surname: string;
  userName: string;
  email?: any;
  passwordSalt?: any;
  passwordHash?: any;
  phone?: any;
  notificationToken?: any;
  isNotificationEnable: boolean;
  isActive: boolean;
  createDate: string;
  projectName: string;
}

function EmployeeList(): ReactElement {
  const { register, handleSubmit, errors } = useForm<UserCreateField>();
  const [isMounted, setMounted] = useState<boolean>(false);
  const [status, setStatus] = useState<Employees | null>(null);
  const [getEmployees, employeesStat, employees] = useGet<User[], UserListParams>(userUrls.list);
  const [createEmployee, createEmployeeStat] = usePost<any, User, {}>(userUrls.add);
  const [postEditUser, editUserStats] = usePost("/User/UpdateUser");
  const [{ auth }] = useRedux();
  const [isOpenNew, toggleOpenNew] = useToggle();
  const [enable] = useGet<{}, { userId: number | string }>("/User/userEnable");
  const [disable] = useGet<{}, { userId: number | string }>("/User/userDisable");
  const [editUserForm, setUserEditForm] = useState<editUserForm>({ modalOpen: false, defaultValues: null });
  const [roleIsOpen, roleToggle] = useToggle();
  const [role, setRole] = useState(UserEnum.basic);

  useLayoutEffect(() => {
    if (isMounted) {
      if (createEmployeeStat === Status.error) {
        Swal.fire("Kullanıcı Eklenemedi", "Hatanız sistemimize eklendi. En kısa zamanda sorun çözülecektir!", "error");
      } else if (createEmployeeStat === Status.success) {
        toggleOpenNew();
        onGetEmployees();
        Swal.fire("Başarılı!", "Kullanıcı Başarıyla Eklendi!", "success");
      }
    } else {
      onGetEmployees();
      setMounted(true);
    }
  }, [createEmployeeStat]);

  useLayoutEffect(() => {
    onGetEmployees();
  }, [editUserStats]);

  function onGetEmployees() {
    getEmployees({ projectId: auth.user!.project!.id! });
  }

  async function onValid(fields: UserCreateField) {
    try {
      await createEmployee({
        ...fields,
        passwordSalt: fields.password,
        passwordHash: fields.password,
        email: fields.email,
        userRoleId: role,
        projectID: auth.user?.project?.id,
        companyID: auth.user?.companyID,
      });
    } catch (error) {}
  }

  async function handleClickToggle(row: any) {
    if (row.isActive) {
      await disable({ userId: row.id });
      setStatus(null);
    } else {
      await enable({ userId: row.id });
      setStatus(null);
    }
    onGetEmployees();
  }

  function getUserRoleComp(userRoleID: UserEnum) {
    switch (userRoleID) {
      case UserEnum.admin:
        return <Badge color={"primary"}>Admin</Badge>;
      case UserEnum.manager:
        return <Badge color={"warning"}>Yönetici</Badge>;
      case UserEnum.basic:
        return <Badge color={"dark"}>Standart</Badge>;
    }
  }

  return (
    <Page loading={employeesStat === Status.pending}>
      <SubHeader title="Kullanıcı Listesi">
        <Button
          size="sm"
          color="primary"
          onClick={() => {
            toggleOpenNew();
          }}
        >
          Yeni Ekle
        </Button>
      </SubHeader>
      {!!employees && (
        <DataTable
          columns={[
            {
              name: "Adı",
              selector: "name",
            },
            {
              name: "Soyadı",
              selector: "surname",
            },
            {
              name: "Kullanıcı Adı",
              selector: "userName",
            },
            {
              name: "E-Posta",
              selector: "email",
            },
            {
              name: "Proje Adı",
              selector: "projectName",
              cell: function (row: any) {
                return auth.user!.project?.name;
              },
            },
            {
              name: "Oluşturulma Tarihi",
              selector: "createDate",
              cell: function (row: any) {
                return Moment(row.createDate).format("DD/MM/YYYY HH:mm");
              },
            },
            {
              name: "Durum",
              selector: "isActive",
              cell: function (row: any) {
                return <Badge color={row.isActive ? "success" : "danger"}>{row.isActive ? "Aktif" : "Pasif"}</Badge>;
              },
            },
            {
              name: "Rol",
              selector: "userRoleID",
              cell: function (row: any) {
                return getUserRoleComp(row.userRoleID);
              },
            },
            {
              name: "Düzenle",
              cell: (row: any) => {
                return (
                  <Button
                    size="xs"
                    style={{ background: "#008080", color: "#FFF" }}
                    disabled={auth.user?.userRoleID !== UserEnum.admin ? false : row.userRoleID === UserEnum.admin}
                    onClick={() =>
                      setUserEditForm({
                        modalOpen: true,
                        defaultValues: {
                          id: row.id,
                          userRoleID: row.userRoleID,
                          name: row.name,
                          surname: row.surname,
                          email: row.email,
                          phone: row.phone,
                          isActive: row.isActive,
                        },
                      })
                    }
                  >
                    Düzenle
                  </Button>
                );
              },
            },
            {
              name: "İşlem",
              cell: function (row: any) {
                return (
                  <Button
                    color="info"
                    size="sm"
                    className="ml-1"
                    onClick={() => {
                      setStatus(row);
                    }}
                  >
                    <i className="flaticon-interface-7 p-0"></i>
                  </Button>
                );
              },
            },
          ]}
          data={employees.map((employee) => ({ ...employee, projectName: auth.user!.project?.name }))}
        />
      )}
      <Modal isOpen={isOpenNew} toggle={toggleOpenNew}>
        <ModalHeader toggle={toggleOpenNew}>Kullanıcı Ekle</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit(onValid)}>
            <FormGroup>
              <Label>Ad</Label>
              <Input
                type="text"
                name="name"
                placeholder="Ad Giriniz..."
                innerRef={register({ required: true })}
                invalid={!!errors["name"]}
              />
            </FormGroup>
            <FormGroup>
              <Label>Soyad</Label>
              <Input
                type="text"
                name="surname"
                placeholder="Soyad Giriniz..."
                innerRef={register({ required: true })}
                invalid={!!errors["surname"]}
              />
            </FormGroup>
            <FormGroup>
              <Label>Kullanıcı Adı</Label>
              <Input
                type="text"
                name="userName"
                placeholder="Kullanıcı Adı Giriniz..."
                innerRef={register({ required: true })}
                invalid={!!errors["userName"]}
              />
            </FormGroup>
            <FormGroup>
              <Label>E-Posta</Label>
              <Input
                type="text"
                name="email"
                placeholder="E-Posta Adı Giriniz..."
                innerRef={register()}
                invalid={!!errors["email"]}
              />
            </FormGroup>
            <FormGroup>
              <Label>Parola</Label>
              <Input
                type="text"
                name="password"
                placeholder="Parola Giriniz..."
                innerRef={register({ required: true })}
                invalid={!!errors["password"]}
              />
            </FormGroup>
            <FormGroup>
              <Label>Telefon</Label>
              <Input
                type="text"
                name="phone"
                placeholder="Telefon Giriniz..."
                innerRef={register()}
                invalid={!!errors["phone"]}
              />
            </FormGroup>
            <div className="header-menu-wrapper header-menu-wrapper-right mt-4">
              <div className="d-flex align-items-center mr-4">Rol:</div>
              <Dropdown isOpen={roleIsOpen} toggle={roleToggle}>
                <DropdownToggle color="secondary" className="border-0" caret onClick={() => roleToggle}>
                  {role === UserEnum.admin ? "Admin" : role === UserEnum.manager ? "Yönetici" : "Standart"}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setRole(UserEnum.admin)}>Admin</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => setRole(UserEnum.manager)}>Yönetici</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => setRole(UserEnum.basic)}>Standart</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <FormGroup className="text-right">
              <Button type="button" color="danger" className="mr-2" outline onClick={toggleOpenNew}>
                Vazgeç
              </Button>
              <Button color="primary">Ekle</Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={!!status} toggle={() => setStatus(null)} centered>
        <ModalHeader toggle={toggleOpenNew}>Kullanıcı Durum Değiştir</ModalHeader>
        <ModalBody>
          <div>
            Kullanıcıyı {status?.isActive ? <strong>pasif</strong> : <strong>aktif</strong>} etmek istediğinize emin
            misiniz?
          </div>
          <div className="d-flex justify-content-end gap-2">
            <Button size="md" color="danger" title="Kapat" onClick={() => setStatus(null)}>
              Kapat
            </Button>
            <Button size="md" color="success" title="Evet" onClick={() => handleClickToggle(status)}>
              Evet
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={editUserForm?.modalOpen}
        toggle={() => setUserEditForm({ modalOpen: false, defaultValues: null })}
        centered
      >
        <ModalHeader>Kullanıcı Düzenle</ModalHeader>
        <ModalBody>
          {editUserForm?.defaultValues && (
            <UserEditForm
              setUserEditForm={setUserEditForm}
              editUserRequest={postEditUser}
              defaultValues={editUserForm.defaultValues}
            />
          )}
        </ModalBody>
      </Modal>
    </Page>
  );
}

export default EmployeeList;
