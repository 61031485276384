import React, { ReactElement, useLayoutEffect } from "react";
import { Card, CardBody } from "reactstrap";
import { useGet } from "hooks";
import { Link } from "react-router-dom";

interface Props {
  campusId?: number;
  buildId?: number;
  floorId?: number;
}

function LocationBreadcrumb({ buildId, campusId, floorId }: Props): ReactElement {
  const [getCampus, campusStatus, campus] = useGet<any, { campusId: number }>("/Campus/GetCampusById");
  const [getBuild, buildStatus, build] = useGet<any, { buildId: number }>("/Build/GetBuildById");
  const [getFloor, floorStatus, floor] = useGet<any, { floorId: number }>("/Floor/GetFloorById");

  async function getLocations() {
    if (!!campusId) {
      getCampus({ campusId });
    }
    if (!!buildId) {
      getBuild({ buildId });
    }
    if (!!floorId) {
      getFloor({ floorId });
    }
  }

  useLayoutEffect(() => {
    getLocations();
  }, []);

  return (
    <Card className="mb-3">
      <CardBody>
        <div className="d-flex align-items-center">
          {!!campusId && (
            <h6 className="m-0 p-0">
              <Link className="text-dark" to={`/campus/${campusId}/builds/`}>{campus ? campus["name"] : "---"}</Link>
            </h6>
          )}
          {!!buildId && (
            <>
              <i className="flaticon2-right-arrow mx-5 text-dark" />
              <h6 className="m-0 p-0">
                <Link className="text-dark" to={`/campus/${campusId}/builds/${buildId}/floors/`}>{build ? build["name"] : "---"}</Link>
              </h6>
            </>
          )}
          {!!floorId && (
            <>
              <i className="flaticon2-right-arrow mx-5 text-dark" />
              <h6 className="m-0 p-0">{floor ? floor["name"] : "---"}</h6>
            </>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default LocationBreadcrumb;
