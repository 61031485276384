import React, { ReactElement } from "react";
import { SubHeader, Page } from "layout/components";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { useGet } from "hooks";
import Swal from "sweetalert2";
import { useStore } from "react-redux";

interface Props {}

function Settings({}: Props): ReactElement {
  const { register, handleSubmit, errors, watch, reset } = useForm<any>();
  const [change, status] = useGet<any, any>("/User/passwordChance");
  const { user } = useStore().getState().auth;
  async function onValid({ newPass, newPassConfirm, oldPass }: any) {
    try {
      await change({
        userId:user.id,
        newPass,
        oldPass,
      });
      reset();
      Swal.fire({ title: "Şifre Başarıyla Değiştirildi!" });
    } catch (error) {
      Swal.fire({ title: "Şifre Değiştirilemedi!", text: "Lütfen eski şifrenizi gözden geçirin!" });
    }
  }
  return (
    <Page>
      <SubHeader title="Ayarlar" />
      <Card>
        <CardBody>
          <Form onSubmit={handleSubmit(onValid)}>
            <Row>
              <Col md={6}>
                <h4>Şifre Değiştir</h4>
                <hr />
                <FormGroup>
                  <Label>Mevcut Şifre</Label>
                  <Input
                    type="text"
                    name="oldPass"
                    innerRef={register({ required: true })}
                    invalid={!!errors["oldPass"]}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Yeni Şifre</Label>
                  <Input
                    type="text"
                    name="newPass"
                    innerRef={register({ required: true })}
                    invalid={!!errors["newPass"]}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Yeni Şifre Tekrar</Label>
                  <Input
                    type="text"
                    name="newPassConfirm"
                    innerRef={register({
                      required: true,
                      validate: () => {
                        Swal.fire({ title: "Şifreler Uyuşmuyor" });
                        return watch("newPass") === watch("newPassConfirm");
                      },
                    })}
                    invalid={!!errors["newPassConfirm"]}
                  />
                </FormGroup>
                <div className="mt-1 d-flex justify-content-end">
                  <Button color="primary">Değiştir</Button>
                </div>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Page>
  );
}

export default Settings;
