import { InvertoryOData } from "models";
import React from "react";
import { Col, Row } from "reactstrap";

type Props = {
  item: InvertoryOData;
};

const IntentoryPdfItem = ({ item }: Props) => {
  return (
    <Row
      className="d-flex  py-2"
      style={{
        width: "100%",
        marginBottom: "20px",
        border: "1px solid #888",
        borderLeft: "none",
        borderRight: "none",
      }}
    >
      <Col xs={2}>
        <div>
          {item.PhotoPath ? (
            <img src={item.PhotoPath} className="img-fluid" width="100%" height="50" alt={item.Barcode} />
          ) : (
            "Fotoğraf Yok"
          )}
        </div>
      </Col>
      <Col xs={4}>
        <div>
          <div>
            <b>Konum Bilgileri</b>
          </div>
          <div>Kampüs: {item.CampusName}</div>
          <div>Bina: {item.BuildName}</div>
          <div>Kat: {item.FloorName}</div>
          <div>Oda: {item.RoomName}</div>
        </div>
        <div className="mt-5">
          <div>
            <b>Envanter Bilgileri</b>
          </div>
          <div>Barkod: {item.Barcode}</div>
          <div>Envanter NO: {item.InventoryNo}</div>
          <div>Durum: {item.InventoryStatusName}</div>
          <div>Alım Tarihi: {item.PurchaseData}</div>
          <div>Alım Fiyatı: {item.Price}</div>
          <div>Fatura No: {item.InvoiceNo}</div>
          <div>Bölüm: {item.DepartmentName}</div>
          <div>Masraf Yeri: {item.CostName}</div>
        </div>
      </Col>
      <Col xs={3}>
        <div>
          <div>
            <b>Teknik Bilgiler</b>
          </div>
          <div>Ana Grup: {item.MainGrupName}</div>
          <div>Grup: {item.GrupName}</div>
          <div>Tip: {item.Text1}</div>
          <div>Marka: {item.BrandName}</div>
          <div>Model: {item.ModelName}</div>
          <div>Seri No: {item.Barcode}</div>
        </div>
        <div className="mt-5">
          <div>
            <b>Zimmet Bilgileri</b>
          </div>
          <div>Ad Soyad: {item.EmpleyooName}</div>
          <div>Sicil No: {item.EmpleyooSicil}</div>
        </div>
      </Col>
      <Col xs={3}>
        <div>
          <div>
            <b>Boyut Bilgileri</b>
          </div>
          <div>En: {item.E}</div>
          <div>Boy: {item.B}</div>
          <div>Yükseklik: {item.Y}</div>
          <div>Renk: {item.ColorName}</div>
        </div>
        <div className="mt-5">
          <div>
            <b>Diğer Bilgiler</b>
          </div>
          <div>Diğer 2: {item.Text2}</div>
          <div>Diğer 3: {item.Text3}</div>
          <div>Diğer 4: {item.Text4}</div>
          <div>Notlar: {item.Notes}</div>
        </div>
      </Col>
    </Row>
  );
};

export default IntentoryPdfItem;
