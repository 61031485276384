interface SidebarItem {
  title: string;
  path: string;
  icon: string;
  subs?: Omit<SidebarItem, "icon" | "subs">[];
}

export const sidebarItems: SidebarItem[] = [
  {
    icon: "",
    path: "/",
    title: "Kontrol Merkezi",
  },
  {
    icon: "",
    path: "/campus-list",
    title: "Lokasyon",
  },
  {
    icon: "",
    path: "",
    title: "Raporlar",
    subs: [
      {
        path: "/report-match",
        title: "Tüm Kayıtlar",
      },
      {
        path: "/report-passive",
        title: "Pasif Kayıtlar",
      },
      {
        path: "/sanal-barcode",
        title: "Sanal Kayıtlar",
      },
      {
        path: "/report-search",
        title: "Kayıt Arama",
      },
      {
        path: "/report-readable",
        title: "Sayım Sonuçları",
      },
      {
        path: "/employee-reports",
        title: "Zimmet Listesi",
      },
    ],
  },
  {
    icon: "",
    path: "/employee",
    title: "Personeller",
  },
  {
    icon: "",
    path: "/user",
    title: "Kullanıcılar",
  },
];
