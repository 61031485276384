import React, { useLayoutEffect } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { useForm } from "react-hook-form";
import { Status, useGet } from "hooks";
import { Build, buildUrls,  } from "services/location";

interface Props {
  campusId: any;
  buildId: any;
  onValid: any;
  defaultValue?: any;
  projectID: any;
}

const FloorForm = ({ campusId, onValid, defaultValue, projectID, buildId }: Props) => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [getBuilds, statBuilds, builds] = useGet<Build[], { campusId: number | string }>(buildUrls.buildList);

  async function beforeRenderAsync() {
    await getBuilds({campusId: campusId});
    setValue("buildId", buildId);
  } 

  useLayoutEffect(() => {
    beforeRenderAsync();
  }, []);

  return (
    <Form onSubmit={handleSubmit(onValid)}>
      <FormGroup>
        <Label>Bina</Label>
        <Input type="select" name="buildId" innerRef={register({required: true})}>
          {statBuilds === Status.success &&
            !!builds &&
            builds.map((build, index) => <option key={index} value={`${build.id}`}>{build.name}</option>)}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>Kat Adı</Label>
        <Input type="text" name="name" innerRef={register({ required: true })} defaultValue={defaultValue?.name} />
      </FormGroup>
      <FormGroup>
        <Button color="primary">Kaydet</Button>
      </FormGroup>
    </Form>
  );
};

export default FloorForm;
