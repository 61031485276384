import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Input,
  Button,
  Label,
  ModalHeader,
} from "reactstrap";
import SkyInput from "components/Forms/SkyInput";
import { IUserEdit, editUserForm } from "models/IUserEdit";
import { usePost, useToggle } from "hooks";
import { UserEnum } from "models/user-enum";

type UserEditFormProps = {
  defaultValues: IUserEdit;
  setUserEditForm: React.Dispatch<React.SetStateAction<editUserForm>>;
  editUserRequest: any;
};

const UserEditForm: FC<UserEditFormProps> = (props) => {
  const { defaultValues } = props;
  const { control, setValue, watch, handleSubmit, register } = useForm({
    defaultValues: defaultValues,
  });

  const [isOpen, toggle] = useToggle();
  const [roleIsOpen, roleToggle] = useToggle();
  const [isActive, setIsActive] = useState(defaultValues.isActive);
  const [role, setRole] = useState(defaultValues.userRoleID);

  async function onValid(fields: any) {
    //update user servisi
    const body: any = {
      id: defaultValues.id,
      userRoleID: role,
      name: fields.name,
      surname: fields.name,
      email: fields.email,
      phone: fields.phone,
      isActive: isActive,
    };
    await props.editUserRequest(body);
    props.setUserEditForm({ modalOpen: false, defaultValues: null });
  }

  return (
    <form onSubmit={handleSubmit(onValid)}>
      <SkyInput name="name" control={control} label="Ad" />
      <SkyInput name="surname" control={control} label="Soyad" />
      <SkyInput name="email" control={control} label="E-mail" />
      <SkyInput name="phone" control={control} label="Telefon" />
      <div className="header-menu-wrapper header-menu-wrapper-right">
        <div className="d-flex align-items-center mr-4">Durum:</div>
        <Dropdown isOpen={isOpen} toggle={toggle}>
          <DropdownToggle color="secondary" className="border-0" caret onClick={() => toggle}>
            {isActive ? "Aktif" : "Pasif"}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => setIsActive(true)}>Aktif</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={() => setIsActive(false)}>Pasif</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className="header-menu-wrapper header-menu-wrapper-right mt-4">
        <div className="d-flex align-items-center mr-4">Rol:</div>
        <Dropdown isOpen={roleIsOpen} toggle={roleToggle}>
          <DropdownToggle color="secondary" className="border-0" caret onClick={() => roleToggle}>
            {role === UserEnum.admin ? "Admin" : role === UserEnum.manager ? "Yönetici" : "Standart"}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => setRole(UserEnum.admin)}>Admin</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={() => setRole(UserEnum.manager)}>Yönetici</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={() => setRole(UserEnum.basic)}>Standart</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className="d-flex justify-content-end gap-2">
        <Button
          size="md"
          color="danger"
          title="Kapat"
          onClick={() => props.setUserEditForm({ modalOpen: false, defaultValues: null })}
        >
          Kapat
        </Button>
        <Button size="md" type="submit" color="success" title="Evet">
          Kaydet
        </Button>
      </div>
    </form>
  );
};

export default UserEditForm;
