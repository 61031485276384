import { FC, useEffect, useLayoutEffect, useState } from "react";
import { usePost, useRedux, useGet, useToggle, Status } from "hooks";
import { Controller, useForm } from "react-hook-form";
import cogoToast from "cogo-toast";
import { Row, Col, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, CardBody, Card } from "reactstrap";
import InputMask from "react-input-mask";
import { useHistory } from "react-router-dom";
import SelectBox from "components/SelectBox";
import CurrencyInput from "react-currency-format";
import ImageUploading from "react-images-uploading";
import Swal from "sweetalert2";
import SkyInput from "components/Forms/SkyInput";

type InventoryAddProps = {
  defaultValues?: any;
  isEdit?: boolean;
};

const InventoryAdd: FC<InventoryAddProps> = (props) => {
  const { defaultValues } = props;
  const history = useHistory();
  const { register, errors, handleSubmit, reset, watch, control, setValue } = useForm({
    defaultValues: defaultValues,
  });

  const [getColors, statColors, colors] = useGet<any[], any>("/Color/GetColorListByProjectID");
  const [getStats, statStat, stats] = useGet<any[], {}>("/Inventory/GetInventoryStatusList");
  const [add, stat, , msg] = usePost("/InventoryAdd/AddInventory");

  const [image, setImage] = useState<any>();
  const [
    {
      auth: { user },
    },
  ] = useRedux();

  async function onValid(fields: any) {
    if (!image) {
      Swal.fire({ title: "Lütfen sabit kıymet için fotoğraf seçiniz!" });
      return;
    }

    const body: any = {
      projectID: user!.project!.id!,
      campusID: parseInt(fields.campusID),
      buildID: parseInt(fields.buildID),
      floorID: parseInt(fields.floorID),
      roomID: parseInt(fields.roomID),
      brandID: parseInt(fields.brandID),
      grupID: parseInt(fields.grupID),
      modelID: fields.modelID ? parseInt(fields.modelID) : 0,
      inventoryNo: fields.inventoryNo,
      serialNumber: fields.serialNumber,
      invoiceNo: fields.invoiceNo,
      purchaseData: fields.purchaseData,
      notes: fields.notes,
      isActive: fields.isActive,
      departmentID: fields.departmentID ? parseInt(fields.departmentID) : 0,
      costID: fields.costID ? parseInt(fields.costID) : 0,
      price: fields.price ? fields.price.split(",").join(".") : "",
      text1: fields.text1,
      text2: fields.text2,
      text3: fields.text3,
      text4: fields.text4,
      colorID: fields.colorID ? parseInt(fields.colorID) : 0,
      inventoryStatusID: fields.inventoryStat ? parseInt(fields.inventoryStat) : 0,
      e: fields.e,
      b: fields.b,
      y: fields.y,
      empleyooID: parseInt(fields.employeeID),
      barcode: fields.barcode,
      isSanalBarcode: fields.sanal,
      inventoryCount: fields.inventoryCount ? parseInt(fields.inventoryCount) : 1,
      photoPath: fields.photoPath,
    };
    try {
      await add({ ...body, AddUserID: user?.id, inventoryStatusID: 1, costID: body.costID == null ? 0 : body.costID });
    } catch (error) {
      cogoToast.error("Zimmet Kaydı Oluşturuldu!");
    }
  }

  useLayoutEffect(() => {
    getColors({ projectId: user?.project?.id! });
    getStats();
    register("price");
    register("sanal");
    register("photoPath");
    register("purchaseData");
  }, []);

  useEffect(() => {
    if (stat === Status.success) {
      reset();
      cogoToast.success("Zimmet Kaydı Oluşturuldu!");
      history.push("/report-match");
    } else if (stat === Status.error) {
      cogoToast.error(msg);
    }
  }, [stat]);

  return (
    <form onSubmit={handleSubmit(onValid)}>
      <Card className="mt-5">
        <CardBody className="p-4">
          <h5 className="mt-1">Lokasyon</h5>
          <hr />
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label>
                  <span className="text-danger">* </span>Kampüs
                </Label>

                <Select
                  listurl="/Campus/GetCampusListByProjectID"
                  noAdd
                  params={{ projectId: user!.project!.id! }}
                  name="campusID"
                  control={control}
                  error={errors}
                  rules={{
                    required: {
                      value: true,
                      message: "Bu alanın doldurulması zorunludur!",
                    },
                    validate: () => {
                      return watch("campusID") == 0 ? false : true;
                    },
                  }}
                  invalid={!!errors["campusID"]}
                />
              </FormGroup>
            </Col>
            {watch("campusID") && watch("campusID") != 0 && (
              <Col md={3}>
                <FormGroup>
                  <Label>
                    <span className="text-danger">* </span>Bina
                  </Label>
                  <Select
                    listurl="/Build/GetBuildListByCampusID"
                    params={{ campusId: watch("campusID") }}
                    parent={watch("campusID")}
                    name="buildID"
                    error={errors}
                    rules={{
                      required: {
                        value: true,
                        message: "Bu alanın doldurulması zorunludur!",
                      },
                      validate: () => {
                        return watch("buildID") == 0 ? false : true;
                      },
                    }}
                    control={control}
                    invalid={!!errors["buildID"]}
                    noAdd
                  />
                </FormGroup>
              </Col>
            )}
            {watch("buildID") && watch("buildID") != 0 && (
              <Col md={3}>
                <FormGroup>
                  <Label>
                    <span className="text-danger">* </span>Kat
                  </Label>
                  <Select
                    listurl="/Floor/GetFloorListByBuildID"
                    params={{ buildId: watch("buildID") }}
                    parent={watch("buildID")}
                    name="floorID"
                    error={errors}
                    rules={{
                      required: {
                        value: true,
                        message: "Bu alanın doldurulması zorunludur!",
                      },
                      validate: () => {
                        return watch("floorID") == 0 ? false : true;
                      },
                    }}
                    control={control}
                    invalid={!!errors["floorID"]}
                    noAdd
                  />
                </FormGroup>
              </Col>
            )}
            {watch("floorID") && watch("floorID") != 0 && (
              <Col md={3}>
                <FormGroup>
                  <Label>
                    <span className="text-danger">* </span>Oda
                  </Label>
                  <Select
                    listurl="/Room/GetRoomListByFloorID"
                    params={{ floorId: watch("floorID") }}
                    parent={watch("floorID")}
                    name="roomID"
                    error={errors}
                    rules={{
                      required: {
                        value: true,
                        message: "Bu alanın doldurulması zorunludur!",
                      },
                      validate: () => {
                        return watch("roomID") == 0 ? false : true;
                      },
                    }}
                    control={control}
                    invalid={!!errors["roomID"]}
                    noAdd
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
      <Card className="mt-5">
        <CardBody className="p-4">
          <h5 className="mt-3">Envanter Bilgileri</h5>
          <hr />
          <Row>
            {!props.isEdit && (
              <Col md={3}>
                <FormGroup>
                  <Label>
                    <span className="text-danger">* </span>Barkod No
                  </Label>
                  <Row>
                    <Col md={9}>
                      <Input
                        type="text"
                        name="barcode"
                        disabled={watch("sanal")}
                        innerRef={register({ required: !watch("sanal") })}
                        invalid={!!errors["barcode"]}
                      />
                    </Col>
                    <Col className="d-flex align-items-center" md={3}>
                      <FormGroup switch>
                        <Input
                          type="switch"
                          name="sanal"
                          role="switch"
                          checked={watch("sanal")}
                          onChange={(val) => setValue("sanal", val.target.checked)}
                        />
                        <Label>{watch("sanal") ? "Sanal" : "Sanal Değil"}</Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            )}
            <Col md={3}>
              <FormGroup>
                <Label>
                  <span className="text-danger">* </span>Durum
                </Label>
                <SelectBox
                  name="inventoryStatusID"
                  control={control}
                  error={errors}
                  rules={{
                    required: {
                      value: true,
                      message: "Bu alanın doldurulması zorunludur!",
                    },
                    validate: () => {
                      return watch("inventoryStatusID") == 0 ? false : true;
                    },
                  }}
                  options={stats ? stats!.map((stat) => ({ value: stat.id, label: stat.name })) : []}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <SkyInput
                  control={control}
                  error={errors}
                  rules={{
                    pattern: {
                      value:  /^[A-Z\s!@#$%^&*(),.?-_/\":{}|<>\d]*$/,
                      message: "Sadece İngilizce büyük harfler kullanabilirsiniz.",
                    },
                  }}
                  name="inventoryNo"
                  label="Envanter No"
                />
                {/* <Label>Envanter No</Label>
                <Input type="text" name="inventoryNo" innerRef={register()} /> */}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>Alım Tarihi</Label>
                <InputMask
                  mask="99.99.9999"
                  //value={watch("purchaseData")}
                  className="form-control"
                  maskPlaceholder="__.__.____"
                  onChange={(e) => {
                    setValue(
                      "purchaseData",
                      e.target.value
                        .split("")
                        .filter((ch) => ch !== "_")
                        .join("")
                    );
                  }}
                  name="purchaseData"
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>Alım Fiyatı</Label>
                <CurrencyInput
                  className="form-control"
                  value={watch("price") ? watch("price").split(".").join(",") : ""}
                  thousandSeparator={"."}
                  decimalSeparator=","
                  prefix={"₺"}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    // formattedValue = $2,223
                    // value ie, 2223
                    setValue("price", `${value}`);
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <SkyInput
                  control={control}
                  error={errors}
                  rules={{
                    pattern: {
                      value: /^[A-Z\s!@#$%^&*(),.?-_/\":{}|<>\d]*$/,
                      message: "Sadece İngilizce büyük harfler kullanabilirsiniz.",
                    },
                  }}
                  name="invoiceNo"
                  label="Fatura No"
                />
                {/* <Label>Fatura No</Label>
                <Input type="text" name="invoiceNo" innerRef={register()} /> */}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>Bölüm</Label>
                <Select
                  listurl="/Department/GetDepartmentListByProjectID"
                  addurl="/Department/AddDepartment"
                  params={{ projectId: user!.project!.id! }}
                  addparams={{ projectId: user!.project!.id! }}
                  control={control}
                  name="departmentID"
                />
              </FormGroup>
            </Col>
            {watch("departmentID") && watch("departmentID") != 0 && (
              <Col md={3}>
                <FormGroup>
                  <Label>Masraf Yeri</Label>
                  <Select
                    listurl="/Department/GetCostListByDepartmentID"
                    addurl="/Department/AddCost"
                    params={{ departmentId: watch("departmentID") }}
                    addparams={{ departmentId: watch("departmentID") }}
                    parent={watch("departmentID")}
                    control={control}
                    name="costID"
                    selected={watch("costID")}
                  />
                </FormGroup>
              </Col>
            )}
            {!props.isEdit && (
              <Col md={3}>
                <FormGroup>
                  <Label>Adet</Label>
                  <Input innerRef={register()} type="number" name="inventoryCount" defaultValue="1" min="1" />
                </FormGroup>
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
      <Card className="mt-5">
        <CardBody className="p-4">
          <h5 className="mt-1">Teknik</h5>
          <hr />
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label>
                  <span className="text-danger">* </span>Ana Grup
                </Label>
                <Select
                  listurl={`/Group/GetMainGroupsByProjectId?projectId=${user!.project!.id}`}
                  addurl="/Group/AddGroupMain"
                  params={{ projectId: user!.project!.id! }}
                  addparams={{ projectId: user!.project!.id! }}
                  name="mainGrupID"
                  control={control}
                  error={errors}
                  rules={{
                    required: {
                      value: true,
                      message: "Bu alanın doldurulması zorunludur!",
                    },
                    validate: () => {
                      return watch("mainGrupID") == 0 ? false : true;
                    },
                  }}
                  invalid={!!errors["mainGrupID"]}
                />
              </FormGroup>
            </Col>
            {watch("mainGrupID") && watch("mainGrupID") != 0 && (
              <Col md={4}>
                <FormGroup>
                  <Label>
                    <span className="text-danger">* </span>Grup
                  </Label>
                  <Select
                    listurl="/Group/GetGroupListByGroupMainID"
                    addurl="/Group/AddGroup"
                    params={{ groupMainID: watch("mainGrupID") }}
                    parent={watch("mainGrupID")}
                    addparams={{ grupMainID: watch("mainGrupID") }}
                    name="grupID"
                    control={control}
                    error={errors}
                    rules={{
                      required: {
                        value: true,
                        message: "Bu alanın doldurulması zorunludur!",
                      },
                      validate: () => {
                        return watch("grupID") == 0 ? false : true;
                      },
                    }}
                    invalid={!!errors["grupID"]}
                  />
                </FormGroup>
              </Col>
            )}
            {watch("grupID") && watch("grupID") != 0 && (
              <Col md={4}>
                <FormGroup>
                  <Label>
                    <span className="text-danger">* </span>Marka
                  </Label>
                  <Select
                    listurl="/Group/GetBrandListByGroupID"
                    addurl="/Group/AddBrand"
                    params={{ groupId: watch("grupID") }}
                    parent={watch("grupID")}
                    addparams={{ grupID: watch("grupID") }}
                    name="brandID"
                    control={control}
                    error={errors}
                    rules={{
                      required: {
                        value: true,
                        message: "Bu alanın doldurulması zorunludur!",
                      },
                      validate: () => {
                        return watch("brandID") == 0 ? false : true;
                      },
                    }}
                    invalid={!!errors["brandID"]}
                  />
                </FormGroup>
              </Col>
            )}
            {watch("brandID") && watch("brandID") != 0 && (
              <Col md={4}>
                <FormGroup>
                  <Label>Model</Label>
                  <Select
                    listurl="/Group/GetModelListByBrandID"
                    addurl="/Group/AddModel"
                    params={{ brandId: watch("brandID") }}
                    parent={watch("brandID")}
                    addparams={{ brandId: watch("brandID"), grupId: watch("grupID") }}
                    name="modelID"
                    control={control}
                  />
                </FormGroup>
              </Col>
            )}
            <Col md={4}>
              <FormGroup>
                <SkyInput
                  control={control}
                  error={errors}
                  rules={{
                    pattern: {
                      value: /^[A-Z\s!@#$%^&*(),.?-_/\":{}|<>\d]*$/,
                      message: "Sadece İngilizce büyük harfler kullanabilirsiniz.",
                    },
                  }}
                  name="text1"
                  label="Tip"
                />
                {/* <Input type="text" name="text1" innerRef={register()} /> */}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <SkyInput
                  control={control}
                  error={errors}
                  rules={{
                    pattern: {
                      value: /^[A-Z\s!@#$%^&*(),.?-_/\":{}|<>\d]*$/,
                      message: "Sadece İngilizce büyük harfler kullanabilirsiniz.",
                    },
                  }}
                  name="serialNumber"
                  label="Seri No"
                />
                {/* <Label>Seri No</Label>
                <Input type="text" name="serialNumber" innerRef={register()} /> */}
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="mt-5">
        <CardBody className="p-4">
          <h5 className="mt-1">Zimmet</h5>
          <hr />
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Personel</Label>
                <Select
                  listurl="/Employee/GetEmployeeListByProjectID"
                  addurl="/Employee/AddEmployee"
                  params={{ projectId: user!.project!.id! }}
                  addparams={{ projectId: user!.project!.id!, companyId: user!.companyID }}
                  name="employeeID"
                  innerRef={register({ required: true })}
                  employee
                />
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" name="isActive" innerRef={register()} />{" "}
                  {watch("isActive") ? "Aktif" : "Pasif"}
                </Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <SkyInput
                  control={control}
                  error={errors}
                  rules={{
                    pattern: {
                      value: /^[A-Z\s!@#$%^&*(),.?-_/\":{}|<>\d]*$/,
                      message: "Sadece İngilizce büyük harfler kullanabilirsiniz.",
                    },
                  }}
                  name="notes"
                  isTextarea
                  label="Notlar"
                />
                {/* <Label>Notlar</Label>
                <Input type="textarea" name="notes" innerRef={register()} /> */}
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="mt-5">
        <CardBody className="p-4">
          <h5 className="mt-1">Boyut</h5>
          <hr />
          <Row>
            <Col md={4}>
              <FormGroup>
                <SkyInput
                  control={control}
                  error={errors}
                  rules={{
                    pattern: {
                      value: /^[A-Z\s!@#$%^&*(),.?-_/\":{}|<>\d]*$/,
                      message: "Sadece İngilizce büyük harfler kullanabilirsiniz.",
                    },
                  }}
                  name="e"
                  label="En"
                />
                {/* <Label>En</Label>
                <Input type="text" name="e" innerRef={register()} /> */}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <SkyInput
                  control={control}
                  error={errors}
                  rules={{
                    pattern: {
                      value: /^[A-Z\s!@#$%^&*(),.?-_/\":{}|<>\d]*$/,
                      message: "Sadece İngilizce büyük harfler kullanabilirsiniz.",
                    },
                  }}
                  name="b"
                  label="Boy"
                />
                {/* <Label>Boy</Label>
                <Input type="text" name="b" innerRef={register()} /> */}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <SkyInput
                  control={control}
                  error={errors}
                  rules={{
                    pattern: {
                      value: /^[A-Z\s!@#$%^&*(),.?-_/\":{}|<>\d]*$/,
                      message: "Sadece İngilizce büyük harfler kullanabilirsiniz.",
                    },
                  }}
                  name="y"
                  label="Yükseklik"
                />
                {/* <Label>Yükseklik</Label>
                <Input type="text" name="y" innerRef={register()} /> */}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                {}
                <Label>
                  <span className="text-danger">* </span>Renk
                </Label>
                <Controller
                  name="colorID"
                  control={control}
                  render={(field) => (
                    <Input {...field} type="select" invalid={!!errors["colorID"]}>
                      <option value={-1} selected>
                        Seçim Yapınız
                      </option>

                      {colors &&
                        colors.map((_color, index) => (
                          <option value={_color.id} key={index}>
                            {_color.colorName}
                          </option>
                        ))}
                    </Input>
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="mt-5">
        <CardBody className="p-4">
          <h5 className="mt-1">Diğer</h5>
          <hr />
          <Row>
            <Col md={3}>
              <FormGroup>
                <SkyInput
                  control={control}
                  error={errors}
                  rules={{
                    pattern: {
                      value: /^[A-Z\s!@#$%^&*(),.?-_/\":{}|<>\d]*$/,
                      message: "Sadece İngilizce büyük harfler kullanabilirsiniz.",
                    },
                  }}
                  name="text2"
                  label="Diğer 2"
                />
                {/* <Label>Diğer 2</Label>
                <Input type="text" name="text2" control={control} innerRef={register()} /> */}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <SkyInput
                  control={control}
                  error={errors}
                  rules={{
                    pattern: {
                      value: /^[A-Z\s!@#$%^&*(),.?-_/\":{}|<>\d]*$/,
                      message: "Sadece İngilizce büyük harfler kullanabilirsiniz.",
                    },
                  }}
                  name="text3"
                  label="Diğer 3"
                />
                {/* <Label>Diğer 3</Label>
                <Input type="text" name="text3" control={control} innerRef={register()} /> */}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <SkyInput
                  control={control}
                  error={errors}
                  rules={{
                    pattern: {
                      value: /^[A-Z\s!@#$%^&*(),.?-_/\":{}|<>\d]*$/,
                      message: "Sadece İngilizce büyük harfler kullanabilirsiniz.",
                    },
                  }}
                  name="text4"
                  label="Diğer 4"
                />
                {/* <Label>Diğer 4</Label>
                <Input type="text" name="text4" control={control} innerRef={register()} /> */}
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="mt-5">
        <CardBody className="p-4">
          <h5 className="mt-1">Fotograf</h5>
          <hr />
          <Row>
            <Col md={3}>
              <ImageUploading
                multiple
                value={image}
                onChange={(imageList) => {
                  setImage(imageList as never[]);
                  setValue("photoPath", imageList[0].data_url?.split(",")[1]);
                }}
                dataURLKey="data_url"
              >
                {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
                  <div className="upload__image-wrapper">
                    <div
                      style={{ border: "1px dashed" }}
                      className="d-flex justify-content-center p-5 align-items-center"
                    >
                      {imageList.length > 0 ? (
                        imageList.map((image, index) => (
                          <div key={index} className="image-item">
                            <img src={image["data_url"]} alt="" width="100%" />
                            <div className="d-flex justify-content-between mt-10 image-item__btn-wrapper">
                              <Button color="warning" onClick={() => onImageUpdate(index)}>
                                Resim Değiştir
                              </Button>
                              <Button color="danger" onClick={() => onImageRemove(index)}>
                                Resim Kaldır
                              </Button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <Button color="success" onClick={onImageUpload} {...dragProps}>
                          Fotoğraf Ekle
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </ImageUploading>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div className="d-flex justify-content-end mt-3">
        <Button color="primary">Kaydet</Button>
      </div>
    </form>
  );
};

export default InventoryAdd;

function Select({
  title,
  addurl,
  addparams,
  listurl,
  params,
  parent,
  employee,
  selected,
  error,
  noAdd,
  ...props
}: any) {
  const { register, handleSubmit, control, errors, trigger } = useForm();
  const [getList, liststat, items] = useGet<any[], any>(listurl);
  const [isOpen, toggle] = useToggle();
  const [add] = usePost(addurl);

  async function onValid(fields: any) {
    await add({ ...fields, ...addparams });
    toggle();
    getList(params);
  }

  useLayoutEffect(() => {
    if (params) {
      getList(params);
    }
  }, [parent]);

  return (
    <div className="d-flex justify-content-between">
      {props.control && (
        <SelectBox
          name={props.name}
          control={props.control}
          rules={props.rules}
          error={error}
          options={items ? items!.map((item) => ({ value: item.id, label: item.name })) : []}
        />
      )}
      {props.innerRef && (
        <Input {...props} type="select" disabled={liststat !== Status.success}>
          {liststat === Status.success && !!items && Array.isArray(items) && (
            <>
              <option value={"0"} selected disabled>
                Seçim Yapınız
              </option>
              {items.map((item: any, key: number) => (
                <option key={key} value={`${item.id}`} selected={selected === `${item.id}`}>
                  {item.id} - {item.name || item.nameSurname}
                </option>
              ))}
            </>
          )}
          {liststat === Status.pending && (
            <option selected value={"0"}>
              Yükleniyor{" "}
            </option>
          )}
        </Input>
      )}
      {!!noAdd ? null : (
        <Button style={{ maxHeight: "38px" }} className="ml-1" color="success" onClick={toggle}>
          +
        </Button>
      )}
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>{title} Ekle</ModalHeader>
        <ModalBody>
          <FormGroup>
            <SkyInput
              control={control}
              error={errors}
              rules={{
                pattern: {
                  value: /^[A-Z\s!@#$%^&*(),.?-_/\":{}|<>\d]*$/,
                  message: "Sadece İngilizce büyük harfler kullanabilirsiniz.",
                },
              }}
              name={employee ? "nameSurname" : "name"}
              label="İsim"
            />
            {/* <Label>İsim</Label>
            <Input type="text" name={employee ? "nameSurname" : "name"} innerRef={register()} /> */}
          </FormGroup>
          {employee && (
            <FormGroup>
              <SkyInput
                control={control}
                error={errors}
                rules={{
                  pattern: {
                    value: /^[A-Z\s!@#$%^&*(),.?-_/\":{}|<>\d]*$/,
                    message: "Sadece İngilizce büyük harfler kullanabilirsiniz.",
                  },
                }}
                name="sicilID"
                label="Sicil"
              />
              {/* <Label>Sicil</Label>
              <Input type="text" name={"sicilID"} innerRef={register()} /> */}
            </FormGroup>
          )}
          <div className="d-flex justify-content-end">
            <Button
              type="button"
              color="success"
              onClick={() => {
                handleSubmit(onValid)();
              }}
            >
              Ekle
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
