import axios from "axios";
import { store, Store } from "store/store";

export const http = () => {
  const reduxStore: Store = store.getState();
  const worker = axios.create({timeout: 600000});
  worker.defaults.baseURL = "https://skyapi.tesisyonetim.pro/api";
  if (reduxStore.auth.isLogged) {
    worker.defaults.headers.common["Authorization"] =
      "Bearer " + reduxStore.auth.token;
  }

  return worker;
};

export const httpOData = () => {
  const reduxStore: Store = store.getState();
  const worker = axios.create({timeout: 600000});
  worker.defaults.baseURL = "https://skyapi.tesisyonetim.pro/";
  if (reduxStore.auth.isLogged) {
    worker.defaults.headers.common["Authorization"] =
      "Bearer " + reduxStore.auth.token;
  }

  return worker;
};
