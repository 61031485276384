export const employeeUrls = {
  list: "/Employee/GetEmployeeListByProjectID/",
  add: "/Employee/AddEmployee",
};

export interface EmployeeListParams {
  projectID: string | number;
}

export interface Employee {
  id: number;
  companyID: number;
  projectID: number;
  nameSurname: string;
  sicilID: string;
  isActive: boolean;
  createDate: Date;
}
