import React, { useLayoutEffect, useState } from "react";
import { Card, CardBody, CardHeader, Spinner } from "reactstrap";
import Chart from "react-apexcharts";
import { Status, useGet, useRedux } from "hooks";
import { Params } from "pages/report-pages/Detail/services";
import { Res, apiUrls } from "pages/report-pages/MatchInventory/service";

const val = {
  options: {},
  series: [44, 55, 41, 17, 15],
  labels: ["A", "B", "C", "D", "E"],
};

interface Props {
  data: any[];
}

function Statu({data}: Props) {
  const [
    {
      auth: { user },
    },
  ] = useRedux();
  const [statics, setStatics] = useState<any[]>([]);

  async function getStats() {
    let stats = [
      { id: 1, name: "Sayılan", isActive: true, count: 0 },
      { id: 2, name: "Bulunamayan", isActive: true, count: 0 },
    ];
    if (data) {
      data.forEach((_item) => {
        switch (!!_item.LastReadDate) {
          case true:
            stats[0].count++;
            break;
          case false:
            stats[1].count++;
            break;
          default:
            break;
        }
      });
      setStatics(stats);
    }
  }

  useLayoutEffect(() => {
    getStats();
  }, []);

  return (
    <Card>
      <CardHeader>
        <h5>Sayım Sonuçları</h5>
      </CardHeader>
      <CardBody>
        {statics.length > 0 ? (
          <div className="donut">
            <Chart
              options={{ colors: ["#20c997", "#F64E60", "#ffc107", "#3F4254", "#6f42c1", "#007bff", "#fd7e14"], labels: statics.map((_stat: { name: string }) => _stat.name) }}
              series={statics.map((_stat) => _stat.count)}
              type="donut"
              width="380"
            />
          </div>
        ) : (
          <div className="d-flex h-100 justify-content-center align-items-center">
            <Spinner />
          </div>
        )}
      </CardBody>
    </Card>
  );
}

export default Statu;
