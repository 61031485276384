import React, { ReactElement, useLayoutEffect } from "react";
import { RouteComponentProps, Link, useHistory } from "react-router-dom";
import { Badge, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Page, SubHeader } from "layout/components";
import DataTable from "components/DataTable";
import { Status, useGet, usePost, useRedux, useToggle } from "hooks";
import { floorUrls } from "services/location";
import Moment from "moment";
import ReactDOM from "react-dom";

import FloorForm from "./FloorForm";
import LocationBreadcrumb from "components/LocationBreadcrumb";

interface Props {}

function FloorList({
  match: {
    params: { buildID, campusID },
  },
}: Props & RouteComponentProps<{ campusID: string; buildID: string }>): ReactElement {
  const [isOpen, openToggle] = useToggle();

  const [
    {
      auth: { user },
    },
  ] = useRedux();
  const history = useHistory();

  const [getFloors, floorsStat, floors] = useGet<any[], { buildId: number | string }>(floorUrls.floorList);
  const [add, addStat] = usePost<{ projectID: number; campusID: number; buildID: number; name: string }, {}, {}>(
    "/Floor/AddFloor"
  );
  const [update] = usePost("/Floor/UpdateFloor");
  const [getCampus, campusStatus, campus] = useGet<any, { campusId: number | string }>("/Campus/GetCampusById");
  const [getBuild, buildStatus, build] = useGet<any, { buildId: number | string }>("/Build/GetBuildById");

  async function getLocations() {
    if (!!campusID) {
      getCampus({ campusId: campusID });
    }
    if (!!buildID) {
      getBuild({ buildId: buildID });
    }
  }

  async function onValid(fields: any) {
    await add({
      name: fields.name,
      campusID: parseInt(campusID),
      projectID: user!.project!.id!,
      buildID: parseInt(fields.buildId),
    });
    openToggle();
    getFloors({ buildId: buildID });
  }

  async function handleClickToggle(row: any) {
    await update({
      ...row,
      isActive: !row.isActive,
    });
    getFloors({ buildId: buildID });
  }

  useLayoutEffect(() => {
    getFloors({ buildId: buildID });
    getLocations();
  }, []);

  return (
    <Page loading={floorsStat === Status.pending}>
      <SubHeader
        title="Katlar"
        breadcrumbs={[
          { title: "Binalar", path: `/campus/${campusID}/builds` },
          { title: "Kampüsler", path: "/campus-list" },
        ]}
      >
        <Button size="sm" color="primary" onClick={openToggle}>
          Yeni Kat Ekle
        </Button>
      </SubHeader>
      <LocationBreadcrumb campusId={parseInt(campusID)} buildId={parseInt(buildID)} />

      {floors && (
        <DataTable
          columns={[
            {
              name: "Kampüs İsmi",
              selector: "campusName",
            },
            {
              name: "Bina İsmi",
              selector: "buildName",
            },
            {
              name: "Kat İsmi",
              selector: "name",
            },
            {
              name: "Eklenme Tarihi",
              selector: "createDate",
              cell: function (row: any) {
                return Moment(row.createDate).format("DD/MM/YYYY HH:mm");
              },
            },
            {
              name: "Durum",
              selector: "isActive",
              // render: function(data: any, type: any, row: any) {

              // },
              cell: function (row: any) {
                return <Badge color={row.isActive ? "success" : "secondary"}>{row.isActive ? "Aktif" : "Pasif"}</Badge>;
              },
            },
            {
              name: "Button",
              selector: "id",
              cell: function (row: any) {
                return (
                  <div>
                    <Button
                      color="info"
                      size="sm"
                      className="ml-1"
                      onClick={() => {
                        handleClickToggle(row);
                      }}
                    >
                      <i className="flaticon-interface-7 p-0"></i>
                    </Button>
                    <Button
                      className="btn btn-primary btn-sm ml-1"
                      color="primary"
                      onClick={() => {
                        history.push("/campus/" + campusID + "/builds/" + buildID + "/floors/" + row.id + "/rooms");
                      }}
                    >
                      Odalar
                    </Button>
                  </div>
                );
              },
            },
          ]}
          data={
            campusStatus === "fulfilled" &&
            buildStatus === "fulfilled" ?
            floors.map((floor) => ({ ...floor, campusName: campus.name, buildName: build.name })) : []
          }
        />
      )}

      <Modal isOpen={isOpen} toggle={openToggle}>
        <ModalHeader toggle={openToggle}>Kampüs Ekle</ModalHeader>
        <ModalBody>
          <FloorForm {...{ campusId: campusID, buildId: buildID, onValid: onValid, projectID: user!.project!.id! }} />
        </ModalBody>
      </Modal>
    </Page>
  );
}

export default FloorList;
